import {MutationTree} from "vuex";
import {DriverUI} from "@/utils/httpReqResMapping";
import {DriverState} from "@/store/driver/types";

export enum DriverMutations {
    SET_EDIT_DRIVER = "SET_EDIT_DRIVER"
}

export const mutations: MutationTree<DriverState> = {
    [DriverMutations.SET_EDIT_DRIVER](state, driver: DriverUI) {
        state.editingDriver = {
            ...driver
        }
    }
}
