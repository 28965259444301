















































import {Component, Emit, Prop, Vue} from "vue-property-decorator";

@Component
export default class SignatureModal extends Vue {

  @Prop()
  imageUrl!: string

  src = ''

  modal = true
  rotation = 0

  mounted(): void {
    this.displayProtectedImage();
  }

  @Emit('onClose')
  onClose(val: boolean): boolean {
    return val;
  }

  async displayProtectedImage(): Promise<void> {
    const response = await this.$http.get(this.imageUrl, {
      responseType: 'blob'
    });
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    reader.onload = () => {
      this.src = reader.result as string;
    }
  }

  rotate(left: boolean): void {
    this.rotation = left? this.rotation - 90 : this.rotation + 90;
  }

}
