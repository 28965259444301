













































import {Component, Mixins} from "vue-property-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {emptyVehicle, Vehicle} from "@/utils/httpReqResMapping";
import {readyForLoad} from "@/utils/pagination";
import VehicleTable from "@/components/VehicleTable.vue";
import DecisionModal from "@/components/DecisionModal.vue";
import RegisterVehicleModal from "@/components/RegisterVehicleModal.vue";
import EmptyAlert from "@/components/EmptyAlert.vue";
import CustomerAutoSelect from "@/components/CustomerAutoSelect.vue";
@Component({
  components: {CustomerAutoSelect, EmptyAlert, RegisterVehicleModal, DecisionModal, VehicleTable}
})
export default class Vehicles extends Mixins <PaginationMixin <Vehicle> > (PaginationMixin) {

  vehicles: Vehicle [] = [];
  initFetch = false;

  async mounted(): Promise<void> {
    this.vehicles = await this.getNextPage('/vehicle', true, undefined);
    this.initFetch = true;
  }

  endIntersect(e: unknown): void {
    const fetch = readyForLoad(e);
    if (fetch) {
      this.loadPage();
    }
  }

  async loadPage(): Promise<void> {
    const data = await this.getNextPage('/vehicle', false, undefined);
    this.vehicles.push(...data);
  }

  // Register all modals necessary
  get decisionModal (): DecisionModal {
    return this.$refs.decisionModal as DecisionModal;
  }

  get registerVehicleModal (): RegisterVehicleModal {
    return this.$refs.registerVehicleModal as RegisterVehicleModal;
  }

  async deleteVehicle(vehicle: Vehicle): Promise<void> {
    const result = await this.decisionModal.showModal({
      message: `Ali ste prepricani da zelite izbrisati vozilo ${vehicle.registrationNumber}.
       Vsi podatki, ki so vezani na to vozilo bodo s to akcijo izbrisani`,
      title: `Brisanje vozila ${vehicle.registrationNumber}`,
      positiveDecision: 'Izbrisi',
      negativeDecision: 'Preklici'
    })
    if (result) {
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/vehicle/${vehicle.id}`);
      this.vehicles = this.vehicles.filter((it) => it.id !== vehicle.id);
    }
  }

  async updateVehicle(vehicle: Vehicle): Promise<void> {
    const result = await this.registerVehicleModal.showModal(vehicle, 'Uredi vozilo');
    // Check if user changed vehicle fields
    if (result == undefined) {
      return;
    }
    if (JSON.stringify(result) === JSON.stringify(vehicle)) {
      return;
    } else {
      await this.$http.put(`${process.env.VUE_APP_API_URL}/vehicle/${vehicle.id}`, result);
      this.vehicles = this.vehicles.map((it) => {
        if (it.id === vehicle.id) {
          return {
            ...result
          }
        } else {
          return it;
        }
      })
    }
  }

  async registerVehicle(vehicle: Vehicle): Promise<void> {
    const result = await this.registerVehicleModal.showModal({
      ...emptyVehicle
    }, "Registriraj vozilo");
    if (result !== undefined) {
      const res = await this.$http.post<Vehicle>(`${process.env.VUE_APP_API_URL}/vehicle`, result);
      this.vehicles.push(res.data);
    }

  }
}
