import {ActionContext, ActionTree} from "vuex";
import {RootState} from "@/store/types";
import {SpinnerState} from "@/store/spinner/types";
import {SpinnerMutations} from "@/store/spinner/mutations";

export enum SpinnerActions {
    SHOW_SPINNER='SHOW_SPINNER',
    HIDE_SPINNER="HIDE_SPINNER",
    INCREMENT_SPINNER="INCREMENT_SPINNER",
    DECREMENT_SPINNER="DECREMENT_SPINNER"
}

type Context = ActionContext<SpinnerState, RootState>

export const actions: ActionTree<SpinnerState, RootState> = {

    [SpinnerActions.SHOW_SPINNER] ({ commit }: Context): void {
        commit(SpinnerMutations.SHOW_SPINNER);
    },

    [SpinnerActions.HIDE_SPINNER]({commit}: Context): void{
        commit(SpinnerMutations.HIDE_SPINNER);
    }
}
