import {MutationTree} from "vuex";
import {CustomerState} from "@/store/customer/types";
import {Customer} from "@/utils/httpReqResMapping";

export enum CustomerMutations {
    SET_EDIT_CUSTOMER_ADDRESS = "SET_EDIT_CUSTOMER_ADDRESS",
    SET_EDIT_CUSTOMER_DATA = "SET_EDIT_CUSTOMER_DATA",
    SET_CUSTOMER_ID = "SET_CUSTOMER_ID"
}

export const mutations: MutationTree<CustomerState> = {
    [CustomerMutations.SET_EDIT_CUSTOMER_ADDRESS](state, address) {
        state.editingCustomer.address = address
    },
    [CustomerMutations.SET_EDIT_CUSTOMER_DATA](state, customer: Customer) {
        state.editingCustomer.name = customer.name
        state.editingCustomer.contactPhone = customer.contactPhone
        state.editingCustomer.taxNumber = customer.taxNumber

        state.editingCustomer.address = {
            place_id: customer.address.placeId,
            lat: customer.location.latitude.toString(),
            lon: customer.location.longitude.toString(),
            display_name: customer.address.displayName,
            address: {
                house_number: customer.address.houseNumber,
                road: customer.address.road,
                residential: customer.address.residential,
                suburb: customer.address.suburb,
                town: customer.address.town,
                postcode: customer.address.postcode,
                country: customer.address.country,
                country_code: customer.address.countryCode,
                neighbourhood: customer.address.neighbourhood,
                municipality: customer.address.municipality
            }
        }
    },
    [CustomerMutations.SET_CUSTOMER_ID](state, id) {
        state.customerId = id
    }
}
