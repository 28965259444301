import {MutationTree} from "vuex";
import {ShipmentUI} from "@/utils/httpReqResMapping";
import {ShipmentState} from "@/store/shipment/types";

export enum ShipmentMutations {
    SET_EDIT_SHIPMENT = "SET_EDIT_SHIPMENT"
}

export const mutations: MutationTree<ShipmentState> = {
    [ShipmentMutations.SET_EDIT_SHIPMENT](state, shipment: ShipmentUI) {
        state.editingShipment = {
            ...shipment
        }
    }
}
