<template>
  <div
      :to="{name: routeName}"
      class="navContent"
  >
    <img
        :src="require(`@/assets/menu/${icon}${selected && !expandable ? '-white' : ''}.png`)"
        class="navIcon"
        style="fill: white"
    />
    <span class="navText" :class="selected && !expandable ? 'navTextSelected' : 'navTextNotSelected'" v-if="!iconOnly">
      {{name}}
    </span>
    <v-btn v-if="expandable && !iconOnly" icon @click="$emit('onExpand')">
      <v-icon color="black" v-if="!selected">
        mdi-chevron-up
      </v-icon>
      <v-icon color="black" v-if="selected">
        mdi-chevron-down
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "RedyNavItem",
  props: ['icon', 'selected', 'name', 'routeName', 'expandable', 'iconOnly']
}
</script>

<style scoped>
.navContent {
  display: flex;
  text-decoration: none;
  align-items: center;
}
.navText {
  text-align: left;
  font: normal normal normal 16px Arial;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  text-decoration: none;
  text-underline: none;
  margin-left: 5px;
}
.navTextSelected {
  color: white;
}
.navTextNotSelected {
  color: black;
}
.navIcon {
  width: 22px;
  height: 22px;
}
</style>
