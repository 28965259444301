















import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class TaskItem extends Vue {

  @Prop()
  name: string

  @Prop()
  count: number

  @Prop()
  selected: boolean
}
