import {AxiosResponse} from "axios";

export interface PaginationInfo {
    pages: number,
    offset: number,
    limit: number
}

/**
 * Extracts pagination info from headers
 */
export const getPaginationInfo = (res: AxiosResponse, currentPaginationInfo: PaginationInfo): PaginationInfo => {
    const count = parseInt(res.headers['x-total-count']);
    const pages = Math.ceil(count / currentPaginationInfo.limit);
    return {
        pages: pages,
        offset: currentPaginationInfo.offset,
        limit: currentPaginationInfo.limit
    };
}
/**
 * Is another fetch possible given current state of pagination
 */
export const fetchingPossible = (currentPaginationInfo: PaginationInfo): boolean => {
    const currentCount = Math.floor(currentPaginationInfo.offset / currentPaginationInfo.limit);
    return currentCount <= currentPaginationInfo.pages;

}

/**
 * Returns true if bottom of scrollable dom element is reached and data needs to be loaded
 * @param e - event that is emitted from scroll
 */
export const readyForLoad = (e: any): boolean => {
    const scrollTop = e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const scrollHeight = e.target.scrollHeight;
    
    return scrollTop + clientHeight >= scrollHeight;
}

export const emptyPaginationInfo = {
    pages: 0,
    offset: 0,
    limit: 10
}
