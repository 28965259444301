





































































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {ShipmentUI, Customer, DriverUI, EditShipmentRequestData} from "@/utils/httpReqResMapping";
import PayerIcon from "@/components/PayerIcon.vue";
import EditShipmentModal from "@/components/EditShipmentModal.vue";
import {ShipmentMutations} from "@/store/shipment/mutations";
import {SpinnerMutations} from "@/store/spinner/mutations";
import BarCodeModal from "@/components/BarCodeModal.vue";
import {convertShipment} from "@/utils/shipment";
import ShipmentPointTableEntry from "@/components/ShipmentPointTableEntry.vue";

@Component({
  components: {ShipmentPointTableEntry, PayerIcon, EditShipmentModal, BarCodeModal}
})
export default class ShipmentTable extends Vue {
  showSubmitBtn = false

  @Prop() readonly search: string
  @Prop(Array)
  shipment!: ShipmentUI [];

  @Prop(Array)
  drivers!: DriverUI [];

  modal = false

  barcodeModal = false;
  barcodeSelection: ShipmentUI | null = null;

  headers = [
    {text: 'Naziv stranke, naslov', align: 'start', sortable: false, value: 'collectionPoint', 'class': 'table_entry'},
    {value: 'deliveryPoint', sortable: false},
    {text: 'Tel., DŠ', value: 'driver.phoneNumber', sortable: false, align: 'start'},
    {align: 'center', width: '3rem', value: 'deliverTime', sortable: false},
    {align: 'center', width: '7rem', value: 'driver', sortable: false},
    {align: 'center', width: '4rem', value: 'paymentType', sortable: false},
    {align: 'center', width: '8rem', value: 'packageNumber', text: 'St. paketa brez presledka', sortable: false},
    {align: 'center', text: 'Akcije', value: 'actions', sortable: false}
  ]

  // Convert unix timestamp to time windowed with +/- prefix
  timeWindowedTimeStamp(value: number): string {
    const current = Date.now() / 1000;
    const gap = current - value;
    const gapSeconds = Math.abs(Math.ceil(gap));

    const hours = `${Math.floor(gapSeconds / 3600) < 10 ? `0${Math.floor(gapSeconds / 3600)}` : `${Math.floor(gapSeconds / 3600)}`}`;
    const minutes = `${Math.floor((gapSeconds % 3600) / 60) < 10 ? `0${Math.floor((gapSeconds % 3600) / 60)}` : `${Math.floor((gapSeconds % 3600) / 60)}`}`;

    const prefix = gap <= 0 ? '-' : '+';

    return `${prefix}${hours}:${minutes}`;

  }

  @Emit('onPackageDelete')
  deletePackage(item: ShipmentUI): ShipmentUI {
    return item;
  }

  showBarcodeModal(item: ShipmentUI): void {
    this.barcodeModal = true;
    this.barcodeSelection = item;
  }

  // Return true if shipment is late in deliverance
  isBeingLate(value: number): boolean {
    const current = Date.now() / 1000;
    const gap = current - value;

    return gap > 0;
  }

  // Convert unix timestamp to hour:minutes timestamp
  toTimeStamp(value: number): string {
    const date = new Date(value * 1000);
    const hours = `${Math.floor(date.getHours()) < 10 ? `0${date.getHours()}` : `${date.getHours()}`}`;
    const minutes = `${Math.floor(date.getMinutes()) < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`}`;

    return `${hours}:${minutes}`;
  }

  editPackage(shipment: ShipmentUI): void {
    this.$store.commit(ShipmentMutations.SET_EDIT_SHIPMENT, convertShipment(shipment))
    this.modal = true;
  }

  @Watch('modal')
  async handleChange(val: boolean): Promise<void> {
    if (val) {
      this.$store.commit(SpinnerMutations.SPINNER_MODAL)
    } else {
      this.$store.commit(SpinnerMutations.SPINNER_NORMAL)
    }
  }

  onBarcodeModalClose(val: boolean): void {
    this.barcodeModal = val;
  }

  isCustomerObj(obj: unknown): obj is Customer {
    return !!(obj !== undefined && (obj as Customer).taxNumber);

  }

  // Custom search function to search nested objects
  filterOnCustomer(value: unknown, search: string): boolean {
    if (this.isCustomerObj(value)) {
      // Concatenated search values
      const searchValues = value.name + value.address.displayName
      return search != null &&
          searchValues.toLowerCase().indexOf(search.toLowerCase()) !== -1
    }
    return false
  }

  @Emit('packageChange')
  onPackageChange(item: ShipmentUI): EditShipmentRequestData {
    const obj = {
      id: item.id,
      deliveryPointId: item.deliveryPoint?.id,
      collectionPointId: item.collectionPoint?.id,
      payerId: item.payer?.id,
      driverId: item.driver?.id,
      footNote: item.footNote,
      paymentTypeId: item.paymentType?.id,
      deliveryTime: item.deliverTime,
      collectTime: item.collectTime,
      packageNumber: item.packageNumber
    }
    return obj
  }

  getTableClass(item: ShipmentUI): string {
    if (this.isBeingLate(item.deliverTime)) {
      return 'late'
    }
    return '';
  }
}
