

































import {Component, Vue, Prop} from "vue-property-decorator"
import {emptyGeocodingOBJ, CustomerRegisterRequestData, Customer} from "@/utils/httpReqResMapping";
import NewCustomer from "@/views/NewCustomer.vue";
@Component({
  components: {NewCustomer}
})
export default class EditCustomerModal extends Vue {
    @Prop() readonly modal: boolean
    @Prop() readonly customer: Customer

    editedCustomer: CustomerRegisterRequestData = {
        address: {...emptyGeocodingOBJ},
        name: '',
        contactPhone: '',
        taxNumber: ''
    }
}
