/***
 * @project redy
 * @author azurkurbasic on 12/12/2022
 */
import {MutationTree} from "vuex";
import {OverviewState} from "@/store/overview/types";
import {AdHocPickupDTO} from "@/utils/httpReqResMapping";

export enum OverviewMutations {
    SET_TASKS = "SET_TASKS",
    SET_TYPE = "SET_TYPE",
    SET_BASIC_INFO = "SET_BASIC_INFO",
    SET_SELECTED_TICKET = "SET_SELECTED_TICKET",
    UPDATE_TASK = "UPDATE_TASK",
    ADD_TASK_TO_ASSIGNED = "ADD_TASK_TO_ASSIGNED"
}

export const mutations: MutationTree<OverviewState> = {

    [OverviewMutations.SET_TASKS](state, task) {
        state.tasks = task;
    },

    [OverviewMutations.SET_TYPE](state, type) {
        state.chosenType = type
    },

    [OverviewMutations.SET_BASIC_INFO](state, info) {
        state.basicInfo = info
    },

    [OverviewMutations.SET_SELECTED_TICKET](state, info) {
        state.selectedTicket = info;
    },

    [OverviewMutations.UPDATE_TASK](state, task: AdHocPickupDTO) {
        state.tasks = state.tasks.map((currentTask) => {
            if (currentTask.id === task.id) {
                return {
                    ...task
                }
            } else {
                return {
                    ...currentTask
                }
            }
        })
    },

    [OverviewMutations.ADD_TASK_TO_ASSIGNED](state, task: AdHocPickupDTO) {
        // Ignore if task is already present
        if (state.tasks.find((it) => it.id === task.id)) return
        state.tasks = [
            ...state.tasks,
            task
        ]
    }
}
