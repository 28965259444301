import Vue from 'vue';
import {Mixin} from "vue-mixin-decorator";
import {emptyPaginationInfo, fetchingPossible, getPaginationInfo, PaginationInfo} from "@/utils/pagination";

@Mixin
export class PaginationMixin <T=any> extends Vue  {

    // Holds info for pagination
    paginationInfo: PaginationInfo = emptyPaginationInfo;

    // Holds array of fetched items
    items: T []  = [];

    // Fetch in progress
    fetchInProgress= false;

    allItems = 0;

    /**
     * Gets new page based on pagination info
     */
    async getNextPage(source: string, initPage: boolean, queryParams: any | undefined = undefined): Promise<T []> {
        if (this.fetchInProgress) {
            return [];
        }

        // Check if we overflow total pages
        const isFetchPossible = fetchingPossible(this.paginationInfo);
        this.fetchInProgress = true;

        if (isFetchPossible || initPage) {
            // Update pagination info
            let baseURL = `${process.env.VUE_APP_API_URL}${source}?`;

            if (queryParams) {
                for (const [key, value] of Object.entries(queryParams)) {
                    baseURL += `${key}=${value}&`
                }
            }
            const constructedURL = `${baseURL}offset=${this.paginationInfo.offset}&limit=${this.paginationInfo.limit}`;
            console.log(constructedURL)

            const res = await this.$http.get<T []>(constructedURL);
            this.paginationInfo = getPaginationInfo(res, this.paginationInfo);
            this.paginationInfo.offset += this.paginationInfo.limit;
            this.fetchInProgress = false;

            this.allItems = res.headers['x-total-count'];

            return res.data;
        }

        this.fetchInProgress = false;
        return [];
    }

    /**
     * Resets pagination information to default
     * Useful when filter criteria changes in
     * AutoSelect component
     */
    resetPaginationInfo(): void {
        this.paginationInfo.limit = 10;
        this.paginationInfo.offset = 0;
        this.paginationInfo.pages = 0;
    }

    changePaginationInfo(limit: number, offset: number): void {
        this.paginationInfo.limit = limit;
        this.paginationInfo.offset = offset;
        this.paginationInfo.pages = 0;
    }
}
