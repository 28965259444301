




import store from '../store/index'
import {Component, Vue } from "vue-property-decorator";
@Component
export default class DateHeader extends Vue {

  dateVal= this.$store.getters.getShiftDate;

  created(): void {
    store.watch(
        state => state.shift.activeShift,
        () => {
          this.dateVal = this.$store.getters.getShiftDate
        }
    )
  }

}
