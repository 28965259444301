









































































import {Component, Vue} from "vue-property-decorator";

@Component({})
export default class Allocations extends Vue {
    switchState = false;
    clicked = false;
}

