

















































import {Component, Emit, VModel, Vue} from "vue-property-decorator";
import {VMenu} from "@/views/types";
import {DeliveriesActions} from "@/store/deliveries/actions"
@Component
export default class DatePicker extends Vue {
  menu = false
  date = ''

  get vMenu(): VMenu {
    return this.$refs.menu as VMenu;
  }

  @Emit("onDate")
  onDate(): Date {
    this.$store.dispatch(DeliveriesActions.SET_DATE, this.date)
    this.vMenu.save(this.date);
    const dateOBJ = new Date(this.date);
    return dateOBJ;
  }
  mounted(): void {
    if(this.$store.getters.getDate !== '') { // if date is set in store
      this.date = this.$store.getters.getDate
      this.onDate()
    }
  }
}
