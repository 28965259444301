



































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {Vehicle} from "@/utils/httpReqResMapping";
import DecisionModal from "@/components/DecisionModal.vue";
import RegisterVehicleModal from "@/components/RegisterVehicleModal.vue";
@Component({
  components: {RegisterVehicleModal, DecisionModal}
})
export default class PackageTable extends Vue {
  showSubmitBtn = false

  @Prop(Array)
  vehicle!: Vehicle []

  headers = [
    { value: 'internNumber', text: 'Interna stevilka', align: 'start', sortable: false, 'class': 'table_entry' },
    { value: 'registrationNumber', text: 'Registerska stevilka', sortable: false },
    { value: 'weight', text: 'Teza', sortable: false, align: 'start', width: '6rem' },
    { value: 'capacity', text: 'Kapaciteta',  align: 'start', sortable: false, width:'6rem' },
    { value: 'type', text: 'Tip vozila', align: 'center', sortable: true },
    { value: 'actions', text: 'Akcije', align: 'center', sortable: false}
  ]

  @Emit("onDeleteVehicle")
  deleteItem(item: Vehicle): Vehicle {
    return item;
  }

  @Emit("onEditVehicle")
  editItem(item: Vehicle): Vehicle {
    console.log("On edit vehcile", item)
    return item;
  }

  // Information about vehicle type
  isCity(type: string): boolean {
    return type === 'ZM';
  }
}
