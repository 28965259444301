





















import {Component, Vue, Prop, Watch} from "vue-property-decorator"
import NewCustomer from "@/views/NewCustomer.vue";
import NewShipment from "@/views/NewShipment.vue";
import {AdHocPickupDTO} from "@/utils/httpReqResMapping";
@Component({
  components: {NewShipment, NewCustomer}
})
export default class NewShipmentModal extends Vue {

  @Prop() readonly modal!: boolean

  modalValue: boolean = this.modal;

  @Watch('modal')
  onModalChange(modal: boolean): void {
    console.log(modal, "newVal")
    this.modalValue = modal;
  }

  handleClose(data: AdHocPickupDTO | undefined): void {
    console.log('Emitting close with data from NewShipmentModal', data)
    this.$emit('close', data);
  }

}
