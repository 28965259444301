import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import httpClient from "@/utils/httpClient";
import {setupInterceptor} from "@/utils/httpInterceptors";
import {AxiosStatic} from "axios";
import backgroundHttpClient from "@/utils/backgroundHttpClient";

Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.prototype.$http = httpClient;
Vue.prototype.$httpBackground = backgroundHttpClient;

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosStatic;
  }
}
new Vue({
  router,
  store,
  vuetify,
  created() {
    setupInterceptor(store, false, httpClient);
    setupInterceptor(store, true, backgroundHttpClient);
  },
  render: h => h(App)
}).$mount('#app')
