import {ActionTree} from "vuex";
import {AuthState, JWTRefreshPayload} from "@/store/auth/types";
import {RootState} from "@/store/types";
import {LoginResponseData} from "@/utils/httpReqResMapping";
import {AuthMutations} from "@/store/auth/mutations";
import axios from "axios";
import {isExpired, parseJWT} from "@/utils/jwtUtils";
import eventBus, {BusEvents} from "@/eventBus";

export enum AuthActions {
    LOGOUT = "LOGOUT_ACTION",
    SET_TOKENS = "SET_TOKENS",
    REFRESH_TOKENS = "REFRESH_TOKENS",
}

export const actions: ActionTree<AuthState, RootState> = {
    [AuthActions.SET_TOKENS]({commit}, data: LoginResponseData): void {
        commit(AuthMutations.SET_AUTH_TOKEN, data.authToken);
        commit(AuthMutations.SET_REFRESH_TOKEN, data.refreshToken);
    },

    /**
     * Returns Promise with true if success
     */
    [AuthActions.REFRESH_TOKENS]({commit}, refreshToken: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            // Check if empty token or expired
            if (refreshToken !== '') {
                const obj = parseJWT<JWTRefreshPayload>(refreshToken);
                if (isExpired(obj.exp)) {
                    return resolve(false);
                }
            } else {
                return resolve(false);
            }

            // Try to obtain token from api
            axios.post<LoginResponseData>(`${process.env.VUE_APP_API_URL}/auth/refresh-token`, {refreshToken: refreshToken})
                .then((data) => {
                    commit(AuthMutations.SET_AUTH_TOKEN, data.data.authToken);
                    commit(AuthMutations.SET_REFRESH_TOKEN, data.data.refreshToken);
                    resolve(true);
                })
                .catch((err) => {
                    console.log(err);
                    resolve(false);
                })
        })
    },

    /**
     * Removes tokens from local storage
     */
    [AuthActions.LOGOUT]({commit}) {
        commit(AuthMutations.SET_AUTH_TOKEN, '');
        commit(AuthMutations.SET_REFRESH_TOKEN, '');
        eventBus.$emit(BusEvents.LOGOUT_EVENT);
    }
}
