<template>
  <v-slide-y-transition>
    <div class="mainContent" v-if="currentlyDisplayed">
      <div class="cancelIcon">
        <v-btn icon @click="cancelMessage">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div class="titleContainer">
        {{currentlyDisplayed.title}}
      </div>
      <div class="mainContainer">
        {{currentlyDisplayed.content}}
      </div>
    </div>
  </v-slide-y-transition>
</template>

<script>
import {SnackbarActions} from "@/store/snackbar/actions";

export default {
  name: "RedyTaskUpdateSnack",
  data: () => {
    return {
      currentlyDisplayed: ''
    }
  },
  computed: {
    messages() {
      return this.$store.getters['getTicketMessages'];
    }
  },
  watch: {
    messages(newVal) {
      if (!this.currentlyDisplayed && newVal.length > 0) {
        this.currentlyDisplayed = newVal[0];
        setTimeout(async () => {
          await this.cancelMessage();
        }, 4000);
      }
    },
    currentlyDisplayed(newVal) {
      if (!newVal && this.messages.length > 0) {
        this.currentlyDisplayed = newVal[0];
        setTimeout(async () => {
          await this.cancelMessage();
        }, 4000);
      }
    }
  },
  methods: {
    async cancelMessage() {
      await this.$store.dispatch(SnackbarActions.REMOVE_TICKET_MESSAGE, this.currentlyDisplayed);
      this.currentlyDisplayed = '';
    }
  }
}
</script>

<style scoped>
.mainContent {
  padding: 16px;
  border-radius: 17px;
  background-color: #FFD541;
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 350px;
}
.titleContainer {
  text-align: left;
  font: normal normal bold 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.mainContainer {
  text-align: left;
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-top: 6px;
}
.cancelIcon {
  position: absolute;
  right: 0;
  top:0;
}
</style>