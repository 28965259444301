/**
 * Declaration of shared rules for vuetify
 * input component
 */

import {Customer, Driver} from "@/utils/httpReqResMapping";

/**
 * Returns function that checks if values are same
 */
export const matchingValueRule = (value1: string, message: string) => {
    return (val2: string) => {
        return value1 === val2 || message;
    }
}

/**
 * Password rule
 */
export const passwordRule = (message = 'Geslo mora vsebovati vsaj en simbol, stevko, veliko in malo crko ter mora biti dolgo vsaj 8 znakov') => (s: string): boolean | string => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(s) || message;

/**
 * Non empty value rule
 */
export const nonEmptyRule = (message: string) => (s: string): boolean | string => !!s || message;

/**
 * Tax number rule - sl. davcna stevlika
 */
export const taxNumberRule = (message: string) => (s: string): boolean | string => s.length == 8 || message;

/**
 * Non empty customer rule
 */
export const nonEmptyCustomer = (message: string) => (c: Customer): boolean | string => c.id !== '' || message;

/**
 * Non empty driver rule
 */
export const nonEmptyDriver = (message: string) => (d: Driver): boolean | string => d.id !== '' || message;

/**
 * Non empty
 */
export type Entity = {
    id: string
}

export declare const EntityVar: Entity

export const nonEmptyEntity = <T extends Entity> (message: string) => (e: T): boolean | string => e.id !== '' || message

export const nonNullValue = <T> (message: string) => (e:T): boolean | string => e !== null && e !== undefined || message;
