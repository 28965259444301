/**
 * Converts nullable fields of shipments to empty fields
 */
import {AdHocPickupDTO, emptyCustomer, emptyDriver, emptyPaymentType, ShipmentUI} from "@/utils/httpReqResMapping";

export const convertShipment = (shipmentUI: ShipmentUI): ShipmentUI => {
    const convertedShipment = {
        ...shipmentUI
    };
    convertedShipment.paymentType = convertedShipment.paymentType == undefined
        ? emptyPaymentType : convertedShipment.paymentType
    convertedShipment.driver = convertedShipment.driver == undefined
        ? emptyDriver : convertedShipment.driver
    convertedShipment.payer = convertedShipment.payer == undefined
        ? emptyCustomer : convertedShipment.payer
    convertedShipment.collectionPoint = convertedShipment.collectionPoint == undefined
        ? emptyCustomer : convertedShipment.collectionPoint
    convertedShipment.deliveryPoint = convertedShipment.deliveryPoint == undefined
        ? emptyCustomer : convertedShipment.deliveryPoint

    return convertedShipment;
}

export enum ShipmentType {
    CITY_PICKUP="CITY_PICKUP",
    COUNTRY_PICKUP="COUNTRY_PICKUP",
    BASIC_PICKUP="BASIC_PICKUP"
}

export interface AdHocPickup {
    type: ShipmentType,
    name: string
}

export const availableAdHocTypes: AdHocPickup [] = [
    {
        type: ShipmentType.CITY_PICKUP,
        name: "ZM - Znotraj mesta"
    },
    {
        type: ShipmentType.COUNTRY_PICKUP,
        name: 'SI - Po Sloveniji'
    }
]

/**
 * Checks if adHoc shipments is of type Country pickup
 */
export const isCountryPickup = (adHoc: AdHocPickupDTO): boolean => {
    const deliveryPoints = new Set<string | undefined>();
    adHoc.shipments.forEach((it) => {
        deliveryPoints.add(it.deliveryPoint?.id)
    })
    console.log(deliveryPoints)
    return deliveryPoints.size === 1;
}
