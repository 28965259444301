



































































































import ViewWrapper from "@/components/ViewWrapper.vue";
import {Vue, Component, Watch} from "vue-property-decorator";
import {BasicAnalyticaDTO, ShiftVehicleSumRelationDTO, Vehicle} from "@/utils/httpReqResMapping";
import VehicleAnalyticsTable from "@/components/VehicleAnalyticsTable.vue";
import AnalyticsCard from "@/components/AnalyticsCard.vue";

@Component({
  components: {AnalyticsCard, VehicleAnalyticsTable, ViewWrapper}
})
export default class Analytics extends Vue {

  dates: string [] = [];

  analyticsInfo: ShiftVehicleSumRelationDTO [] = [];
  analyticsFetchInProgress = false

  basicAnalytica: BasicAnalyticaDTO | null = null
  basicAnalyticaFetchInProgress = false

  vehicles: Vehicle [] = [];

  @Watch('dates')
  handleDates(newVal?: string []): void {
    if (newVal != undefined && newVal.length >= 2) {
      this.getVehiclesAnalytics();
    }
  }

  async getVehiclesAnalytics(): Promise<void> {
    if (!this.analyticsFetchInProgress) {
      this.analyticsFetchInProgress = true;
      const fromDate = Math.floor(Date.parse(this.dates[0]) / 1000);
      const toDate = Math.floor(Date.parse(this.dates[1]) / 1000);
      const res = await this.$http.get<ShiftVehicleSumRelationDTO []>(`${process.env.VUE_APP_API_URL}/analytics/vehicles?fromDate=${fromDate}&toDate=${toDate}`);
      this.analyticsInfo = res.data;
      await this.getBasicAnalytica();
      this.analyticsFetchInProgress = false;
    }
  }

  async getBasicAnalytica(): Promise<void> {
    if (!this.basicAnalyticaFetchInProgress) {
      this.basicAnalyticaFetchInProgress = true;
      const fromDate = Math.floor(Date.parse(this.dates[0]) / 1000);
      const toDate = Math.floor(Date.parse(this.dates[1]) / 1000);
      this.basicAnalytica = (await this.$http.get<BasicAnalyticaDTO>(`${process.env.VUE_APP_API_URL}/analytics/basic?fromDate=${fromDate}&toDate=${toDate}`)).data;
      this.basicAnalyticaFetchInProgress = false;
    }
  }

  async getVehicles(): Promise<void> {
    this.vehicles = (await this.$http.get<Vehicle []>(`${process.env.VUE_APP_API_URL}/vehicle?loadAll=true`)).data;
  }

  async beforeMount(): Promise<void> {
    await this.getVehicles();
  }

  getKilometers(distanceInMeters: number): number {
    return Math.floor(distanceInMeters / 1000)
  }
}
