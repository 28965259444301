import {Mixin} from "vue-mixin-decorator";
import Vue from "vue";
import WebsocketManager from "@/utils/socketManager";

/**
 * Responsible for establishing socket connection
 * and refreshing it
 */
@Mixin
export class SocketConsumerMixin extends Vue  {

    // WebSocketManager instance
    websocket: WebsocketManager = new WebsocketManager();
    authToken: string = this.$store.getters.getAuthToken

    beforeDestroy(): void {
        // Close socket connection when component is destroyed
        this.websocket.close();
    }

    async mounted(): Promise<void> {
        this.websocket.refreshToken(this.authToken)

        // Register watcher on vuex store to reinitialize socket connection
        // when auth token expires and new pair of refresh / auth token is
        // available for use
        this.$store.watch(
            state => state.auth.authToken,
            (newToken) => {
                this.authToken = newToken;
                this.websocket.refreshToken(this.authToken)
            }
        )
    }
}
