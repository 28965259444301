import Vue from 'vue'
import store from '../store/index'
import VueRouter, { RouteConfig } from 'vue-router'
import Register from "@/views/Register.vue";
import Login from "@/views/Login.vue";
import Overview from "@/views/Overview.vue";
import Shipment from "@/views/Shipment.vue";
import Drivers from "@/views/Drivers.vue";
import Reports from "@/views/Reports.vue";
import NewShipment from "@/views/NewShipment.vue";
import NewDriver from "@/views/NewDriver.vue";
import Customers from "@/views/Customers.vue";
import NewCustomer from "@/views/NewCustomer.vue";
import ShipmentImport from "@/views/ShipmentImport.vue";
import Vehicles from "@/views/Vehicles.vue";
import Deliveries from '@/views/Deliveries.vue';
import Delivery from "@/views/Delivery.vue";
import Messaging from "@/views/Messaging.vue";
import Analytics from '@/views/Analytics.vue'
import Allocations from '@/views/Allocations.vue'
import Profile from '@/views/Profile.vue'
import Tasks from '@/views/Tasks.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/',
    name: 'Overview',
    component: Overview
  },
  {
    path: '/posiljke',
    name: 'Shipment',
    component: Shipment
  },
  {
    path: '/vozniki',
    name: 'Drivers',
    component: Drivers
  },
  {
    path: '/porocila',
    name: 'Reports',
    component: Reports
  },
  {
    path: '/posiljka/nova',
    name: 'NewShipment',
    component: NewShipment
  },
  {
    path: '/voznik/novi',
    name: 'NewDriver',
    component: NewDriver
  },
  {
    path: '/stranke',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/stranke/nova',
    name: 'NewCustomer',
    component: NewCustomer
  },
  {
    path: '/posiljka/uvoz',
    name: 'ShipmentImport',
    component: ShipmentImport
  },
  {
    path: '/vozila',
    name: 'Vehicles',
    component: Vehicles
  },
  {
    path: '/dostava',
    name: 'Deliveries',
    component: Deliveries
  },
  {
    path: '/dostava/:deliveryId',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/sporocanje',
    name: 'Messaging',
    component: Messaging
  },
  {
    path: '/analitika',
    name: 'Analytics',
    component: Analytics
  },
  {
    path: '/allocation',
    name: 'Allocations',
    component: Allocations
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  }
]

const router = new VueRouter({
  routes
})

// Setup guard for routes
router.beforeEach((to, from, next) => {
  // If user has valid auth token or it has valid refresh token let user go to
  // desired route and httpClient will automatically handle all edge cases
  const isAuthenticated = store.getters.isLoggedIn || store.getters.validRefreshToken
  if (to.name !== 'Login' && to.name !== 'Register' && !isAuthenticated) {
    next({name: 'Login'});
  } else if (isAuthenticated && (to.name === 'Login' || to.name === 'Register' || to.name === undefined)) {
    next({
      name: from.name || 'Overview'
    })
  } else {
    next();
  }
})

export default router
