



































































































import {Component} from "vue-property-decorator";
import DriverTable from "@/components/DriverTable.vue";
import DateHeader from "@/components/DateHeader.vue";
import {Driver, DriverUI} from "@/utils/httpReqResMapping";
import {ActiveShift} from "@/store/shift/types";
import store from "@/store";
import {AxiosResponse} from "axios";
import {Entity} from "@/utils/rules";
import {Mixins} from "vue-mixin-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import EmptyAlert from "@/components/EmptyAlert.vue";

@Component({
    components: {
      DriverTable, DateHeader, EmptyAlert
  }
})
export default class Drivers extends Mixins <PaginationMixin <Driver> > (PaginationMixin) {

  text = "";
  test: Entity | undefined = undefined;

  // All active drivers for current shift
  activeDrivers: DriverUI [] = [];
  currentActiveDrivers: DriverUI [] = [];

  // All drivers registered to our system
  allDrivers: DriverUI [] = [];
  currentAllDrivers: DriverUI [] = [];

  fetchAll = false;
  fetchActive = false;

  itemsPerPage = [10, 50];
  itemsPerPageText = ["Show 10", "Show 50"];
  selected = "Show 10";
  selectedItem = 10
  clicked = false;
  page = 0;

  async setPageNum(pageNum: number) {
    this.page = pageNum;
    this.changePaginationInfo(this.selectedItem, (this.page) * this.selectedItem);
    this.allDrivers = (await this.getAllDrivers()).map((item) => {
      const active = this.activeDrivers.find((item2) => item2.id === item.id );
      return {
        ...item,
        active: active !== undefined
      }
    });
  }

  handleClick() {
    this.clicked = true;
  }

  async setNumberOfItems(index: number) {
    this.selected = this.itemsPerPageText[index];
    this.selectedItem = this.itemsPerPage[index];
    this.page = 0;
    this.changePaginationInfo(this.selectedItem, 0);
    this.allDrivers = (await this.getAllDrivers()).map((item) => {
      const active = this.activeDrivers.find((item2) => item2.id === item.id );
      return {
        ...item,
        active: active !== undefined
      }
    });
  }

  async search() {
    var queryParameters = {'name': this.text}
    this.changePaginationInfo(this.selectedItem, 0);
    this.allDrivers = (await this.getAllDrivers(queryParameters)).map((item) => {
      const active = this.activeDrivers.find((item2) => item2.id === item.id );
      return {
        ...item,
        active: active !== undefined
      }
    });
  }

  async getAllDrivers(queryParameters: any | undefined = undefined): Promise<Driver[]> {
    const res =  await this.getNextPage(`/driver`, true, queryParameters);
    this.fetchAll = true;
    return res;
  }

  async getDriversForActiveShift(): Promise<AxiosResponse<Driver[]>> {
    const res = await this.$http.get<Driver []>(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers`);
    this.fetchActive = true;
    return res;
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  async created(): Promise<void> {
    this.activeDrivers = (await this.getDriversForActiveShift()).data.map((item) => {
      return {
        ...item,
        active: true
      }
    });

    this.allDrivers = (await this.getAllDrivers()).map((item) => {
      const active = this.activeDrivers.find((item2) => item2.id === item.id );
      return {
        ...item,
        active: active !== undefined
      }
    });
  }

  updateDriver(updatedDriver: DriverUI) {
        const activeDriverIndex = this.activeDrivers.findIndex(el => el.id === updatedDriver.id)
        if (activeDriverIndex > -1) {
            this.activeDrivers.splice(activeDriverIndex, 1, updatedDriver)
        }
        const allDriverIndex = this.allDrivers.findIndex(el => el.id === updatedDriver.id)
        if (allDriverIndex > -1) {
            this.allDrivers.splice(allDriverIndex, 1, updatedDriver)
        }
  }

  async handleStatusAll (driver: DriverUI): Promise<void> {
    // Can change from active to inactive of vice versa
    if (!driver.active) { // driver.active is already changed when the method gets called
      // Change to inactive
      this.activeDrivers = this.activeDrivers.filter((item) => item.id !== driver.id);
      await this.removeDriverFromShift(driver.id);
    } else {
      // Change to active
      await this.assignDriverToShift(driver.id);
      this.activeDrivers.push({...driver, active: true});
    }

    this.allDrivers = this.allDrivers.map((item) => {
      if (item.id === driver.id) {
        return {...item}
      } else {
        return {...item}
      }
    })
  }

  async handleStatusActive (driver: DriverUI): Promise<void> {
    // Can only change to inactive
    await this.removeDriverFromShift(driver.id);
    this.activeDrivers = this.activeDrivers.filter((item) => item.id !== driver.id);
    this.allDrivers = this.allDrivers.map((item) => {
      if (item.id === driver.id) {
        return {
          ...item,
          active: false
        }
      } else {
        return item
      }
    })
  }

  async removeDriverFromShift(driverID: string): Promise<void> {
    await this.$http.delete(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers/${driverID}`);
  }

  async assignDriverToShift(driverID: string): Promise<void> {
    await this.$http.post(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers`, {
      driverId: driverID
    });
  }

  async fetchDrivers(): Promise<void> {
    const data = await this.getNextPage("/driver", false);
    const mapped: DriverUI [] = data.map((item) => {
      const active = this.activeDrivers.find((item2) => item2.id === item.id);
      return {
        ...item,
        active: active !== undefined
      }
    })
    this.allDrivers = this.allDrivers.concat(mapped);
  }




}
