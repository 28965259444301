import {GetterTree} from "vuex";
import {DeliveriesState} from "@/store/deliveries/types";
import {RootState} from "@/store/types";

export const getters: GetterTree<DeliveriesState, RootState> = {
    /**
     * Get date (deliveries screen)
     */
    getDate(state): string {
        return state.date
    }
}
