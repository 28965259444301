
























import {Component, Prop, Vue, Watch} from "vue-property-decorator"

@Component
export default class InputField extends Vue {
  @Prop() label: string;
  @Prop() type: string;
  @Prop() error: boolean;
  @Prop() errorMessage: string;

  mText = ''

  @Watch('mText')
  onPropertyChanged() {
    this.$emit('sendValue', this.mText)
  }
}
