import {MutationTree} from "vuex";
import {SpinnerState} from "@/store/spinner/types";

export enum SpinnerMutations {
    SHOW_SPINNER="SHOW_SPINNER",
    HIDE_SPINNER="HIDE_SPINNER",
    SPINNER_MODAL="SPINNER_MODAL",
    SPINNER_NORMAL="SPINNER_NORMAL"
}

export const mutations: MutationTree<SpinnerState> = {

    [SpinnerMutations.SHOW_SPINNER](state) {
        state.show = true;
    },

    [SpinnerMutations.HIDE_SPINNER](state) {
        state.show = false;
    },

    [SpinnerMutations.SPINNER_MODAL](state) {
        state.isModal = true;
    },

    [SpinnerMutations.SPINNER_NORMAL](state) {
        state.isModal = false;
    }
}
