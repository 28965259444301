import {ImportProcessState, ImportProcessStatus} from "@/store/importProcess/types";
import {Module} from "vuex";
import {RootState} from "@/store/types";
import {mutations} from "@/store/importProcess/mutations";
import {actions} from "@/store/importProcess/actions";
import {getters} from "@/store/importProcess/getters";

const state: ImportProcessState = {
    status: ImportProcessStatus.WAITING,
    unconfirmedShipmentData: [],
    totalShipments: 0,
    currentShipment: 0
}

export const importProcess: Module<ImportProcessState, RootState> = {
    state,
    mutations,
    actions,
    getters
}
