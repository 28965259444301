


































































import {Component} from "vue-property-decorator";
import ShipmentTable from "../components/ShipmentTable.vue";
import DateHeader from "@/components/DateHeader.vue";
import {Mixins} from "vue-mixin-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {ActiveShift} from "@/store/shift/types";
import store from "@/store";
import {DriverUI, EditShipmentRequestData, ShipmentUI} from "@/utils/httpReqResMapping";
import {readyForLoad} from "@/utils/pagination";
import EmptyAlert from "@/components/EmptyAlert.vue";
import PackageTable from "@/components/PackageTable.vue";
import DecisionModal from "@/components/DecisionModal.vue";
import NewShipmentModal from "@/components/NewShipmentModal.vue";

@Component({
  components: {
    DecisionModal,
    NewShipmentModal,
    PackageTable,
    EmptyAlert,
    DateHeader,
    ShipmentTable
  }
})
export default class Shipment extends Mixins<PaginationMixin<ShipmentUI>>(PaginationMixin) {

  shipments: ShipmentUI [] = [];
  drivers: DriverUI [] = [];
  fetch = false;
  search = '';
  marker = true;
  newShipmentModal = false;

  async mounted(): Promise<void> {
    this.init()
  }

  handleClose() {
    this.newShipmentModal = false
    this.fetch = false
    this.init()
  }

  async init() {
    this.resetPaginationInfo()
    this.shipments = await this.getNextPage(`/shifts/${this.shift.id}/shipment`, true)
    this.drivers = (await this.$http.get(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers`)).data
    this.fetch = true;
  }

  updateTable(shipment: ShipmentUI): void {
    const shipmentIndex = this.shipments.findIndex(el => el.id === shipment.id)
    if (shipmentIndex > -1) {
      this.shipments.splice(shipmentIndex, 1, shipment)
    }
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  // Computed property getter over decision modal
  get decisionModal(): DecisionModal {
    return this.$refs.decisionModal as DecisionModal;
  }

  endIntersect(e: unknown): void {
    const fetch = readyForLoad(e);
    if (fetch) {
      this.loadPage();
    }
  }

  async loadPage(): Promise<void> {
    const data = await this.getNextPage(`/shifts/${this.shift.id}/shipment`, false);
    this.shipments.push(...data);
  }

  /**
   * @param item - shipment object
   * it updates given shipment according to changes
   */
  async onPackageChange(item: EditShipmentRequestData): Promise<void> {
    await this.$http.put(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/shipment`, item)
  }

  /**
   * @param item - shipment object
   */
  async onPackageDelete(item: ShipmentUI): Promise<void> {
    let additionalText = item.driver ? `S to akcijo lahko povzročite nevšečnosti vozniku ${item.driver.workerName} ${item.driver.workerLastName}` : '';
    const decision = await this.decisionModal.showModal({
      title: 'Brisanje pošiljke',
      message: `Ali ste prepričani, da želite izbrisati pošiljko ? ${additionalText}`,
      positiveDecision: 'Izbriši',
      negativeDecision: 'Prekliči'
    });
    if (decision) {
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/shipment/${item.id}`);
      this.shipments = this.shipments.filter((it) => it.id !== item.id);
    }
  }

}
