/***
 * @project redy
 * @author azurkurbasic on 12/12/2022
 */
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";

export interface OverviewState {
    tasks: AdHocPickupDTO [],
    chosenType: string,
    basicInfo?: ShiftBasicInfoDTO,
    selectedTicket?: AdHocPickupDTO
}

export const initState = {
    tasks: [],
    chosenType: 'unassigned',
    basicInfo: undefined,
    selectedTicket: undefined
}