


































import {Component, Vue} from "vue-property-decorator";
import {ModalObject} from "@/views/types";
@Component
export default class DecisionModal extends Vue {

  modalOBJ: ModalObject = {
    message: '',
    title: '',
    positiveDecision: '',
    negativeDecision: ''
  }

  dialog = false;

  resolve!: (v:boolean) => void

  reject!: (r:any) => void

  showModal(obj: ModalObject): Promise<boolean> {
    this.dialog = true;
    this.modalOBJ = obj;

    return new Promise<boolean>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  handleClick(result:boolean): void {
    this.resolve(result);
    this.dialog = false;
  }

}
