
















import {Component, Vue } from "vue-property-decorator";
@Component
export default class Snackbar extends Vue {

  show = false
  message = ''
  color = 'success'
  timeout = 3000

  showSnack(message: string): void {
    this.message = message;
    this.show = true;
    setTimeout(() => {
      this.show = false;
    }, this.timeout)
  }

}
