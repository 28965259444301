





































import {Component, Prop, Vue} from "vue-property-decorator";
import {ShiftVehicleSumRelationDTO, Vehicle} from "@/utils/httpReqResMapping";

@Component
export default class VehicleAnalyticsTable extends Vue {

  @Prop(Array)
  vehicleInfo!: ShiftVehicleSumRelationDTO []

  @Prop(Array)
  vehicles: Vehicle []

  headers = [
    { value: 'totalDistance', text: 'Prevožena razdalja', align: 'start', sortable: false },
    { value: 'shiftCount', text: 'Število izmen', align: 'start', sortable: false },
    { value: 'internNumber', text: 'Interna številka', align: 'start', sortable: false },
    { value: 'regNumber', text: 'Registerska številka', align: 'start', sortable: false},
    { value: 'actions', text: 'Akcija', align: 'start', sortable: false}
  ]

  getKilometers(distanceInMeters: number): number {
    return Math.floor(distanceInMeters / 1000)
  }

  getVehicle(vehicleId: string): Vehicle | undefined {
    return this.vehicles.find((it) => {
      return it.id === vehicleId;
    })
  }

  onInfoClick(item: ShiftVehicleSumRelationDTO) {
    console.log(item)
  }
}
