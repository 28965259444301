import {GetterTree} from "vuex";
import {AuthState, JWTAuthPayload, JWTRefreshPayload} from "@/store/auth/types";
import {RootState} from "@/store/types";
import {isExpired, parseJWT} from "@/utils/jwtUtils";

export const getters: GetterTree<AuthState, RootState> = {

    /**
     * takes auth token from store and check if it is valid and expired
     */
    isLoggedIn (state): boolean {
        if (state.authToken === '') {
            return false;
        }
        const authPayload = parseJWT<JWTAuthPayload>(state.authToken);

        return !isExpired(authPayload.exp);
    },

    getRefreshToken (state): string {
      return state.refreshToken;
    },

    getAuthToken (state): string {
        return state.authToken;
    },

    /**
     * returns true if tokens can be refreshed
     */
    validRefreshToken(state): boolean {
        if (state.refreshToken === '') {
            return false;
        }
        const refreshPayload = parseJWT<JWTRefreshPayload>(state.refreshToken);

        return !isExpired(refreshPayload.exp);
    },

    getUsername (state): Record<string, string> {
        if (state.authToken === '') {
            return {};
        }

        const authPayload = parseJWT<JWTAuthPayload>(state.authToken);

        return {"name": authPayload.name, "lastName": authPayload.lastName, "workerNumber": authPayload.workerNumber};
    }
}
