





























































































































import {Component, Vue} from "vue-property-decorator";
import {JWTAuthPayload} from "@/store/auth/types";
import {parseJWT} from "@/utils/jwtUtils";
import {SnackbarActions} from "@/store/snackbar/actions";
import {VForm} from "@/views/types";

@Component({})
export default class Profile extends Vue {

  user: JWTAuthPayload | undefined = undefined;

  newPw = '';
  newPwRepeat = '';

  pwRule = [ (v: string) => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'Geslo mora vsebovati vsaj en simbol, stevko, veliko in malo crko ter mora biti dolgo vsaj 8 znakov.']

  get pwResetForm(): VForm {
    return this.$refs.pwResetForm as VForm
  }

  async resetPW(): Promise<void> {
    const valid = this.pwResetForm.validate();
    if (!valid) return
    if (this.newPwRepeat !== this.newPw) {
      return this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        type: 'error',
        message: 'Passwords must match'
      });
    }
    try {
      await this.$http.post(`${process.env.VUE_APP_API_URL}/auth/pw-change`, {
        newPassword: this.newPw
      });
      this.newPw = ''
      this.newPwRepeat = ''
      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        type: 'success',
        message: 'Successfully updated password'
      });
      this.pwResetForm.resetValidation()
    } catch (e) {
      console.log(e)
    }
  }

  async created(): Promise<void> {
    this.user = parseJWT<JWTAuthPayload>(this.$store.getters.getAuthToken);
  }
}
