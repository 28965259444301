import {GetterTree} from "vuex";
import {ShipmentState} from "@/store/shipment/types";
import {RootState} from "@/store/types";
import {ShipmentUI} from "@/utils/httpReqResMapping";

export const getters: GetterTree<ShipmentState, RootState> = {
    /**
     * Get the shipment that is currently being edited
     */
    getEditingShipment(state): ShipmentUI {
        return state.editingShipment
    }
}
