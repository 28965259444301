import {MutationTree} from "vuex";
import {ImportProcessState, ImportProcessStatus} from "@/store/importProcess/types";
import {AutoGeneratedShipment} from "@/utils/httpReqResMapping";

export enum ImportProcessMutations {
    ADD_UNCONFIRMED_PACKAGE = "ADD_UNCONFIRMED_PACKAGE",
    REMOVE_UNCONFIRMED_PACKAGE = "REMOVE_UNCONFIRMED_PACKAGE",
    SET_STATUS = "SET_STATUS",
    SET_TOTAL_SHIPMENTS = "SET_TOTAL_SHIPMENTS",
    INCREMENT_CURRENT_SHIPMENT = "INCREMENT_CURRENT_SHIPMENT"
}

export const mutations: MutationTree<ImportProcessState> = {

    [ImportProcessMutations.ADD_UNCONFIRMED_PACKAGE] (state, pack: AutoGeneratedShipment) {
        state.unconfirmedShipmentData.push(pack);
    },

    [ImportProcessMutations.REMOVE_UNCONFIRMED_PACKAGE] (state, pack: AutoGeneratedShipment) {
      state.unconfirmedShipmentData = state.unconfirmedShipmentData.filter((item) => {
        return item.deliveryPoint.name !== pack.deliveryPoint.name && item.deliveryPoint.address.place_id !== pack.deliveryPoint.address.place_id
      })
    },

    [ImportProcessMutations.SET_STATUS] (state, status: ImportProcessStatus) {
        if (status === ImportProcessStatus.WAITING) {
            // Reset state to default state
            state.unconfirmedShipmentData = [];
            state.currentShipment = 0;
            state.totalShipments = 0;
        }
        state.status = status;
    },

    [ImportProcessMutations.SET_TOTAL_SHIPMENTS] (state, count: number) {
        state.totalShipments = count;
    },

    [ImportProcessMutations.INCREMENT_CURRENT_SHIPMENT] (state) {
        state.currentShipment += 1;
    }
}
