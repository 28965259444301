






























































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import {getDateString, getHourString} from '@/utils/date';
import {parseXLSX} from "@/utils/csvReader";
import {AvteraProvider} from "@/utils/customersImports";
import AutoSelect from "@/components/AutoSelect.vue";
import {AutoGeneratedShipment, Customer, emptyCustomer, emptyPaymentType, PaymentType} from "@/utils/httpReqResMapping";
import {ImportProcessActions, ImportProcessData} from "@/store/importProcess/actions";
import {nonEmptyRule, nonNullValue} from "@/utils/rules";
import {VForm} from "@/views/types";
import {ActiveShift} from "@/store/shift/types";
import store from "@/store";
import Progress from "@/components/Progress.vue";
import {ImportProcessState, ImportProcessStatus} from "@/store/importProcess/types";
import PackageImportTable from "@/components/PackageImportTable.vue";
import DecisionModal from "@/components/DecisionModal.vue";
import {SnackbarActions} from "@/store/snackbar/actions";

@Component({
  components: {DecisionModal, PackageImportTable, Progress, AutoSelect}
})
export default class ShipmentImport extends Vue {

  file: File | null = null;

  pickupDate = "";
  pickupHour = "";
  collectTime = 0;
  pickupPoint: Customer = {...emptyCustomer};
  paymentType: PaymentType = {...emptyPaymentType};

  typesOfPayment: PaymentType [] = [];

  rules = {
    sharedRule: [nonEmptyRule("Polje je obvezno !")],
    sharedNonNull: [nonNullValue("Polje je obvezno !")]
  }

  async created(): Promise<void> {
    this.typesOfPayment = (await this.$http.get<PaymentType []>(`${process.env.VUE_APP_API_URL}/customer/payment`)).data;
  }

  async startImportProcess(): Promise<void> {
    const valid = this.form.validate();
    if (!valid) {
      return;
    }
    try {
      const result = await parseXLSX<AvteraProvider>(this.file as File);
      this.progressInfo.totalCount = result.length;

      const data: ImportProcessData = {
        shipmentRawData: result,
        pickupPoint: this.pickupPoint,
        pickupTime: this.collectTime,
        deliveryTime: this.collectTime,
        paymentType: this.paymentType as PaymentType,
        activeShiftId: this.shift.id
      }

      await store.dispatch(ImportProcessActions.START_EXPORT_PROCESS, data);
    }catch (e: unknown) {
      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        message: 'Prislo je do napake pri avtomatskem uvozu, prosimo preverite format !',
        type: 'error'
      });
    }
  }

  // Mutate expected pickup arrival time
  mutateTime(minutes: number): void {
    this.collectTime = Math.floor((Date.now() / 1000) + (minutes * 60));
  }

  @Watch('collectTime')
  changeTime(time: number): void {
    this.pickupDate = getDateString(time);
    this.pickupHour = getHourString(time);
  }

  // Reference to form
  get form(): VForm {
    return this.$refs.form as VForm;
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  // Computed property getter - current status of import process
  get currentStatus(): ImportProcessStatus {
    return store.state.importProcess.status;
  }

  // Returns true if import process in certain state
  inAction(): boolean {
    return this.currentStatus === ImportProcessStatus.CONFIRMATION_WAIT ||
        this.currentStatus === ImportProcessStatus.WORKING;
  }

  get progressOfImport(): number {
    if (this.progressInfo.currentCount === 0) {
      return 0;
    }
    return Math.floor((this.progressInfo.currentCount / this.progressInfo.totalCount) * 100)
  }

  get importProcess(): ImportProcessState {
    return store.state.importProcess;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get progressInfo () {

    const message = this.importProcess.status === ImportProcessStatus.WORKING ? 'Avtomatski uvoz posiljk v poteku...'
        : 'Avtomatski uvoz posiljk koncan, prosimo potrdite nepotrjene posiljke !';

    return {
      currentCount: this.importProcess.currentShipment,
      totalCount: this.importProcess.totalShipments,
      message: message
    }
  }

  // dispatches action to store to remove customer and all packages
  removeCustomer(item: AutoGeneratedShipment): void {
    this.$store.dispatch(ImportProcessActions.REMOVE_CUSTOMER, item);
  }

  // dispatches action to store to add customer and all packages
  addCustomer(item: AutoGeneratedShipment): void {
    this.$store.dispatch(ImportProcessActions.CONFIRM_CUSTOMER, item)
  }

  // dispatches action to store to add customer and all packages
  // customer in this case is manually created
  createCustomer(item: AutoGeneratedShipment): void {
    this.$store.dispatch(ImportProcessActions.CONFIRM_CUSTOMER, item);
  }


}
