import {ActionTree} from "vuex";
import {CustomerState} from "@/store/customer/types";
import {RootState} from "@/store/types";
import {CustomerMutations} from "@/store/customer/mutations";
import {Customer} from "@/utils/httpReqResMapping";

export enum CustomerActions {
    GET_CUSTOMER="GET_CUSTOMER"
}

export const actions: ActionTree<CustomerState, RootState> = {
    /**
     * This action is dispatched to fetch and set all data needed for editing a customer
     */
    async [CustomerActions.GET_CUSTOMER]({ commit }, customer: Customer): Promise<void> {
        commit(CustomerMutations.SET_CUSTOMER_ID, customer.id)
        commit(CustomerMutations.SET_EDIT_CUSTOMER_DATA, customer);
    }
}
