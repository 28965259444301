

















































































import {Component, Vue} from "vue-property-decorator";
import {RegisterRequestData} from "@/utils/httpReqResMapping.ts";
import {SnackbarActions} from "@/store/snackbar/actions";
import {VForm} from "@/views/types";

@Component({})
export default class Register extends Vue {

    registerData: RegisterRequestData = {
        workerNumber: '',
        workerPassword: '',
        workerPasswordRepeat: ''
    }

    // Holds validity of form
    validForm = true

    // Reference to form
    get form(): VForm {
        return this.$refs.form as VForm;
    }

    // Sends register request
    async registerUser(): Promise<void> {
        try {
            // Validate form
            this.validForm = this.form.validate()
            if (!this.validForm) {
                // Notify client with warning
                await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                    type: 'warning',
                    message: 'Prosimo izpolnite obrazec za prijavo pravilno !'
                })
                return
            }

            // Obtain auth and refresh token if success
            await this.$http.post(`${process.env.VUE_APP_API_URL}/auth/register`, this.registerData)
            // Notify client about successfully created account
            await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                message: 'Uspesno ste kreirali uporabniski racun',
                type: 'info',
            })
            this.form.reset();
        } catch (e) {
            console.log(e);
        }

    }

    numberRules = [
        (v: string): boolean | string => !!v || 'Stevilka delavca ne sme biti prazna',
    ]

    passwordRule = [
        (v: string): boolean | string => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'Geslo mora vsebovati vsaj en simbol, stevko, veliko in malo crko ter mora biti dolgo vsaj 8 znakov'
    ]

    repeatPasswordRule = [
        (v: string): boolean | string => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'Geslo mora vsebovati vsaj en simbol, stevko, veliko in malo crko ter mora biti dolgo vsaj 8 znakov',
        (v: string): boolean | string => v == this.registerData.workerPassword || `Gesli se ne ujemata`
    ]


}
