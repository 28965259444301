





























































import {Component, Vue} from "vue-property-decorator";
@Component
export default class LegendDialog extends Vue {
  dialog = false;

  showModal(): void {
    this.dialog = true;
  }


}
