var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"tableContainer mt-7",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.vehicleInfo,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.totalDistance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getKilometers(item.totalDistance))+" km ")]}},{key:"item.internNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVehicle(item.vehicleId).internNumber)+" ")]}},{key:"item.regNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVehicle(item.vehicleId).registrationNumber)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onInfoClick(item)}}},[_c('v-icon',[_vm._v(" mdi-information ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }