import {ActionTree} from "vuex";
import {ImportProcessState, ImportProcessStatus} from "@/store/importProcess/types";
import {RootState} from "@/store/types";
import {ImportProcessMutations} from "@/store/importProcess/mutations";
import {AvteraProvider} from "@/utils/customersImports";
import {
    AutoGeneratedShipment,
    Customer,
    CustomerRegisterRequestData,
    emptyGeocodingOBJ,
    NewShipmentRequestData,
    GeocodingOBJ,
    PaymentType
} from "@/utils/httpReqResMapping";
import {AxiosResponse} from "axios";
import {SnackbarMutations} from "@/store/snackbar/mutations";
import backgroundHttpClient from "@/utils/backgroundHttpClient";

export enum ImportProcessActions {
    START_EXPORT_PROCESS="START_EXPORT_PROCESS",
    ABORT_EXPORT_PROCESS="ABORT_EXPORT_PROCESS",
    COMMIT_EXPORT_PROCESS="COMMIT_EXPORT_PROCESS",
    CONFIRM_CUSTOMER="CONFIRM_CUSTOMER",
    REMOVE_CUSTOMER="REMOVE_CUSTOMER"
}

export interface ImportProcessData {
    shipmentRawData: AvteraProvider [],
    pickupPoint: Customer,
    pickupTime: number,
    deliveryTime: number,
    paymentType: PaymentType,
    activeShiftId: string
}

export const actions: ActionTree<ImportProcessState, RootState> = {

    [ImportProcessActions.ABORT_EXPORT_PROCESS]({ commit }): void {
        commit(ImportProcessMutations.SET_STATUS, ImportProcessStatus.WAITING);
    },

    async [ImportProcessActions.START_EXPORT_PROCESS]({ commit }, importData: ImportProcessData): Promise<void> {

        // Setting status to working
        commit(ImportProcessMutations.SET_STATUS, ImportProcessStatus.WORKING);

        // Setting number of shipments that needs to be handled
        commit(ImportProcessMutations.SET_TOTAL_SHIPMENTS, importData.shipmentRawData.length);

        const customerMatchArr: Promise<AxiosResponse<Customer [] >> [] = [];
        const geoEncodeMatchArr: GeocodingOBJ  [] = [];

        // Avoid geocoding already seen addresses
        const seen: Record<string, GeocodingOBJ> = {};

        for (const item of importData.shipmentRawData) {
            const deliveryCompanyName = item['Dobava - ime'];
            const address = `${item["Dobava - naslov"]} ${item["Dobava - mesto"]}`;

            const cachedMatch = address in seen;
            let geocodingOBJ: GeocodingOBJ = {...emptyGeocodingOBJ};
            if (cachedMatch) {
                geocodingOBJ = seen[address];
            } else {
                // Fetch address
                // TODO change this nominatim is not used and NominatimGeoOBJ was changed to GeocodingOBJ
                // const res = await backgroundHttpClient.get<GeocodingOBJ[]>(`${process.env.VUE_APP_API_URL}/geocode/nominatim?q=${address}`);
                // geocodingOBJ = res.data.length > 0 ? res.data[0] : {...emptyGeocodingOBJ, display_name: address};
                // seen[address] = geocodingOBJ;
            }

            geoEncodeMatchArr.push(geocodingOBJ);

            // Try to match address by place_id with existing customer
            customerMatchArr.push(backgroundHttpClient.get<Customer []>(
                `${process.env.VUE_APP_API_URL}/customer?name=${deliveryCompanyName}&place_id=${geocodingOBJ.place_id}`)
            );
            await commit(ImportProcessMutations.INCREMENT_CURRENT_SHIPMENT);
        }
        const resolvedCustomers = await Promise.all(customerMatchArr);

        const shipmentCreateArr: Promise<AxiosResponse> [] = [];
        let unresolvedShipments = 0;

        for (const [index, item] of resolvedCustomers.entries()) {

            // Check if database match
            const match = item.data.length > 0 ? item.data[0] : undefined;
            if (match) {
                // Create shipment
                const shipment: NewShipmentRequestData = {
                    collectionPointId: importData.pickupPoint.id,
                    deliveryPointId: match.id,
                    payerId: importData.pickupPoint.id,
                    driverId: null,
                    footNote: '',
                    paymentTypeId: importData.paymentType.id,
                    deliveryTime: 0,
                    collectTime: importData.pickupTime,
                    packageNumber: importData.shipmentRawData[index]['Št. paketa brez presledkov']
                };

                const asyncFun: () => Promise<AxiosResponse> = async () => {
                    return await backgroundHttpClient.post(`${process.env.VUE_APP_API_URL}/shifts/${importData.activeShiftId}/shipment`, shipment);
                }

                shipmentCreateArr.push(asyncFun());

            } else {
                // Add shipment to list of unconfirmed ones

                const potentialCustomer:CustomerRegisterRequestData = {
                    address: geoEncodeMatchArr[index],
                    name: importData.shipmentRawData[index]["Dobava - ime"],
                    contactPhone: '',
                    taxNumber: ''
                };

                const unconfirmedShipment: AutoGeneratedShipment = {
                    pickupPoint: importData.pickupPoint,
                    deliveryPoint: potentialCustomer,
                    pickupTime: importData.pickupTime,
                    paymentType: importData.paymentType,
                    packageNumber: importData.shipmentRawData[index]['Št. paketa brez presledkov']
                };
                unresolvedShipments += 1;
                await commit(ImportProcessMutations.ADD_UNCONFIRMED_PACKAGE, unconfirmedShipment);
            }

        }

        await Promise.all(shipmentCreateArr);
        const endStatus = unresolvedShipments > 0 ? ImportProcessStatus.CONFIRMATION_WAIT : ImportProcessStatus.WAITING;
        commit(SnackbarMutations.SHOW_SNACK, {
            message: 'Uvozni posopek posiljk se je uspesno koncal',
            type: 'info'
        })
        commit(ImportProcessMutations.SET_STATUS, endStatus);
    },

    async [ImportProcessActions.CONFIRM_CUSTOMER]({commit, rootState, getters}, customer: AutoGeneratedShipment) {
        // Add customer to database via API
        const result = await backgroundHttpClient.post<Customer>(`${process.env.VUE_APP_API_URL}/customer`, customer.deliveryPoint);
        const activeShiftID = rootState.shift.activeShift.id;

        const packages: AutoGeneratedShipment [] = getters.getUnconfirmedPackages;
        const promiseArr: Promise<AxiosResponse> [] = [];

        packages.forEach((pack) => {

            // If customer belongs to package
            if(pack.deliveryPoint.name === customer.deliveryPoint.name && pack.deliveryPoint.address.place_id === customer.deliveryPoint.address.place_id) {
                const shipmentReqData: NewShipmentRequestData = {
                    deliveryPointId: result.data.id,
                    collectionPointId: pack.pickupPoint.id,
                    payerId: pack.pickupPoint.id,
                    footNote: '',
                    paymentTypeId: pack.paymentType.id,
                    deliveryTime: pack.pickupTime,
                    collectTime: pack.pickupTime,
                    driverId: null,
                    packageNumber: pack.packageNumber
                }
                const promise = backgroundHttpClient.post(`${process.env.VUE_APP_API_URL}/shifts/${activeShiftID}/shipment`, shipmentReqData);
                promiseArr.push(promise);
                commit(ImportProcessMutations.REMOVE_UNCONFIRMED_PACKAGE, pack);
            }
        })

        await Promise.all(promiseArr);
        commit(SnackbarMutations.SHOW_SNACK, {
            type: 'success',
            message: 'Stranka in posiljke uspesno dodani'
        });
        const unconfirmedPackages: AutoGeneratedShipment [] = getters.getUnconfirmedPackages
        if (unconfirmedPackages.length === 0) {
            commit(ImportProcessMutations.SET_STATUS, ImportProcessStatus.WAITING);
        }
    },

    async [ImportProcessActions.REMOVE_CUSTOMER]({commit, rootState, getters}, customer: AutoGeneratedShipment) {
        const packages: AutoGeneratedShipment [] = getters.getUnconfirmedPackages;

        packages.forEach((pack) => {
            if (pack.deliveryPoint.name === customer.deliveryPoint.name && pack.deliveryPoint.address.place_id === customer.deliveryPoint.address.place_id) {
                commit(ImportProcessMutations.REMOVE_UNCONFIRMED_PACKAGE, pack);
            }
        })
    }
}
