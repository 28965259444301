import {Customer, emptyCustomer} from "@/utils/httpReqResMapping";

export type VForm = Vue & {
    validate: () => boolean;
    resetValidation: () => boolean;
    reset: () => void;
};


export type VMenu = Vue & {
    save: (arg: any) => void
}

/**
 * Mapbox component cords emit
 */
export interface Events {
    message: MessageEvent
}

export interface MessageEvent {
    text: string
}

export interface ModalObject {
    message: string
    title: string
    positiveDecision: string
    negativeDecision: string
}

export interface EnteredDeliveryPoint {
    deliveryPoint: Customer,
    filterSync: string,
    locked: boolean,
    packageCount: number
}

export const emptyDeliveryPoint: EnteredDeliveryPoint =  {
    deliveryPoint: {...emptyCustomer},
    filterSync: '',
    locked: false,
    packageCount: 1
}
