
































































































































import Vue from 'vue';
import store from './store/index'
import Snackbar from "@/components/Snackbar.vue";
import Spinner from "@/components/Spinner.vue";
import {AuthActions} from "@/store/auth/actions";
import {ShiftActions} from "@/store/shift/actions";
import eventBus, {BusEvents} from "@/eventBus";
import RedyNavItem from "@/components/RedyNew/RedyNavItem.vue";
import RedyButton from "@/components/RedyNew/RedyButton.vue";
import NewShipmentModal from "@/components/NewShipmentModal.vue";
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";
import {OverviewActions} from "@/store/overview/actions";

export default Vue.extend({
  name: 'App',
  components: {NewShipmentModal, RedyButton, RedyNavItem, Spinner, Snackbar},
  data: () => ({
    mainMenu: false,
    hover: false,
    newShipmentModal: false,
    navBarItems: [
      {
        title: 'Home',
        icon: 'home',
        selected: true,
        routes: [
          {
            title: 'Overview',
            routeName: 'Overview',
            icon: 'overview'
          },
          {
            title: 'Tasks',
            routeName: 'Tasks',
            icon: 'tasks'
          },
          {
            title: 'Customers',
            routeName: 'Customers',
            icon: 'customers'
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'settings',
        selected: false,
        routes: [
          {
            title: 'Drivers',
            routeName: 'Drivers',
            icon: 'drivers'
          },
          /*{
            title: 'Analytics',
            routeName: 'Analytics',
            icon: 'analytics'
          },*/
          {
            title: 'Allocations',
            routeName: 'Allocations',
            icon: 'allocation'
          },
          /*{
            title: 'Configurations',
            routeName: 'Configuration',
            icon: 'configuration'
          },*/
          {
            title: 'Profile',
            routeName: 'Profile',
            icon: 'profile'
          }
        ]
      }
    ],
    selectedItem: {
      title: 'Overview',
      routeName: 'Overview',
      icon: 'overview'
    },
    appInit: false,
    taskHide: localStorage.getItem('ticket-panel') == "false"
  }),
  async mounted() {
    if (this.$store.getters.isLoggedIn || store.getters.validRefreshToken) {
      await this.$store.dispatch(ShiftActions.INIT_SHIFT);
      if (this.$route.name !== 'Overview') {
        this.$router.push({
          name: 'Overview'
        });
      }
    } else if (this.$route.name !== 'Login') {
      this.$router.push({
        name: 'Login'
      })
    }

    eventBus.$on(BusEvents.LOGOUT_EVENT, () => {
      this.$router.push({
        name: 'Login'
      });
    })
    this.appInit = true;
  },
  methods: {
    logOut() {
      this.$store.dispatch(AuthActions.LOGOUT);
    },
    userInfo() {
      if (Object.keys(this.$store.getters.getUsername).length === 0) {
        return "No user data";
      }
      return this.$store.getters.getUsername.name + " " + this.$store.getters.getUsername.lastName +
          " (" + this.$store.getters.getUsername.workerNumber + ")";
    },
    handleRoutSelect(selectedItem: any) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (this.selectedItem !== undefined && this.selectedItem.title === selectedItem.title) {
        return;
      }
      this.selectedItem = selectedItem;
      this.$router.push({name: selectedItem.routeName})
    },
    onTaskAdd() {
      this.newShipmentModal = true;
    },
    onUpdateNavigationDrawer(item: any) {
      console.log(item)
    },
    onTaskHide() {
      this.taskHide = !this.taskHide
      localStorage.setItem('ticket-panel', (!this.taskHide).toString())
      eventBus.$emit('taskHide', !this.taskHide);
    },
    handleShipmentDialogClose(shipment: AdHocPickupDTO | undefined) {
      console.log('adding new shipment', shipment)
      this.newShipmentModal = false
      const currentInfo: ShiftBasicInfoDTO | undefined = this.$store.getters.shiftBasicInfo

      if (shipment && currentInfo) {
        this.$store.dispatch(OverviewActions.ADD_TASK_TO_ASSIGNED, shipment)
        this.$store.dispatch(OverviewActions.UPDATE_BASIC_INFO, {
          ...currentInfo,
          assigned: currentInfo.assigned + 1
        } as ShiftBasicInfoDTO)
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isLoggedIn || this.$store.getters.validRefreshToken;
    }
  },
  created() {
    // this.$store.dispatch(ShiftActions.INIT_SHIFT);
  }
});
