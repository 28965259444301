import {Module} from "vuex";
import {RootState} from "@/store/types";
import {mutations} from "@/store/spinner/mutations";
import {actions} from "@/store/spinner/actions";
import {getters} from "@/store/spinner/getters";
import {SpinnerState} from "@/store/spinner/types";

const state: SpinnerState = {
    show: false,
    isModal: false
}

export const spinner: Module<SpinnerState, RootState> = {
    getters,
    state,
    mutations,
    actions
}
