import {GetterTree} from "vuex";
import {DriverState} from "@/store/driver/types";
import {RootState} from "@/store/types";
import {DriverUI} from "@/utils/httpReqResMapping";

export const getters: GetterTree<DriverState, RootState> = {
    /**
     * Get the driver that is currently being edited
     */
    getEditingDriver(state): DriverUI {
        return state.editingDriver
    }
}
