

















import {Component, Prop, Vue} from "vue-property-decorator";
@Component
export default class Progress extends Vue {

  @Prop(Number)
  value!: number;

  @Prop(String)
  message!: string;

}
