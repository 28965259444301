var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"table-redy-white",attrs:{"headers":_vm.headers,"items":_vm.adHocPickups,"items-per-page":-1,"hide-default-footer":true,"fixed-header":"","height":"100%"},scopedSlots:_vm._u([{key:"item.pickup",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[(_vm.isCountry(item) && false)?_c('v-expansion-panels',{staticClass:"py-6"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Seznam strank ")]),_c('v-expansion-panel-content',_vm._l((item.shipments),function(delivery){return _c('p',{key:delivery.id},[_c('ShipmentPointTableEntry',{attrs:{"point":delivery.collectionPoint,"payer":delivery.payer && delivery.collectionPoint && delivery.collectionPoint.id === delivery.payer.id,"collect":false}})],1)}),0)],1)],1):_vm._e(),(item.shipments.length > 0)?_c('ShipmentPointTableEntry',{attrs:{"point":item.shipments[0].collectionPoint,"payer":item.shipments.length > 0 &&
          item.shipments[0].payer && item.shipments[0].collectionPoint
          && item.shipments[0].collectionPoint.id === item.shipments[0].payer.id,"collect":true}}):_c('p',[_vm._v(" Ni na voljo ")])],1)]}},{key:"item.delivery",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[(!_vm.isCountry(item))?_c('v-expansion-panels',{staticClass:"py-6"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Seznam strank ")]),_c('v-expansion-panel-content',_vm._l((item.shipments),function(delivery){return _c('p',{key:delivery.id},[_c('ShipmentPointTableEntry',{attrs:{"point":delivery.deliveryPoint,"payer":delivery.payer && delivery.deliveryPoint && delivery.deliveryPoint.id === delivery.payer.id,"collect":false}})],1)}),0)],1)],1):(item.shipments.length > 0)?_c('ShipmentPointTableEntry',{attrs:{"point":item.shipments[0].deliveryPoint,"payer":item.shipments.length > 0 &&
          item.shipments[0].payer && item.shipments[0].deliveryPoint
          && item.shipments[0].deliveryPoint.id === item.shipments[0].payer.id,"collect":false}}):_vm._e()],1)]}},{key:"item.issuer",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(item.issuer.workerName)+" "+_vm._s(item.issuer.workerLastName)+" ")]),_c('p',{staticClass:"ma-0 pa-0"},[_c('b',[_vm._v(_vm._s(item.issuer.workerNumber))])])]}},{key:"item.proposedDriver",fn:function(ref){
          var item = ref.item;
return [(!_vm.isFinished(item.state))?_c('v-autocomplete',{staticStyle:{"min-width":"8rem","max-width":"8rem","max-height":"5rem"},attrs:{"solo":"","hide-details":"","disabled":true,"items":_vm.drivers,"label":item.proposedDriver ? item.proposedDriver.workerLastName : 'Posiljka nima dodoeljenega voznika'},on:{"change":function($event){return _vm.onDriverChange(item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerName)+" "+_vm._s(item.workerLastName)+" ")]}},{key:"selection",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerLastName)+" ")]}}],null,true),model:{value:(item.proposedDriver),callback:function ($$v) {_vm.$set(item, "proposedDriver", $$v)},expression:"item.proposedDriver"}}):[_c('p',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(item.proposedDriver.workerName)+" "+_vm._s(item.proposedDriver.workerLastName)+" ")]),_c('p',{staticClass:"pa-0 ma-0"},[_c('b',[_vm._v(_vm._s(item.proposedDriver.workerNumber))])])]]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"pa-0 ma-0"},[_c('b',{style:({color: _vm.getStatusString(item.state).color})},[_vm._v(" "+_vm._s(_vm.getStatusString(item.state).text)+" ")])])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"pa-0 ma-0"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.isFinished(item.state)},on:{"click":function($event){return _vm.deleteAdHoc(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }