





































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class TasksTable extends Vue {

  page = 1;
  pageCount = 0;

  @Watch('page')
  emitPageNum() {
    this.$emit('pageNum', this.page - 1);
  }

  @Prop() loading: boolean | undefined;
  get isLoading (): boolean {
    if (this.loading !== undefined) {
      return this.loading
    } else {
      return false
    }
  }

  @Prop() itemsPerPage: number;
  @Prop(Array) readonly tasks: [];
  headers = [
    {
      text: 'Customer',
      align: 'start',
      value: 'customer',
    },
    {
      text: 'Tel. number',
      value: 'phoneNumber'
    },
    {
      text: 'Courier',
      value: 'courier'
    },
    {
      text: 'Create time',
      value: 'createTime'
    },
    {
      text: 'Due time',
      value: 'dueTime'
    },
    {
      text: 'Tasks',
      value: 'tasks'
    }
  ]

  editTask(task: any) {
    return
  }

}
