













































































































































import {Component, Vue, Prop, Watch} from "vue-property-decorator"
import {DriverUI, DriverType, DriverEditRequestData} from "@/utils/httpReqResMapping";
import {nonEmptyRule, passwordRule} from "@/utils/rules";
import {VForm} from "@/views/types";
import {SnackbarActions} from "@/store/snackbar/actions";
@Component
export default class EditDriverModal extends Vue {
    @Prop() readonly driver!: DriverUI
    @Prop() readonly modal!: boolean

    // Driver types fetched from API
    driverTypes: DriverType [] = [];

    // Driver editing object
    editDriverObject: DriverEditRequestData = {
        workerName: '',
        workerLastName: '',
        workerNumber: '',
        workerPassword: '',
        workerPasswordRepeat: '',
        driverTypeId: '',
        phoneNumber: '',
        driverId: ''
    }

    // Display form inputs for editing password
    showEditPassword = false

    // Save the currently edited driver
    driverUI: DriverUI

    rules = {
        numberRules :[
        nonEmptyRule("Sifra zaposlenega ne sme biti prazna"),
        ],
        firstNameRule: [
            nonEmptyRule("Ime zaposlenega ne sme biti prazno")
        ],
        lastNameRule: [
            nonEmptyRule("Priimek zaposlenega ne sme biti prazen")
        ],
        phoneNumberRule: [
            nonEmptyRule("Telefonska stevilka zaposlenega ne sme biti prazna")
        ],
        driverTypeRule: [
            nonEmptyRule("Izbrati je potrebno tip voznika")
        ]
    }

    passwordRules = {
        passwordRule: [
            passwordRule(),
            nonEmptyRule("Geslo ne sme biti prazno")
        ],
        repeatPasswordRule: [
            passwordRule(),
            nonEmptyRule("Geslo ne sme biti prazno"),
            (v: string): boolean | string => v == this.editDriverObject.workerPassword || `Gesli se ne ujemata`
        ],
    }

    get editingDriver(): DriverUI {
        return this.$store.getters.getEditingDriver
    }

    @Watch("editingDriver", { deep: true })
    handleEditingDriver(driver: DriverUI): void {
        this.editDriverObject.driverTypeId = driver.driverType.id
        this.editDriverObject.phoneNumber = driver.phoneNumber
        this.editDriverObject.workerLastName = driver.workerLastName
        this.editDriverObject.workerName = driver.workerName
        this.editDriverObject.workerNumber = driver.workerNumber
        this.editDriverObject.driverId = driver.id
        this.showEditPassword = false
        this.driverUI = JSON.parse(JSON.stringify(driver));
    }

    checkIfPasswordsBlank(): boolean {
        return this.editDriverObject.workerPassword === '' && this.editDriverObject.workerPasswordRepeat === ''
    }

    closeModal(): void {
        this.$emit('close-modal');
        this.showEditPassword = false;
    }

    // Reference to form
    get form(): VForm {
        return this.$refs.editDriverForm as VForm;
    }
    get passwordForm(): VForm {
        return this.$refs.editDriverPasswordForm as VForm;
    }

    async mounted(): Promise<void> {
        this.driverTypes = await this.fetchDriverTypes();
    }

    // Fetch driver types
    async fetchDriverTypes(): Promise<DriverType []> {
        const res = await this.$http.get<DriverType []>(`${process.env.VUE_APP_API_URL}/driver/type`);
        return res.data;
    }

    emitUpdatedDriver(): void {
        const driver = this.driverUI
        const driverType = this.driverTypes.find(el => el.id === this.editDriverObject.driverTypeId)
        if (driverType === undefined) {
            return;
        }
        driver.workerName = this.editDriverObject.workerName
        driver.workerLastName = this.editDriverObject.workerLastName
        driver.workerNumber = this.editDriverObject.workerNumber
        driver.driverType = driverType
        driver.phoneNumber = this.editDriverObject.phoneNumber
        this.$parent.$emit("updateDriver", driver)
    }

    async editDriver(): Promise<void> {
        const validForm = this.form.validate();
        let validPasswordForm = true

        // Validate password inputs only if user wants to change it i.e. showEditPassword=true
        if (this.showEditPassword) {
            validPasswordForm = this.passwordForm.validate();
        } else {
            this.editDriverObject.workerPassword = ''
            this.editDriverObject.workerPasswordRepeat = ''
        }
        // Notify client of invalid form
        if (!validForm || !validPasswordForm) {
            await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                type: 'info',
                message: 'Obrazec za registracijo voznika je potrebno izpolniti pravilno'
            });
            return;
        }

        // Edit driver
        const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/driver`, this.editDriverObject);

        if (response.status === 200) {
            this.emitUpdatedDriver()
            this.$emit('close-modal');
        }

        await this.$store.dispatch(SnackbarActions.FIRE_SNACK,  {
            type: 'success',
            message: `Uspesno ste uredili voznika ${this.editDriverObject.workerName } ${this.editDriverObject.workerLastName }`
        });

        this.form.reset();

    }
}
