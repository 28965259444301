

























































import {Component, Vue, Watch} from "vue-property-decorator";
import DateHeader from "@/components/DateHeader.vue";
import TasksTable from "@/components/TasksTable.vue";
import EmptyAlert from "@/components/EmptyAlert.vue"
import {AdHocPickupDTO, GeneralInfoResponse} from "@/utils/httpReqResMapping";

export interface TaskTableItem {
  customer: string,
  phoneNumber: string,
  courier: string,
  createTime: Date,
  dueTime: Date,
  tasks: number
}

@Component({
  components: {TasksTable, DateHeader, EmptyAlert}
})
export default class Tasks extends Vue {

  fetch = false;
  itemsPerPage = [10, 50, -1];
  itemsPerPageText = ["Show 10", "Show 50", "Show all"];
  selected = "Show 10";
  selectedItem = 10
  clicked = false;
  page = 0;

  currentTabItem = 'active'

  activeTasks: AdHocPickupDTO [] = [];
  activeTasksLoading = true;
  get activeTasksTableData(): TaskTableItem [] {
    return this.activeTasks.map((it) => {
      return {
        customer: it.shipments[0].collectionPoint?.name,
        phoneNumber: it.shipments[0].collectionPoint?.contactPhone,
        courier: `${it.shipments[0].driver?.workerName} ${it.shipments[0].driver?.workerLastName}`,
        createTime: new Date(it.createdAt * 1000),
        dueTime: new Date(it.shipments[0].deliverTime * 1000),
        tasks: it.shipments.reduce((a, b) => a + parseInt(b.packageNumber), 0)
      } as TaskTableItem
    })
  }

  historyTasks: AdHocPickupDTO [] = [];
  historyTasksLoading = false;
  get historyTaskTableData(): TaskTableItem [] {
    return this.historyTasks.map((it) => {
      return {
        customer: it.shipments[0].collectionPoint?.name,
        phoneNumber: it.shipments[0].collectionPoint?.contactPhone,
        courier: `${it.shipments[0].driver?.workerName} ${it.shipments[0].driver?.workerLastName}`,
        createTime: new Date(it.createdAt * 1000),
        dueTime: new Date(it.shipments[0].deliverTime * 1000),
        tasks: it.shipments.reduce((a, b) => a + b.packageCount, 0)
      } as TaskTableItem
    })
  }

  generalInfo: GeneralInfoResponse | string = '';

  @Watch('currentTabItem')
  onTabItemChange(): void {
    if (this.currentTabItem === 'active' && !this.activeTasksLoading) {
      this.loadInActiveTasks();
    } else if (this.currentTabItem === 'history' && !this.historyTasksLoading) {
      this.loadInHistoryTasks();
    }
  }


  setPageNum(pageNum: number) {
    this.page = pageNum;
  }

  handleClick() {
    this.clicked = true;
  }

  setNumberOfItems(index: number) {
    this.selected = this.itemsPerPageText[index];
    this.selectedItem = this.itemsPerPage[index];
    this.page = 0;
  }

  async created(): Promise<void> {
    await this.loadInActiveTasks()
    await this.loadInGeneralInfo()
  }

  async loadInActiveTasks(): Promise<void> {
    try {
      this.activeTasksLoading = true
      const activeTasks = await this.$http.get<AdHocPickupDTO []>(`${process.env.VUE_APP_API_URL}/tasks/active`)
      this.activeTasks = activeTasks.data
    } finally {
      this.activeTasksLoading = false
    }
  }

  async loadInHistoryTasks(): Promise<void> {
    try {
      this.historyTasksLoading = true
      const historyTasks = await this.$http.get<AdHocPickupDTO []>(`${process.env.VUE_APP_API_URL}/tasks/history`)
      this.historyTasks = historyTasks.data
    } finally {
      this.historyTasksLoading = false
    }
  }

  async loadInGeneralInfo(): Promise<void> {
    try {
      const generalInfo = await this.$http.get<GeneralInfoResponse>(`${process.env.VUE_APP_API_URL}/tasks/basicInfo`)
      this.generalInfo = generalInfo.data
    } catch (e) {
      console.log(e)
    }
  }


  /* async created(): Promise<void> {

  }

  async loadPage(): Promise<void> {

  }

  endIntersect(e: unknown): void {
      const fetch = readyForLoad(e);
      if (fetch) {
      this.loadPage();
      }
  } */
}
