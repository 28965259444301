




import 'mapbox-gl/dist/mapbox-gl.css';
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {LMap, LTileLayer, LMarker} from "vue2-leaflet";
import mapboxgl, {LngLat, MapTouchEvent} from "mapbox-gl";
@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker
  }
})
export default class Map extends Vue {

  @Emit("cords")
  handleClick(c: LngLat): LngLat {
    return c;
  }

  @Prop(Object)
  center!: LngLat;

  map!: mapboxgl.Map
  marker = new mapboxgl.Marker();

  mounted():void {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_KEY;
    this.map = new mapboxgl.Map({
      container: document.getElementById('gpsMap') || 'gpsMap',
      style: "mapbox://styles/mapbox/light-v10",
      center: this.center,
      zoom: 12,
    });

    this.marker
        .setLngLat(this.center)
        .addTo(this.map);

    this.map.on("click", (e: MapTouchEvent) => {
      // Emit cords and center to parent
      this.handleClick(e.lngLat);
      this.marker.setLngLat(e.lngLat).addTo(this.map);
      this.$emit('center',  e.lngLat);
    });

    this.map.on('load', () => {
      this.map.resize();
    });
  }

  @Watch("center")
  handleCenterChange (newCenter: LngLat): void {
    this.marker.setLngLat(newCenter).addTo(this.map);
    this.map.setCenter(newCenter);
  }

  get myCenter (): LngLat {
    return this.center;
  }

  set myCenter (v: LngLat) {
    this.center = v;
  }
}
