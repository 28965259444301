













































import {Component, Prop, Vue} from "vue-property-decorator";
import TaskItem from "@/components/RedyNew/TaskItem.vue";
import RedyTaskTicket from "@/components/RedyNew/RedyTaskTicket.vue";
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";
import {OverviewActions} from "@/store/overview/actions";
@Component({
  components: {RedyTaskTicket, TaskItem}
})
export default class RedyTasks extends Vue {

  @Prop()
  tasks: AdHocPickupDTO [];

  @Prop()
  taskInfo: ShiftBasicInfoDTO

  @Prop()
  selectedItem: string

  get orderedTasks(): AdHocPickupDTO[] {
    return this.tasks.sort((a, b) => {
      return b.createdAt - a.createdAt
    })
  }

  async created(): Promise<void> {
    await this.onItemSelect('assigned')
  }

  async onItemSelect(item: string): Promise<void> {
    await this.$store.dispatch(OverviewActions.SET_TYPE, item);
    await this.$store.dispatch(OverviewActions.FETCH_TASKS);
  }

  onRedyTaskTicketSelect(item: AdHocPickupDTO): void {
    this.$store.dispatch(OverviewActions.SET_SELECTED_TICKET, item)
  }

  get selectedTask(): AdHocPickupDTO | undefined {
    return this.$store.getters['currentlySelectedTicket'];
  }

}
