<template>
  <div class="main">
    <span class="dateText">
      {{date}}
    </span>
  </div>
</template>

<script>
export default {
  name: "RedyTaskPanelDate",
  props: ['date']
}
</script>

<style scoped>
.main {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 17px;
}
.dateText {
  font: normal normal normal 16px/18px Arial;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
</style>