var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.barcodeModal && _vm.barcodeSelection != null)?_c('BarCodeModal',{attrs:{"shipment":_vm.barcodeSelection},on:{"onClose":_vm.onBarcodeModalClose}}):_vm._e(),_c('edit-shipment-modal',_vm._g({attrs:{"modal":_vm.modal},on:{"close-modal":function($event){_vm.modal = false}}},_vm.$listeners)),_c('v-data-table',{staticClass:"table-shipment",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.shipment,"search":_vm.search,"custom-filter":_vm.filterOnCustomer,"hide-default-footer":true,"fixed-header":"","height":"100%","item-class":_vm.getTableClass},scopedSlots:_vm._u([{key:"header.deliverTime",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-time-three-outline")])]},proxy:true},{key:"header.driver",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-outline")])]},proxy:true},{key:"header.paymentType",fn:function(){return [_c('v-icon',[_vm._v("mdi-currency-eur")])]},proxy:true},{key:"item.collectionPoint",fn:function(ref){
var item = ref.item;
return [_c('ShipmentPointTableEntry',{attrs:{"point":item.collectionPoint,"payer":item.payer && item.collectionPoint && item.collectionPoint.id === item.payer.id,"collect":true}})]}},{key:"item.deliveryPoint",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[_c('ShipmentPointTableEntry',{attrs:{"point":item.deliveryPoint,"payer":item.payer && item.deliveryPoint && item.deliveryPoint.id === item.payer.id,"collect":false}})],1)]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[_vm._v(" "+_vm._s(item.deliveryPoint.contactPhone)+" "),_c('br'),_vm._v(" "+_vm._s(item.deliveryPoint.taxNumber)+" ")])]}},{key:"item.deliverTime",fn:function(ref){
var item = ref.item;
return [(item.state === undefined || item.state.name !== 'DELIVERED')?_c('div',[_vm._v(" "+_vm._s(_vm.toTimeStamp(item.deliverTime))+" "),_c('p',{staticClass:"timeWindowed",class:!_vm.isBeingLate(item.deliverTime) ? 'early' : ''},[_vm._v(" "+_vm._s(_vm.timeWindowedTimeStamp(item.deliverTime))+" ")])]):_c('div',[_vm._v(" Posiljka dostavljena ")])]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1",staticStyle:{"max-height":"4rem"}},[(_vm.drivers)?_c('v-autocomplete',{staticStyle:{"min-width":"12rem","max-height":"5rem"},attrs:{"disabled":item.state !== undefined && item.state.name === 'DELIVERED' || true,"solo":"","dense":"","items":_vm.drivers,"label":item.driver ? item.driver.workerLastName : 'Posiljka nima dodoeljenega voznika',"clear-icon":"mdi-delete","clearable":""},on:{"change":function($event){return _vm.onPackageChange(item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerName)+" "+_vm._s(item.workerLastName)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerLastName)+" ")]}}],null,true),model:{value:(item.driver),callback:function ($$v) {_vm.$set(item, "driver", $$v)},expression:"item.driver"}}):_vm._e()],1)]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [(item.paymentType)?[_c('div',{staticClass:"table_item"},[_vm._v(" "+_vm._s(item.paymentType.type)+" ")])]:[_vm._v(" Nacin placila ni znan ")]]}},{key:"item.packageNumber",fn:function(ref){
var item = ref.item;
return [(item.packageNumber)?_c('div',{staticClass:"table_item centered"},[_vm._v(" "+_vm._s(item.packageNumber)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.showBarcodeModal(item); }}},[_c('v-icon',[_vm._v(" mdi-barcode ")])],1)],1):_c('div',{staticClass:"table_item centered"},[_vm._v(" Paket nima stevilke ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [((item.state === undefined || item.state.name !== 'DELIVERED') && false)?_c('div',{staticClass:"iconContainer"},[(false)?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.editPackage(item)}}},[_vm._v(" mdi-pencil-outline ")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.deletePackage(item)}}},[_vm._v(" mdi-delete ")])],1)],1):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-lock ")])]}}])}),(_vm.showSubmitBtn)?_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"2"}},[_vm._v(" Dodaj pošiljko ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }