import {ShipmentState} from "@/store/shipment/types";
import {RootState} from "@/store/types";
import {Module} from "vuex";
import {getters} from "@/store/shipment/getters";
import {mutations} from "@/store/shipment/mutations";
import {emptyShipment} from "@/utils/httpReqResMapping";

const state: ShipmentState = {
    editingShipment: emptyShipment
}

export const shipment: Module<ShipmentState, RootState> = {
    state,
    getters,
    mutations
}
