
































import {Component, Vue, Prop} from "vue-property-decorator"
import { emptyGeocodingOBJ, CustomerRegisterRequestData} from "@/utils/httpReqResMapping";
import NewShipment from "@/views/NewShipment.vue";
@Component({
  components: {NewShipment}
})
export default class EditShipmentModal extends Vue {
    @Prop() readonly modal: boolean

    editedCustomer: CustomerRegisterRequestData = {
        address: {...emptyGeocodingOBJ},
        name: '',
        contactPhone: '',
        taxNumber: ''
    }
}
