





































import { Component, Mixins } from 'vue-mixin-decorator';
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {Entity} from "@/utils/rules";
import {Prop, VModel, Watch} from "vue-property-decorator";

@Component
export default class AutoSelect extends Mixins<PaginationMixin <Entity> >(PaginationMixin) {

  @Prop(String)
  readonly base!: string;

  @Prop(Array)
  readonly selectionItems: string [] | undefined;

  @Prop(Array)
  readonly displayItems: string [] | undefined;

  @VModel()
  readonly chosen!: Entity;

  @Prop(String)
  readonly filterName!: string;

  @Prop(String)
  readonly label!: string;

  filterValue = ''

  async mounted (): Promise<void> {
    this.items = await this.getNextPage(this.base || '', true);
  }

  // Watching for filter to change
  @Watch('filterValue')
  async handleFilterChange(filter: string): Promise<void> {
    // Reset pagination info to default and load first page
    this.items = [];
    this.resetPaginationInfo();

    // Add query params
    const queryParams: any = {};
    queryParams[this.filterName] = filter;

    this.items = await this.getNextPage(this.base, true, queryParams);
  }

  /**
   * User reached bottom of scrollable
   * load next page
   */
  async endIntersect (): Promise<void> {

    // Add query params
    const queryParams: any = {};
    queryParams[this.filterName] = this.filterValue;

    this.items = this.items.concat(...await this.getNextPage(this.base, false, queryParams));
  }

  /**
   * Helper function to extract deep nester values from json
   */
  getProperty(json: any, path: string): any {
    const tokens = path.split(".");
    let obj = json;
    for (let i = 0; i < tokens.length; i++) {
      obj = obj[tokens[i]];
    }
    return obj;
  }

}
