

































import {Component} from "vue-property-decorator";
import DriverTable from "@/components/DriverTable.vue";
import DateHeader from "@/components/DateHeader.vue";
import {DeliveryDTO} from "@/utils/httpReqResMapping";
import {Mixins} from "vue-mixin-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import EmptyAlert from "@/components/EmptyAlert.vue";
import {readyForLoad} from "@/utils/pagination";
import DeliveryTable from "@/components/DeliveryTable.vue";
import DatePicker from "@/components/DatePicker.vue";
import {getDateString, getUnixTimestamp} from "@/utils/date";
import props from "vuetify/src/components/VCalendar/util/props";

@Component({
  components: {
    DatePicker,
    DeliveryTable,
    DriverTable, DateHeader, EmptyAlert
  }
})
export default class Deliveries extends Mixins <PaginationMixin <DeliveryDTO> > (PaginationMixin) {

  deliveries: DeliveryDTO [] = [];
  initFetch = true;
  date: number = Math.floor(Date.now() / 1000);

  async mounted(): Promise<void> {
    this.deliveries = await this.getNextPage('/delivery', true, {
      date: this.date
    });
    this.initFetch = false;
  }

  endIntersect(e: unknown): void {
    const fetch = readyForLoad(e);
    if (fetch) {
      this.loadPage();
    }
  }

  async loadPage(): Promise<void> {
    const data = await this.getNextPage('/delivery', false, {
      date: this.date
    });
    this.deliveries.push(...data);
  }

  async loadPageFilterChanged(): Promise<void> {
    await this.resetPaginationInfo();
    const data = await this.getNextPage('/delivery', true, {
      date: this.date
    });
    console.log(data);
    this.deliveries = data;
  }

  get customerContainer(): HTMLDivElement | undefined {
    return this.$refs.tabContainer as HTMLDivElement;
  }

  onDate(date: Date): void {
    if (!isNaN(date.getTime())) {
      this.date = getUnixTimestamp(date);
      this.loadPageFilterChanged();
    }
  }

  toDate(seconds: number): string {
    return getDateString(seconds);
  }

  previewItem(item: DeliveryDTO): void {
    this.$router.push({
      name: 'Delivery',
      params: {
        deliveryId: item.id
      },
    })
  }

  get isOverflowing(): boolean {
    const element = this.customerContainer;
    if (element  === undefined) {
      return false
    } else {
      return (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
    }
  }
}
