




















































import {Vue, Component} from "vue-property-decorator";
import {AutoGeneratedShipment} from "@/utils/httpReqResMapping";
import CustomerAdder from "@/components/CustomerAdder.vue";
import Map from "./Map.vue";
import {LngLat} from "mapbox-gl";

@Component({
  components: {Map, CustomerAdder}
})
export default class CustomerPreviewModal extends Vue {

  dialog = false;

  customer!: AutoGeneratedShipment;

  center!: LngLat;

  showModal(customer: AutoGeneratedShipment): void{
    this.customer = customer;
    this.center = new LngLat(Number(customer.deliveryPoint.address.lon), Number(customer.deliveryPoint.address.lat));
    this.dialog = true;
  }
}
