




















import {Component, Prop, Vue} from "vue-property-decorator";
import {Customer} from "@/utils/httpReqResMapping";
import PayerIcon from "@/components/PayerIcon.vue";

@Component({
  components: {
    PayerIcon
  }
})
export default class ShipmentPointTableEntry extends Vue {

  @Prop(Object) readonly point: Customer | null
  @Prop(Boolean) readonly payer: boolean
  @Prop(Boolean) readonly collect: boolean


}
