





























import {Component, Prop, Vue} from "vue-property-decorator";
import DatePicker from "@/components/DatePicker.vue";
import DateHeader from "@/components/DateHeader.vue";

@Component({
  components: {DateHeader, DatePicker}
})
export default class ViewWrapper extends Vue {
  @Prop()
  title!: string


}
