import {GetterTree} from "vuex";
import {CustomerState} from "@/store/customer/types";
import {RootState} from "@/store/types";
import {CustomerRegisterRequestData} from "@/utils/httpReqResMapping";

export const getters: GetterTree<CustomerState, RootState> = {
    /**
     * Get the customer that is currently being edited
     */
    getEditingCustomer(state): CustomerRegisterRequestData {
        return state.editingCustomer
    },
    /**
     * Get the customer ID that is currently being edited
     */
    getEditingCustomerId(state): string {
        return state.customerId
    }
}
