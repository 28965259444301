






import {Component, Prop, Vue} from "vue-property-decorator";
@Component
export default class EmptyAlert extends Vue {

  @Prop(String)
  type!: string

}
