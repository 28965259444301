























































































































































































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {
  CustomerRegisterRequestData,
  Location,
  emptyLocation,
  emptyGeocodingOBJ,
  GoogleAutoCompletePrediction,
  GoogleGeocodingResult,
  emptyAddress,
} from "@/utils/httpReqResMapping";
import {VForm} from "@/views/types";
import {LngLat} from "mapbox-gl";
import {nonEmptyRule, taxNumberRule} from "@/utils/rules";
import {SnackbarActions} from "@/store/snackbar/actions";
import Map from "@/components/Map.vue";
import {CustomerAdderType} from "@/components/types";
import {convertGoogleAddressOBJtoGeocodingOBJ} from "@/utils/geocoding";

@Component({
  components: {Map}
})
export default class CustomerAdder extends Vue {
    @Prop() readonly isEditing: boolean;
    @Prop(Object) customer!: CustomerRegisterRequestData;
    @Prop(Object) customerLocation!: Location;

    customerData: CustomerRegisterRequestData = this.customer;
    googleAutoCompletePredictionModel: GoogleAutoCompletePrediction = { description: '', placeId: '' };

    @Prop(Object)
    type!: CustomerAdderType;

    // Reference to form
    get form(): VForm {
      return this.$refs.form as VForm;
    }

    // Geo objects from google
    locations: GoogleAutoCompletePrediction[] = [];

    // Filters
    customerAddressSearch = '';

    // Customer center
    center: LngLat = new LngLat(Number(this.customerData.address.lon), Number(this.customerData.address.lat));

    // Last change
    timer: number | undefined = undefined;

    @Watch('customerAddressSearch')
    async handleChangeCustomerAddress(address:string): Promise<void> {
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (address) {
          await this.geocode(address);
        }
      }, 1000)
    }

    @Watch('customerLocation', {immediate: true, deep: true})
    handleAddressChange(): void {
      if (this.customerLocation) {
        this.center = new LngLat(Number(this.customerLocation.longitude), Number(this.customerLocation.latitude));
      }
    }

    @Watch('customer', {immediate: true, deep: true})
    handleCustomer(customer: CustomerRegisterRequestData): void {
      this.customerData = customer;
      this.center = new LngLat(Number(this.customerData.address.lon), Number(this.customerData.address.lat));
    }

    async handleSelectedAddress(): Promise<void> {
      this.customerData.address = {...emptyGeocodingOBJ, address: {...emptyAddress}}

      const placeId = this.googleAutoCompletePredictionModel.placeId
      const googleGeocodingResult: GoogleGeocodingResult = (await this.$http.get<GoogleGeocodingResult []>(`${process.env.VUE_APP_API_URL}/geocode/google/${placeId}`)).data[0];
      this.customerData.address = convertGoogleAddressOBJtoGeocodingOBJ(googleGeocodingResult)

      this.center = new LngLat(Number(this.customerData.address.lon), Number(this.customerData.address.lat));
    }

    @Watch('isEditing', {immediate: true})
    handleEdit(): void {
      // When not editing: recenter the map and reset validation
      if (!this.isEditing) {
        this.center = new LngLat(Number(emptyLocation.longitude), Number(emptyLocation.latitude));
        Vue.nextTick(() => {
          this.form.resetValidation()
        })
      }
    }

    handleCords(l: LngLat): void {
      this.customerData.address.lat = l.lat.toString();
      this.customerData.address.lon = l.lng.toString();
    }

    handleCenter(center: LngLat): void {
      this.center = center;
    }

    async registerCustomer(): Promise<void> {
      const validForm = this.form.validate();
      // Notify client
      if (!validForm) {
        await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
          type: 'info',
          message: 'Obrazec za registracijo stranke je potrebno izpolniti pravilno'
        });
        return;
      }
      this.emitCustomer();
      this.customerData = {
        address: {...emptyGeocodingOBJ, address: {...emptyAddress}},
        name: '',
        contactPhone: '',
        taxNumber: ''
      }
      this.googleAutoCompletePredictionModel = { description: '', placeId: '' };
      this.center = new LngLat(Number(this.customerData.address.lon), Number(this.customerData.address.lat));
      this.form.resetValidation();
    }

    async geocode (address: string): Promise<void> {
      const question = encodeURI(address);
      const res = await this.$http.get<GoogleAutoCompletePrediction []>(`${process.env.VUE_APP_API_URL}/geocode/google/places?q=${question}`);
      this.locations = res.data;
    }

    closeModal(): void {
      this.$emit('close-modal');
    }

    // Emitted when customer is mutated / added
    @Emit('onCustomer')
    emitCustomer(): CustomerRegisterRequestData {
      return this.customerData;
    }

    rules = {
      addressRules: [nonEmptyRule("Naslov ne sme biti prazen")],
      nameRule: [nonEmptyRule("Ime ne sme biti prazno")],
      phoneNumberRule: [nonEmptyRule("Telefonska stevilka ne sme biti prazna")],
      taxNumberRule: [taxNumberRule("Davcna stevilka mora biti veljavna")]
    }
}
