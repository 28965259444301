<template>
  <v-card
      class="mx-auto"
      color="#FFD541"
  >
    <v-card-title style="color: white">
      <v-icon
          large
          left
          color="white"
      >
        {{icon}}
      </v-icon>
      <p class="cardTitle">
        {{title}}
      </p>
    </v-card-title>
    <v-card-text>
      <p class="cardText">
        {{main}}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AnalyticsCard",
  props: ['icon', 'title', 'main']
}
</script>

<style scoped>
.cardText {
  color: white;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.cardTitle {
  color: white;
  font-weight: 300;
  word-break:break-word;
}
</style>
