import {ActionTree} from "vuex";
import {ShiftState} from "@/store/shift/types";
import {RootState} from "@/store/types";
import httpClient from "@/utils/httpClient";
import {ShiftMutations} from "@/store/shift/mutations";

export enum ShiftActions {
    INIT_SHIFT="INIT_SHIFT"
}

export const actions: ActionTree<ShiftState, RootState> = {

    /**
     * This action is dispatched in created lifecycle hook when user is logged in
     * it obtains currently active shift
     */
    async [ShiftActions.INIT_SHIFT]({ commit }): Promise<void> {
        const res = await httpClient.get<ShiftState []>(`${process.env.VUE_APP_API_URL}/shifts?type=ACTIVE`);
        commit(ShiftMutations.SET_SHIFT, res.data[0]);
    }
}
