var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DecisionModal'),_c('RegisterVehicleModal'),_c('v-data-table',{staticClass:"table-shipment",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.vehicle,"hide-default-footer":true,"fixed-header":"","height":"100%"},scopedSlots:_vm._u([{key:"item.internNumber",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.internNumber))])]}},{key:"item.registrationNumber",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.registrationNumber))])]}},{key:"item.weight",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.weight)+" kg")])]}},{key:"item.capacity",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.capacity)+" kg")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('b',[_vm._v(_vm._s(item.type))]),_c('div',{staticStyle:{"margin-left":"5%"}},[(_vm.isCity(item.type))?_c('v-img',{attrs:{"src":require("@/assets/zm_connected.svg"),"max-width":"20","max-height":"20"}}):_c('v-img',{attrs:{"src":require("@/assets/car.svg"),"max-width":"20","max-height":"20"}})],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.occupied)?[_c('v-icon',{staticClass:"ma-1",attrs:{"color":"error"}},[_vm._v(" mdi-lock ")])]:[_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]]}}])}),(_vm.showSubmitBtn)?_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"2"}},[_vm._v(" Dodaj vozilo ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }