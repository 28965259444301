/**
 * Auth related
 */
import {Entity} from "@/utils/rules";
import {Marker} from "mapbox-gl";
import {ActiveShift} from "@/store/shift/types";

export interface LoginResponseData {
    authToken: string,
    refreshToken: string
}
export interface LoginRequestData {
    workerNumber: string,
    password: string
}
export interface RegisterRequestData {
    workerNumber: string,
    workerPassword: string,
    workerPasswordRepeat: string
}

/**
 * Payment related
 */
export interface PaymentType extends Entity{
    id: string,
    type: string,
    description: string
}

/**
 * Driver related
 */
export interface DriverRegisterRequestData {
    workerNumber: string,
    workerName: string,
    workerLastName: string,
    workerPassword: string,
    workerPasswordRepeat: string,
    driverTypeId: string,
    phoneNumber: string
}

export interface DriverEditRequestData extends DriverRegisterRequestData {
    driverId: string
}

export interface DriverType extends Entity{
    id: string,
    type: string,
    description: string
}

export interface Driver extends Entity{
    id: string,
    workerNumber: string,
    workerName: string,
    workerLastName: string,
    role: string,
    phoneNumber: string,
    location: Location | undefined,
    driverType: DriverType
}

export interface DriverUI extends Driver {
    active: boolean
}


export interface UserDTO {
    id: string,
    workerNumber: string,
    workerName: string,
    phoneNumber: string,
    workerLastName: string,
    role: UserRoleDTO
}

export interface UserRoleDTO {
    id: string,
    name: string
}

/**
 * Customer related
 */
export interface CustomerRegisterRequestData {
    address: GeocodingOBJ,
    name: string,
    contactPhone: string,
    taxNumber: string
}

export interface CustomerEditRequestData extends CustomerRegisterRequestData {
    customerId: string
}

export interface Customer extends Entity{
    id: string,
    name: string,
    taxNumber: string,
    address: CustomerAddress,
    location: Location,
    contactPhone: string
}

export interface Point extends Customer {
    visited: boolean
}

export interface CustomerAutoSelectEntity extends Customer {
    dbPersisted: boolean,
    placeId?: string
}

export interface Location extends Entity{
    id: string,
    latitude: number,
    longitude: number,
    createdAt: number,
    updatedAt: number
}

export interface CustomerAddress {
    displayName: string,
    houseNumber: string,
    road: string,
    residential: string,
    suburb: string,
    town: string,
    postcode: string,
    country: string,
    countryCode: string,
    neighbourhood: string,
    municipality: string,
    placeId: string,
}

export const emptyCustomerAddress: CustomerAddress = {
    displayName:'',
    houseNumber:'',
    road:'',
    residential:'',
    suburb:'',
    town:'',
    postcode:'',
    country:'',
    countryCode:'',
    neighbourhood:'',
    municipality:'',
    placeId: '',
}

/**
 * Shipment related
 */

// Used to display shipment
// in process of registration
export interface ShipmentUI extends Entity{
    id: string,
    deliveryPoint?: Customer,
    collectionPoint?: Customer,
    payer?: Customer,
    driver?: Driver,
    paymentType?: PaymentType
    footNote: string,
    deliverTime: number,
    collectTime: number,
    packageNumber: string,
    state?: ShipmentStateDTO,
    packageCount: number
}

interface  ShipmentStateDTO {
    id: string,
    name: string,
    description: string
}

export const emptyLocation: Location = {
    id: '',
    latitude: 46.050785,
    longitude: 14.502852,
    createdAt: 0,
    updatedAt: 0
}

export const emptyCustomer: Customer = {
    id: '',
    name: '',
    taxNumber: '',
    address: {...emptyCustomerAddress},
    location: {...emptyLocation},
    contactPhone: ''
}

export const emptyDriverType: DriverType = {
    id: '',
    type: '',
    description: ''
}

export const emptyDriver: Driver = {
    id: '',
    workerNumber: '',
    workerName: '',
    workerLastName: '',
    role: '',
    phoneNumber: '',
    location: emptyLocation,
    driverType: emptyDriverType
}

export const emptyDriverUI: DriverUI = {
    active: false,
    id: '',
    workerNumber: '',
    workerName: '',
    workerLastName: '',
    role: '',
    phoneNumber: '',
    location: undefined,
    driverType: emptyDriverType
}

export const emptyPaymentType: PaymentType = {
    id: '',
    type: '',
    description: ''
}

export const emptyShipment: ShipmentUI = {
    id: '',
    deliveryPoint: emptyCustomer,
    collectionPoint: emptyCustomer,
    payer: emptyCustomer,
    driver: emptyDriver,
    paymentType: emptyPaymentType,
    footNote: '',
    deliverTime: Math.floor(Date.now() / 1000),
    collectTime: Math.floor(Date.now() / 1000),
    packageNumber: '',
    packageCount: 0
}

export interface NewShipmentRequestData {
    deliveryPointId?: string,
    collectionPointId?: string,
    payerId?: string,
    driverId?: string | null,
    footNote: string,
    paymentTypeId?: string,
    deliveryTime: number,
    collectTime: number,
    packageNumber: string,
    packageCount?: number
}

export interface EditShipmentRequestData extends NewShipmentRequestData {
    id: string
}

// Used for auto import
export interface AutoGeneratedShipment {
    pickupPoint: Customer,
    deliveryPoint: CustomerRegisterRequestData,
    pickupTime: number,
    paymentType: PaymentType,
    packageNumber: string
}

export interface AdHocRegisterRequestData {
    proposedDriverId: string,
    status: string,
    shipments: NewShipmentRequestData []
}

export interface AdHocPickupDTO {
    id: string,
    shipments: ShipmentUI [],
    createdAt: number,
    updatedAt: number,
    issuer?: UserDTO,
    proposedDriver?: UserDTO,
    status: AdHocStatus,
    state: AdHocState
}

export interface ShiftBasicInfoDTO {
    assigned: number,
    unassigned: number,
    completed: number,
    failed: number
}

/**
 * Geocoding related
 */
export interface Address {
    house_number: string;
    road: string;
    residential: string;
    suburb: string;
    town: string;
    postcode: string;
    country: string;
    country_code: string;
    neighbourhood: string;
    municipality: string;
}

export const emptyAddress: Address = {
    house_number: '',
    road: '',
    residential: '',
    suburb: '',
    town: '',
    postcode: '',
    country: '',
    country_code: '',
    neighbourhood: '',
    municipality: ''
}

export interface GeocodingOBJ {
    place_id: string;
    lat: string;
    lon: string;
    display_name: string;
    address: Address;
}

export const emptyGeocodingOBJ: GeocodingOBJ = {
    place_id: '',
    lat: '46.056946',
    lon: '14.505751',
    display_name: '',
    address: {...emptyAddress}
}

/**
 * Vehicle related
 */
export interface Vehicle {
    id: string,
    internNumber: string,
    registrationNumber: string,
    weight: number,
    capacity: number,
    location?: Location,
    occupied: boolean,
    type: VehicleType
}

export enum VehicleType {
    ZM="ZM",
    SI="SI"
}

export const emptyVehicle: Vehicle = {
    capacity: 0,
    id: "",
    internNumber: "",
    occupied: false,
    registrationNumber: "",
    weight: 0,
    type: VehicleType.ZM
}

/**
 * Delivery related
 */
export interface DeliveryDTO {
    id: string,
    createdAt: number,
    driver: Driver,
    shift: ActiveShift
}

/**
 * Shift user vehicle relation
 */
export interface ShiftUserVehicleRelationDTO {
    shiftId: string,
    driverId: string,
    vehicleId: string
}


/**
 * Gps signal for sockets
 */
export interface GpsSocketMsg {
    clientId: string,
    latitude: number,
    longitude: number,
    timestamp: number
}

export interface MarkerHolder {
    marker: Marker|undefined,
    data: GpsSocketMsg
    type: string,
}

export interface AdHocStatusChangeMsg {
    type: 'AD_HOC_STATUS_CHANGE',
    payload: AdHocPickupDTO
}

export type SocketMessage = AdHocStatusChangeMsg;

export enum AdHocStatus {
    CONFIRMED="CONFIRMED",
    REJECTED="REJECTED",
    PENDING="PENDING"
}

export enum AdHocState {
    WAITING="WAITING",
    STARTED="STARTED",
    PICKED_UP="PICKED_UP",
    IN_DELIVERY="IN_DELIVERY",
    FINISHED="FINISHED"
}

export interface GoogleAutoCompletePrediction {
    description: string,
    placeId: string
}

export interface GoogleGeocodingResult {
    geometry: {
        location: {
            lng: number,
            lat: number
        },
        locationType: string,
    },
    placeId: string,
    types: string [],
    structuredFormatting: {
        mainText: string,
        secondaryText: string
    },
    formattedAddress: string,
    addressComponents: GoogleAddressComponent []
}

export interface GoogleAddressComponent {
    longName: string,
    shortName: string,
    types: string []
}

/**
 * Analytics related
 */
export interface ShiftVehicleSumRelationDTO {
    totalDistance: number,
    shiftCount: number,
    vehicleId: string
}

export interface BasicAnalyticaDTO {
    totalDistanceTraveled: number,
    avgDistanceTraveled: number,
    totalDeliveredPackages: number,
    avgDeliveredPackagesPerShift: number
}

export interface GeneralInfoResponse {
    activeCount: number,
    historyCount: number
}
