import {MutationTree} from "vuex";
import {ActiveShift, ShiftState} from "@/store/shift/types";

export enum ShiftMutations {
    SET_SHIFT = "SET_SHIFT"
}

export const mutations: MutationTree<ShiftState> = {

    [ShiftMutations.SET_SHIFT](state, shift: ActiveShift) {
        state.activeShift = {
            ...shift
        }
    }
}
