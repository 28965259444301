

















import {Component} from "vue-property-decorator";
import ViewWrapper from "@/components/ViewWrapper.vue";
import {ActiveShift} from "@/store/shift/types";
import store from "@/store";
import AdHocTable from "@/components/AdHocTable.vue";
import {AdHocPickupDTO, AdHocStatus, SocketMessage} from "@/utils/httpReqResMapping";
import {SocketConsumerMixin} from '@/mixins/SocketConsumerMixin'
import eventBus, {BusEvents} from "@/eventBus";
import AdHocAlert from "@/components/AdHocAlert.vue";
import {playRedySoundNotification} from "@/utils/audio";
import DecisionModal from "@/components/DecisionModal.vue";

@Component({
  components: {DecisionModal, AdHocAlert, AdHocTable, ViewWrapper}
})
export default class Messaging extends SocketConsumerMixin {

  // Holds ad hoc shipments
  adHocPickups: AdHocPickupDTO [] = [];

  async mounted(): Promise<void> {
    this.adHocPickups = await this.getAdHocPickups();
    eventBus.$on(BusEvents.SOCKET_MSG_EVENT, (event: MessageEvent) => {
      if (event.data == null || event.data == '') {
        return;
      }
      try {
        const data = JSON.parse(event.data) as SocketMessage;
        switch (data.type) {
          case "AD_HOC_STATUS_CHANGE":
            this.adHocPickups = this.adHocPickups.map((it) => {
              if (it.id === data.payload.id) {
                this.showAlertMessage(data.payload);
                playRedySoundNotification();
                return {
                  ...data.payload
                }
              }else {
                return it;
              }
            })
            break;
          default:
            console.log("Unrelated content ...")
        }
      }catch (e) {
        console.log('Error while parsing socket message', event.data)
      }
    })
  }

  showAlertMessage(adHocPickup: AdHocPickupDTO): void {
    this.adHocAlert.showSnack(
        `Voznik ${adHocPickup.proposedDriver?.workerName} ${adHocPickup.proposedDriver?.workerLastName} je
        ${adHocPickup.status === AdHocStatus.REJECTED ? 'zavrnil' : 'sprejel'} On/Go posiljko`
    );
  }

  get adHocAlert(): AdHocAlert {
    return this.$refs.adHocAlert as AdHocAlert;
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  // Computed property getter over decision modal
  get decisionModal(): DecisionModal {
    return this.$refs.decisionModal as DecisionModal;
  }

  async getAdHocPickups(): Promise<AdHocPickupDTO []> {
    const res = await this.$http.get<AdHocPickupDTO []>(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/adHoc`);
    return res.data;
  }

  async onDriverChange(item: AdHocPickupDTO): Promise<void> {
    if (!item || !item.proposedDriver) {
      return;
    }

    await this.$http.put(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/adHoc/${item.id}`, {
      driverId: item.proposedDriver.id
    });
    item.status = AdHocStatus.PENDING
  }

  async onAdHocDelete(item: AdHocPickupDTO): Promise<void> {
    const decision = await this.decisionModal.showModal({
      title: 'Brisanje ON GO pošiljke',
      message: `Ali ste prepričani, da želite izbrisati ON GO pošiljko ?`,
      positiveDecision: 'Izbriši',
      negativeDecision: 'Prekliči'
    });

    if (decision) {
      await this.$http.delete(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/adHoc/${item.id}`);
      this.adHocPickups = this.adHocPickups.filter(adHoc => adHoc.id !== item.id);
    }
  }
}
