import {ActionContext, ActionTree} from "vuex";
import {RootState} from "@/store/types";
import {DeliveriesState} from "@/store/deliveries/types";
import {DeliveriesMutations} from "@/store/deliveries/mutations";

export enum DeliveriesActions {
    SET_DATE="SET_DATE",
}

type Context = ActionContext<DeliveriesState, RootState>

export const actions: ActionTree<DeliveriesState, RootState> = {

    [DeliveriesActions.SET_DATE] ({ commit }: Context, date: string): void {
        commit(DeliveriesMutations.SET_DATE, date);
    },
}
