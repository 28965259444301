import {Store} from "vuex";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {RootState} from "@/store/types";
import {SpinnerActions} from "@/store/spinner/actions";
import {SnackbarActions} from "@/store/snackbar/actions";
import {AuthActions} from "@/store/auth/actions";

export function setupInterceptor(store: Store<RootState>, backgroundMode= false, client: AxiosInstance) {
    let requestPending = 0
    const req = {
        pending: () => {
            if (!backgroundMode) {
                requestPending ++;
                // start spinner
                store.dispatch(SpinnerActions.SHOW_SPINNER)
                    .then(() => {
                        //successfully
                    })
            }
        },
        done: () => {
            if (!backgroundMode) {
                requestPending --;
                if (requestPending <= 0) {
                    store.dispatch(SpinnerActions.HIDE_SPINNER)
                        .then(() => {
                            // successfully
                        })
                }
            }
        }
    };

    client.interceptors.request.use(
        async function (config: AxiosRequestConfig) {
            req.pending();
            // Workflow: check if token is expired -> if token is expired send preflight request to refresh token
            const authToken: string = store.getters.getAuthToken;
            if (authToken !== '') {
                const validToken = store.getters.isLoggedIn;
                let token = '';

                if(validToken) {
                    // Take valid token
                    token = authToken;
                } else {
                    // Try to refresh token
                    const success: boolean = await store.dispatch(AuthActions.REFRESH_TOKENS, store.getters.getRefreshToken);
                    if (success) {
                        token = store.getters.getAuthToken;
                    }
                }

                // Successfully obtained token should be placed in header
                if (token !== '') {
                    config.headers = {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            }
            return config;
        },
        function (err:any) {
            req.done()
            return Promise.reject(err)
        }
    )

    client.interceptors.response.use(
        function (res:AxiosResponse){
            req.done()
            return Promise.resolve(res);
        },
        async function (err:AxiosError) {
            req.done();
            if (err.response) {
                let errOBJ: ErrorDTO
                const responseCode: number = err.response.status as number;

                if (responseCode === 401) {
                    // Workflow -> check if user is logged in there is valid refresh token in store
                    // Dispatch another request using different instance of axios to /refresh-token
                    // If success save new tokens to store, else dispatch logout action to store
                    const success: boolean = await store.dispatch(AuthActions.REFRESH_TOKENS, store.getters.getRefreshToken);
                    if (success) {
                        // We have successfully obtained new set of tokens and saved them to local storage
                        err.config.headers['Authorization'] = 'Bearer ' + store.getters.getAuthToken;
                        return axios.request(err.config);
                    }
                }

                const errResponseData = err.response.data;
                if (isBackendError(errResponseData)) {
                    errOBJ = (errResponseData) as ErrorDTO
                } else {
                    errOBJ = {
                        errorMessage: 'Unexpected error has occurred',
                        code: -1,
                        httpStatusCode: err.response.status
                    }
                }
                store.dispatch(SnackbarActions.FIRE_SNACK, {
                    type: 'error',
                    message: errOBJ.errorMessage

                })
                    .then(() => {
                        // successfully
                    })

                return Promise.reject(errOBJ)
            } else {
                return Promise.reject(err)
            }
        }
    )

    function isBackendError(errOBJ:any): errOBJ is ErrorDTO {
        return (errOBJ as ErrorDTO).errorMessage !== undefined
    }
}

/**
 * ErrorDTO from backend
 */
interface ErrorDTO {
    errorMessage: string,
    code: number,
    httpStatusCode: number
}
