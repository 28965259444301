import {GetterTree} from "vuex";
import {ImportProcessState} from "@/store/importProcess/types";
import {RootState} from "@/store/types";
import {AutoGeneratedShipment} from "@/utils/httpReqResMapping";

export const getters: GetterTree<ImportProcessState, RootState> = {

    /**
     * returns total count of shipments
     * that need to be processed
     */
    getTotalCount (state): number {
        return state.totalShipments;
    },

    /**
     * returns number of shipments
     * that have already been processed
     */
    getCurrentCount (state): number {
        return state.currentShipment;
    },

    /**
     * returns array of all unconfirmed packages
     */
    getUnconfirmedPackages (state): AutoGeneratedShipment [] {
        return state.unconfirmedShipmentData;
    }
}
