import {DriverState} from "@/store/driver/types";
import {RootState} from "@/store/types";
import {Module} from "vuex";
import {getters} from "@/store/driver/getters";
import {mutations} from "@/store/driver/mutations";
import {emptyDriverUI} from "@/utils/httpReqResMapping";

const state: DriverState = {
    editingDriver: emptyDriverUI
}

export const driver: Module<DriverState, RootState> = {
    state,
    getters,
    mutations
}
