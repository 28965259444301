






































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {DeliveryDTO} from "@/utils/httpReqResMapping";
import DecisionModal from "@/components/DecisionModal.vue";
import RegisterVehicleModal from "@/components/RegisterVehicleModal.vue";
import {getDateString, getHourString} from "@/utils/date";
@Component({
  components: {RegisterVehicleModal, DecisionModal}
})
export default class PackageTable extends Vue {

  @Prop(Array)
  deliveries!: DeliveryDTO [];

  headers = [
    { value: 'driver', text: 'Voznik', align: 'start', sortable: false },
    { value: 'shift', text: 'Izmena', align: 'start', sortable: false },
    { value: 'createdAt', text: 'Datum dostave', align: 'start', sortable: false},
    { value: 'actions', text: 'Akcije', align: 'center', sortable: false }
  ]

  getDate(timestamp: number): string {
    return getDateString(timestamp);
  }

  getFullDate(timestamp: number): string {
    return `${getDateString(timestamp)} - ${getHourString(timestamp)}`
  }

  @Emit("previewItem")
  previewItem(item: DeliveryDTO): DeliveryDTO {
    return item;
  }
}
