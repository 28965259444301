import {Module} from "vuex";
import {RootState} from "@/store/types";
import {mutations} from "@/store/deliveries/mutations";
import {actions} from "@/store/deliveries/actions";
import {getters} from "@/store/deliveries/getters";
import {DeliveriesState} from "@/store/deliveries/types";

const state: DeliveriesState = {
    date: '',
}

export const deliveries: Module<DeliveriesState, RootState> = {
    getters,
    state,
    mutations,
    actions
}
