



























































import {Component, Prop, Vue} from "vue-property-decorator";
import {AdHocPickupDTO, AdHocState, Point} from "@/utils/httpReqResMapping";
import {getHourString} from "@/utils/date";

@Component
export default class RedyTaskTicket extends Vue {

  lineHeight = 0;
  lineMarginTop = 0;

  @Prop()
  task: AdHocPickupDTO

  @Prop()
  completed: boolean

  @Prop()
  selected: boolean

  get firstCircle(): HTMLDivElement {
    return this.$refs.firstCircle as HTMLDivElement;
  }

  get lastCircle(): HTMLDivElement {
    return this.$refs.lastCircle as HTMLDivElement;
  }

  get mContent(): HTMLDivElement {
    return this.$refs.mContent as HTMLDivElement;
  }

  get driverInfo(): string {
    if (this.task.proposedDriver !== undefined) {
      return `${this.task.proposedDriver.workerName} ${this.task.proposedDriver.workerLastName} (${this.task.proposedDriver.workerNumber})`;
    } else {
      return 'Unknown driver';
    }
  }

  get points(): Point [] {
    return [
      {...this.task.shipments[0].collectionPoint, visited: this.task.state !== AdHocState.WAITING && this.task.state !== AdHocState.STARTED} as Point,
      ...this.task.shipments.slice(0, this.task.shipments.length - 1).map((it) => {
        return {...it.deliveryPoint, visited: it.state?.name === "DELIVERED"} as Point
      }),
    ]
  }

  stateString(it: AdHocPickupDTO): string {
    if (it.proposedDriver === undefined) {
      return 'Waiting to be assigned';
    } else if (it.proposedDriver && it.state === AdHocState.WAITING) {
      return 'Waiting confirmation';
    } else if (it.state === AdHocState.FINISHED) {
      return 'Completed'
    } else {
      return 'In progress'
    }
  }

  headerClass(it: AdHocPickupDTO): string {
    if (it.proposedDriver === undefined) {
      return 'lightBlue'
    } else if (it.proposedDriver && it.state === AdHocState.WAITING) {
      return 'darkBlue'
    } else if (it.state === AdHocState.FINISHED) {
      return 'yellow'
    } else {
      return 'lightBlue'
    }
  }

  driverPoint(it: AdHocPickupDTO): string {
    return it.proposedDriver !== undefined ? it.state !== AdHocState.WAITING ? 'circle' : 'circleNonFull' : 'circleNonFull';
  }

  pinned(it: AdHocPickupDTO): boolean {
    return it.proposedDriver !== undefined && it.state !== AdHocState.WAITING
  }

  get lastPoint(): Point {
    const obj = {...this.task.shipments[this.task.shipments.length - 1].deliveryPoint, visited: false} as Point
    obj.visited = this.task.shipments[this.task.shipments.length - 1].state?.name === "DELIVERED";
    return obj;
  }

  mounted() {
    const baseHeight = this.mContent.offsetHeight;
    const firstItemHeight = this.firstCircle.offsetHeight;
    const lastItemHeight = this.lastCircle.offsetHeight;

    this.lineHeight = baseHeight - firstItemHeight / 2 - lastItemHeight / 2 - 16;
    this.lineMarginTop = firstItemHeight / 2;
    console.log(this.firstCircle.offsetHeight, this.lastCircle.offsetHeight);
  }

  parseToDate(it: number): string {
    return getHourString(it);
  }
}
