


















































import {Component, Prop, Vue} from "vue-property-decorator";
import {DeliveryDTO, ShipmentUI} from "@/utils/httpReqResMapping";
import ShipmentTable from "@/components/ShipmentTable.vue";
import SignatureModal from "@/components/SignatureModal.vue";
import {getFullDate} from "@/utils/date";

@Component({
  components: {SignatureModal, ShipmentTable}
})
export default class Delivery extends Vue {

  initFetch = false;
  shipments: ShipmentUI [] = [];
  delivery: DeliveryDTO | null = null;
  deliveryId = '';
  previewModal = false;

  async mounted(): Promise<void> {
    this.deliveryId = this.$route.params['deliveryId'] || '';
    if (this.deliveryId === '') {
      this.initFetch = true;
    }
    this.shipments.push(...(await this.$http.get<ShipmentUI []>(`${process.env.VUE_APP_API_URL}/delivery/${this.deliveryId}/shipment`)).data);
    this.delivery = (await this.$http.get<DeliveryDTO>(`${process.env.VUE_APP_API_URL}/delivery/${this.deliveryId}`)).data;

    this.initFetch = true;
  }

  getImageURL(): string {
    return `${process.env.VUE_APP_API_URL}/delivery/${this.deliveryId}/signature`;
  }

  getFullDate(seconds: number): string {
    return getFullDate(seconds);
  }

}
