var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"max-height":"500px","overflow-y":"scroll !important","-ms-overflow-style":"none","scrollbar-width":"none"}},[_c('edit-driver-modal',{ref:"driverModal",attrs:{"modal":_vm.modal},on:{"close-modal":function($event){_vm.modal = false}}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"table-drivers",attrs:{"disable-sort":"","headers":_vm.computedHeaders,"items":_vm.driversProp,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"hide-default-footer":"","header-props":{ sortIcon: 'mdi-menu-down' },"server-items-length":+_vm.totalItems},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.workerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.workerName)+" "+_vm._s(item.workerLastName)+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.driverType.type)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.editDriver(item)}}},[_vm._v("Edit ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.deleteDriver(item)}}},[_vm._v("Delete ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.onStatusChange(true, item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}])}),_c('v-pagination',{attrs:{"total-visible":"0","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }