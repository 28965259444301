import {MutationTree} from "vuex";
import {AuthState} from "@/store/auth/types";

export enum AuthMutations {
    SET_REFRESH_TOKEN="SET_REFRESH_TOKEN",
    SET_AUTH_TOKEN="SET_AUTH_TOKEN",
}

export const mutations: MutationTree<AuthState> = {

    [AuthMutations.SET_REFRESH_TOKEN](state, token) {
        state.refreshToken = token;
        localStorage.setItem('refreshToken', token);
    },

    [AuthMutations.SET_AUTH_TOKEN](state, token) {
        state.authToken = token;
        localStorage.setItem('authToken', token);
    },

}
