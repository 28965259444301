import {MutationTree} from "vuex";
import {DeliveriesState} from "@/store/deliveries/types";

export enum DeliveriesMutations {
    SET_DATE="SET_DATE",
}

export const mutations: MutationTree<DeliveriesState> = {

    [DeliveriesMutations.SET_DATE](state, date) {
        state.date = date;
    },

}
