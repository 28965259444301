<template>
  <button class="redyButton" @click="onButtonClick">
    <span class="redyText">
      {{text}}
    </span>
  </button>
</template>

<script>
export default {
  name: "RedyButton",
  props: ['text'],
  methods: {
    onButtonClick(event) {
      this.$emit('click')
      const button = event.currentTarget;

      const circle = document.createElement("span");
      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;

      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
      circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];
      console.log(ripple)
      if (ripple) {
        ripple.remove();
      }

      button.appendChild(circle);
    }
  }
}
</script>

<style>
.redyButton {
  background-color: #FFD541;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 9px;
  padding: 8px 18px;
  position: relative;
  overflow: hidden;
  transition: background 400ms;
}
.redyButton:hover {
  cursor: pointer;
}
.redyText {
  font: normal normal normal 14px Arial;
  color: #000000;
  margin: 0;
  padding: 0;
}
span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid red;
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>