



















































import {Component, Vue, Watch} from "vue-property-decorator";
import {LoginRequestData, LoginResponseData} from "@/utils/httpReqResMapping";
import {AuthActions} from "@/store/auth/actions";
import {VForm} from "./types";
import {SnackbarActions} from "@/store/snackbar/actions";
import {ShiftActions} from "@/store/shift/actions";
import {parseJWT} from "@/utils/jwtUtils";
import {JWTAuthPayload} from "@/store/auth/types";
import InputField from "@/components/InputField.vue";

@Component({
    components: {
        InputField
    }})
export default class Login extends Vue {

    userError = false
    passwordError = false

    loginData: LoginRequestData = {
        workerNumber: '',
        password: ''
    }

    // Holds validity of form
    validForm = true

    // Reference to form
    get form(): VForm {
        return this.$refs.form as VForm;
    }

    getUser(mText: string) {
        this.loginData.workerNumber = mText
    }

    getPassword(mText: string) {
        this.loginData.password = mText
    }

    @Watch('loginData.workerNumber')
    userChange() {
        if (this.loginData.workerNumber.length > 0) {
            this.userError = false
        } else {
            this.userError = true
        }
    }

    @Watch('loginData.password')
    passwordChange() {
        if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.loginData.password)) {
            this.passwordError = false
        } else {
            this.passwordError = true
        }
    }

    // Sends login request
    async loginUser(): Promise<void> {

        if (this.loginData.workerNumber.length > 0) {
            this.userError = false
        } else {
            this.userError = true
        }

        if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.loginData.password)) {
            this.passwordError = false
        } else {
            this.passwordError = true
        }

        if (this.userError || this.passwordError) return

        try {
            // Check if form is valid
            this.validForm = this.form.validate();
            if (!this.validForm) {

                // Notify client with warning
                await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                    type: 'warning',
                    message: 'Prosimo izpolnite obrazec za prijavo pravilno !'
                })
                return
            }

            // Obtain auth and refresh token if success
            const response = await this.$http.post<LoginResponseData>(`${process.env.VUE_APP_API_URL}/auth/login`, this.loginData);

            try {
              const user = parseJWT<JWTAuthPayload>(response.data.authToken);
              if (user.role !== "ADMIN" && user.role !== "DISPATCHER") {
                return await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                  type: 'error',
                  message: 'Dostop zavrnjen - za prijavo nimate zadostnih pravic'
                });
              }
            }catch (e: unknown) {
              return await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                type: 'error',
                message: 'Pri prijavi je prislo do napake'
              });
            }

            // Store them in vuex store
            await this.$store.dispatch(AuthActions.SET_TOKENS, response.data);

            // Initialize shift - necessary for app to work !
            await this.$store.dispatch(ShiftActions.INIT_SHIFT);

            // Redirect user to main screen
            await this.$router.push({
                name: 'Overview'
            })
        } catch (e) {
            console.log(e);
        }

    }


    //TODO why is this duplicated in rules.ts?
    numberRules = [
        (v: string): boolean | string => !!v || 'Stevilka delavca ne sme biti prazna',
    ]

    passwordRules = [
        (v: string): boolean | string => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'Geslo mora vsebovati vsaj en simbol, stevko, veliko in malo crko ter mora biti dolgo vsaj 8 znakov'
    ]
}
