// Helper function to extract date
import {GpsSocketMsg, MarkerHolder, Vehicle} from "@/utils/httpReqResMapping";

export const getDateString = (seconds: number): string => {
    const date = new Date(seconds * 1000);
    return `${date.getDate()}/${date.getUTCMonth() + 1}/${date.getFullYear()}`;
}

// Helper function to extract hour
export const getHourString = (seconds: number): string =>{
    const date = new Date(seconds * 1000);
    return `${date.getHours() < 10 ? `0${date.getHours()}`: date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
}

// Gets unix timestamp from date
export const getUnixTimestamp = (date: Date): number => {
    return Math.floor(date.getTime()/1000);
}

export const getFullDate = (second: number) => {
    return `${getHourString(second)} - ${getDateString(second)} `;
}

export const isDisconnected = (data: GpsSocketMsg, DISCONNECTED_TIME_LIMIT: number): boolean => {
    return data.timestamp + DISCONNECTED_TIME_LIMIT < Date.now()
}
