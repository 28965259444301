import {AutoGeneratedShipment} from "@/utils/httpReqResMapping";

export interface ImportProcessState {
    status: ImportProcessStatus,
    unconfirmedShipmentData : AutoGeneratedShipment [],
    totalShipments: number,
    currentShipment: number
}

/**
 * WAITING -> meaning there is no data loaded to store nor is store
 * working on export
 *
 * WORKING -> meaning store is in process of geocoding / matching raw entries
 * with customer database
 *
 *  CONFIRMATION_WAIT -> meaning store is finished with processing but user has to
 * confirm customers that system could not found and suggests to be added to customer DB
 */
export enum ImportProcessStatus {
    WAITING = "WAITING",
    WORKING = "WORKING",
    CONFIRMATION_WAIT = "CONFIRMATION_WAIT"
}
