


























import {Component, Prop, Vue} from "vue-property-decorator";
import RedyTasks from "@/components/RedyNew/RedyTasks.vue";
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";
import RedyTaskPanelDate from "@/components/RedyNew/RedyTaskPanelDate.vue";

@Component({
  components: {RedyTaskPanelDate, RedyTasks}
})
export default class RedyMainPanel extends Vue {

  driversSelected = false;
  tasksSelected = true;

  handleHeaderClick(): void {
    this.tasksSelected = !this.tasksSelected;
    this.driversSelected = !this.driversSelected;
  }

  get tasks(): AdHocPickupDTO [] {
    return this.$store.getters["tasks"];
  }

  get dateSL(): string {
    const date = new Date();
    const day = date.getDay()
    let slDay = ''
    if (day === 0) {
      slDay = 'ponedeljek'
    } else if (day === 1) {
      slDay = 'torek'
    } else if (day === 2) {
      slDay = 'sreda'
    } else if (day === 3) {
      slDay = 'četrtek'
    } else if (day === 4) {
      slDay = 'petek'
    } else if (day === 5) {
      slDay = 'sobota'
    } else if (day === 6) {
      slDay = 'nedelja'
    }

    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}, ${slDay}`;
  }

  get selectedType(): string {
    return this.$store.getters['type'];
  }

  get shiftBasicInfo(): ShiftBasicInfoDTO | undefined {
    return this.$store.getters['shiftBasicInfo'];
  }
}
