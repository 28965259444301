var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.barcodeModal && _vm.barcodeSelection != null)?_c('BarCodeModal',{attrs:{"shipment":_vm.barcodeSelection},on:{"onClose":_vm.onBarcodeModalClose}}):_vm._e(),_c('v-data-table',{staticClass:"table-shipment",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.shipment,"hide-default-footer":true,"fixed-header":"","height":"100%"},scopedSlots:_vm._u([{key:"header.deliverTime",fn:function(){return [_c('v-icon',[_vm._v("mdi-clock-time-three-outline")])]},proxy:true},{key:"header.driver",fn:function(){return [_c('v-icon',[_vm._v("mdi-account-outline")])]},proxy:true},{key:"header.paymentType",fn:function(){return [_c('v-icon',[_vm._v("mdi-currency-eur")])]},proxy:true},{key:"item.collectionPoint",fn:function(ref){
var item = ref.item;
return [(item.collectionPoint)?[_c('div',{staticClass:"pa-1 table_item"},[_c('b',[_vm._v(_vm._s(item.collectionPoint.name.toUpperCase())+" "),(item.payer && item.collectionPoint.id === item.payer.id)?_c('PayerIcon'):_vm._e()],1),_c('p',[_vm._v("("+_vm._s(item.collectionPoint.address.displayName)+")")])])]:[_vm._v(" Sprejemno mesto posiljke ni znano ")]]}},{key:"item.deliveryPoint",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[(item.deliveryPoint)?[_c('b',[_vm._v(" "+_vm._s(item.deliveryPoint.name.toUpperCase())+" "),(item.payer && item.deliveryPoint.id === item.payer.id)?_c('PayerIcon'):_vm._e()],1),_c('p',[_vm._v("("+_vm._s(item.deliveryPoint.address.displayName)+")")])]:[_vm._v(" Dostavno mesto posiljke ni znano ")]],2)]}},{key:"item.telephone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item"},[_vm._v(" "+_vm._s(item.deliveryPoint.contactPhone)+" "),_c('br'),_vm._v(" "+_vm._s(item.deliveryPoint.taxNumber)+" ")])]}},{key:"item.deliverTime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table_item"},[_vm._v(" "+_vm._s(_vm.toTimeStamp(item.deliverTime))+" "),_c('p',{staticClass:"timeWindowed",class:_vm.isBeingLate(item.deliverTime) ? 'late' : ''},[_vm._v(" "+_vm._s(_vm.timeWindowedTimeStamp(item.deliverTime))+" ")])])]}},{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-1 table_item",staticStyle:{"text-align":"left"}},[(item.driver)?[_vm._v(" "+_vm._s(item.driver.workerLastName)+" ")]:[_vm._v(" Posiljka nima dodoeljenega voznika ")]],2)]}},{key:"item.paymentType",fn:function(ref){
var item = ref.item;
return [(item.paymentType)?[_c('div',{staticClass:"table_item"},[_vm._v(" "+_vm._s(item.paymentType.type)+" ")])]:[_vm._v(" Nacin placila ni znan ")]]}},{key:"item.packageNumber",fn:function(ref){
var item = ref.item;
return [(item.packageNumber)?_c('div',{staticClass:"table_item centered"},[_vm._v(" "+_vm._s(item.packageNumber)+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function () { return _vm.showBarcodeModal(item); }}},[_c('v-icon',[_vm._v(" mdi-barcode ")])],1)],1):_c('div',{staticClass:"table_item centered"},[_vm._v(" Paket nima stevilke ")])]}}])}),(_vm.showSubmitBtn)?_c('v-btn',{staticClass:"float-right",attrs:{"elevation":"2"}},[_vm._v(" Dodaj pošiljko ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }