import {GetterTree} from "vuex";
import {SnackbarState} from "@/store/snackbar/types";
import {RootState} from "@/store/types";

export const getters: GetterTree<SnackbarState, RootState> = {

    getSnackbarMessage (state): string {
        return state.snackbar.message;
    },
    getSnackbarType (state): string {
        return state.snackbar.type;
    },
    getTicketMessages (state): {title: string, content: string} [] {
        return state.ticketMessages;
    }
}
