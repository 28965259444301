





















































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import PayerIcon from "@/components/PayerIcon.vue";
import {AutoGeneratedShipment, CustomerRegisterRequestData, emptyGeocodingOBJ} from "@/utils/httpReqResMapping";
import ErrorIcon from "@/components/ErrorIcon.vue";
import DecisionModal from "@/components/DecisionModal.vue";
import CustomerModal from "@/components/CustomerModal.vue";
import CustomerPreviewModal from "@/components/CustomerPreviewModal.vue";

@Component({
  components: {CustomerPreviewModal, CustomerModal, DecisionModal, ErrorIcon, PayerIcon}
})
export default class PackageImportTable extends Vue {

  headers = [
    { value: 'deliveryPoint', sortable: false, text: 'Naziv in naslov nepotrjene stranke', width: '33rem' },
    { value: 'actions', sortable: false, text: 'Akcije' },
  ]

  @Prop(Array)
  shipment!: AutoGeneratedShipment [];

  get customerDisplay(): AutoGeneratedShipment [] {
    const seen = new Set();
    return this.shipment.filter(el => {
      const duplicate = seen.has(el.deliveryPoint.name);
      seen.add(el.deliveryPoint.name);
      return !duplicate;
    });
  }

  get dialog (): DecisionModal {
    return this.$refs.modal as DecisionModal;
  }

  get customerDialog (): CustomerModal {
    return this.$refs.customerModal as CustomerModal;
  }

  get customerPreviewDialog (): CustomerPreviewModal {
    return this.$refs.customerPreviewModal as CustomerPreviewModal;
  }

  async handleDelete(item: AutoGeneratedShipment): Promise<void> {
    const result = await this.dialog.showModal({
      title: `Ali ste prepricani, da zelite odstraniti stranko ${item.deliveryPoint.name} ?`,
      message: `Akcija, ki jo zelite izvesti bo povzrocila, da vse posiljke namenjene stranki ${item.deliveryPoint.name} ne bodo dodane k delovni izmeni`,
      positiveDecision: 'Potrdi',
      negativeDecision: 'Preklici'
    });
    if (result) {
      this.removeCustomer(item);
    }
  }

  async handleAdd(item: AutoGeneratedShipment): Promise<void> {
    this.addCustomer(item);
  }

  async handleCustomerCreate(item: AutoGeneratedShipment): Promise<void> {
    const result: CustomerRegisterRequestData | null = await this.customerDialog.showModal({
      address: {...emptyGeocodingOBJ},
      name: '',
      contactPhone: '',
      taxNumber: ''
    }, item);
    if (result) {
      this.createCustomer(item);
    }
  }

  handleMap(item: AutoGeneratedShipment): void{
    this.customerPreviewDialog.showModal(item);
  }

  // Means client has agreed to add suggested customer
  // And all packages belonging to it
  @Emit('addCustomer')
  addCustomer(item: AutoGeneratedShipment): AutoGeneratedShipment {
    return item;
  }

  // Means client disagreed to add suggested customer
  // And all packages belonging to it -> this action is questionable
  @Emit('removeCustomer')
  removeCustomer(item: AutoGeneratedShipment): AutoGeneratedShipment {
    return item;
  }

  // Means client pressed + icon to add new customer manually
  // And all packages belonging to it
  @Emit('createCustomer')
  createCustomer(item: AutoGeneratedShipment): AutoGeneratedShipment {
    return item;
  }
}
