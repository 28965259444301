



























































































































import {Component, Vue} from "vue-property-decorator";
import {emptyVehicle, Vehicle} from "@/utils/httpReqResMapping";
import {nonEmptyRule} from "@/utils/rules";
import {VForm} from "@/views/types";

@Component
export default class RegisterVehicleModal extends Vue {

  dialog = false;
  resolve!: (v: Vehicle | undefined) => void;
  reject!: (v:any) => void
  title = '';

  editedVehicle:Vehicle = {...emptyVehicle}
  vehicleTypes = [
    {
      value: 'ZM',
      text: 'Znotraj mesta'
    },
    {
      value: 'SI',
      text: 'Po Sloveniji'
    }
  ];

  rules = {
    intern: [
      nonEmptyRule("Interna stevilka ne sme biti prazna")
    ],
    registration: [
        nonEmptyRule("Registerska stevilka ne sme biti prazna")
    ],
    weight: [
        nonEmptyRule("Teza vozila ne sme biti prazna")
    ],
    capacity: [
        nonEmptyRule("Kapaciteta vozila ne sme biti prazna")
    ]

  }

  get registerVehicleForm (): VForm {
    return this.$refs.registerVehicleForm as VForm;
  }

  showModal(vehicle: Vehicle, title: string): Promise<Vehicle | undefined> {
    this.dialog = true;
    this.title = title;
    this.editedVehicle = {...vehicle};

    return new Promise<Vehicle | undefined>((res, rej) => {
      this.resolve = res;
      this.reject = rej;
    })
  }

  // When user emits the change
  onPositive(): void {
    const validForm = this.registerVehicleForm.validate();
    if (!validForm) {
      return;
    }
    this.dialog = false;
    this.resolve(this.editedVehicle);
    this.registerVehicleForm.resetValidation();
  }

  // When users dismisses modal
  onNegative(): void {
    this.dialog = false;
    this.resolve(undefined);
    this.registerVehicleForm.resetValidation();
  }

  // Information about vehicle type
  isCity(type: string): boolean {
    return type === 'ZM';
  }


}
