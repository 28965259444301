














































































































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import ShipmentPointTableEntry from "@/components/ShipmentPointTableEntry.vue";
import {AdHocPickupDTO, AdHocState, Driver} from "@/utils/httpReqResMapping";
import {ActiveShift} from "@/store/shift/types";
import store from "@/store";
import WebsocketManager from "@/utils/socketManager";
import {isCountryPickup} from "@/utils/shipment";

interface AdHocStatusUiDetails {
  color: string,
  text: string
}

@Component({
  components: {ShipmentPointTableEntry}
})
export default class AdHocTable extends Vue {
  @Prop(Array) readonly adHocPickups: AdHocPickupDTO [];
  headers = [
    {
      text: 'Naziv stranke, naslov - prevzem',
      value: 'pickup',
      width: '24rem'
    },
    {
      text: 'Naziv stranke, naslov - dostava',
      value: 'delivery',
      width: '24rem'
    },
    {
      text: 'Kreiral posiljko',
      value: 'issuer',
      width: '140px'
    },
    {
      text: 'Predlagan voznik',
      value: 'proposedDriver',
      width: '130px'
    },
    {
      text: 'Status posiljke',
      value: 'status',
      width: '130px'
    },
    {
      text: 'Akcije',
      value: 'actions',
      width: '68px',
      sortable: false,
    }
  ]
  drivers: Driver [] = [];
  websocket: WebsocketManager = new WebsocketManager();

  getStatusString(status: AdHocState): AdHocStatusUiDetails {
    switch (status) {
      case AdHocState.FINISHED:
        return {
          color: '#4CAF50',
          text: 'ZAKLJUČENA'
        }
      case AdHocState.PICKED_UP:
        return {
          color: '#2196F3',
          text: 'V DOSTAVI'
        }
      case AdHocState.WAITING:
        return {
          color: '#FF5252',
          text: 'ČAKA NA PREVZEM'
        }
      case AdHocState.IN_DELIVERY:
          return {
            color: '#2196F3',
            text: 'V DOSTAVI'
          }
      case AdHocState.STARTED:
          return {
            color: '#2196F3',
            text: 'V POSTOPKU PREVZEMA'
          }
      default:
        return {
          color:'#2196F3',
          text: status
        }
    }
  }

  @Emit('onDelete')
  deleteAdHoc(item?: AdHocPickupDTO): AdHocPickupDTO | undefined {
    return item;
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  async mounted(): Promise<void> {
    this.drivers = (await this.$http.get<Driver []>(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers`)).data;
    console.log(this.drivers, "drivers")
  }

  isFinished(status: AdHocState): boolean {
    return status === AdHocState.FINISHED || status === AdHocState.PICKED_UP || status == AdHocState.IN_DELIVERY;
  }

  @Emit('onDriverChange')
  onDriverChange(item?: AdHocPickupDTO): AdHocPickupDTO | undefined {
    return item;
  }

  isCountry(adHoc: AdHocPickupDTO): boolean {
    return isCountryPickup(adHoc);
  }

  getName(it: Driver) {
    console.log(it)
    return ""
  }
}
