import {MutationTree} from "vuex";
import {SnackbarOBJ, SnackbarState} from "@/store/snackbar/types";

export enum SnackbarMutations {
    SHOW_SNACK= "SHOW_SNACK",

    ADD_TICKET_MESSAGE = "ADD_TICKET_MESSAGE",
    REMOVE_TICKET_MESSAGE = "REMOVE_TICKET_MESSAGE"
}

export const mutations: MutationTree<SnackbarState> = {

    [SnackbarMutations.SHOW_SNACK](state, payload: SnackbarOBJ) {
        state.snackbar = {
            message: payload.message,
            type: payload.type
        }
    },

    [SnackbarMutations.ADD_TICKET_MESSAGE](state, payload: {title: string, content: string}) {
        state.ticketMessages.push(payload);
    },

    [SnackbarMutations.REMOVE_TICKET_MESSAGE](state, payload: {title: string, content: string}) {
        state.ticketMessages = state.ticketMessages.filter(it => it.title !== payload.title && it.content !== payload.content);
    }
}
