
















































































import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import {DriverUI} from "@/utils/httpReqResMapping";
import {readyForLoad} from "@/utils/pagination";
import EditDriverModal from "@/components/EditDriverModal.vue";
import {DriverMutations} from "@/store/driver/mutations";
@Component({
    components: {
        EditDriverModal
  }
})
export default class DriverTable extends Vue {

    dialogDelete = false;
    deleteDriverInd = -1;
    page = 1;
    pageCount = 0;

    @Watch('page')
    emitPageNum() {
        this.$emit('pageNum', this.page - 1);
    }

    @Prop() totalItems: number;
    @Prop() itemsPerPage: number;
    @Prop() readonly driversProp!: DriverUI[]
    @Prop() readonly driversType!: string

    drivers: DriverUI[] = this.driversProp;
    modal = false
    headers = [
        {
            text: 'Courier',
            align: 'start',
            value: 'workerName',
        },
        {
            text: 'Worker number',
            value: 'workerNumber'
        },
        {
            text: 'Phone number',
            value: 'phoneNumber'
        },
        {
            text: 'Area',
            value: 'type'
        },
        {
            text: 'Status',
            value: 'active',
            width: '160px'
        },
        {
            align: 'center',
            value: 'actions',
            sortable: false,
            width: '70px'
        }
    ]

    get computedHeaders() {
        return this.driversType === 'active' ? this.headers.filter(el => el.value !== 'actions') : this.headers
    }

    statusDropdown = [
        { title: 'Aktiven', value: true},
        { title: 'Neaktiven', value: false }
    ]

    onStatusChange (newStatus: boolean, driver: DriverUI): void {
        // Do not emit event to parent when status stays the same
        if (newStatus) {
            // Emit when status has changed
            this.statusChange(driver);
        }
    }

    editDriver(driver: DriverUI): void {
        this.$store.commit(DriverMutations.SET_EDIT_DRIVER, driver)
        this.modal = true;
    }

    deleteDriver(driver: DriverUI): void {
        this.deleteDriverInd = this.drivers.indexOf(driver);
        this.dialogDelete = true;
    }

    deleteItemConfirm () {
        this.drivers.splice(this.deleteDriverInd, 1);
        this.closeDelete();
    }

    closeDelete() {
        this.dialogDelete = false;
        this.$nextTick(() => {
          this.deleteDriverInd = -1
        })
    }

    // Emits only when status has changed
    @Emit('statusChange')
    statusChange(driver: DriverUI): DriverUI {
        return driver;
    }

    @Emit('loadPage')
    loadPage(): boolean {
        return true;
    }

    endIntersect(e: any): void {
        const fetch = readyForLoad(e);
        if (fetch) {
            this.loadPage();
        }
    }

    @Watch('modal')
    async handleChange(val: boolean): Promise<void> {
        if (val) {
            this.$store.commit('SPINNER_MODAL')
        } else {
            this.$store.commit('SPINNER_NORMAL')
        }        
    }
}
