

















































import {Component, Mixins} from 'vue-mixin-decorator';
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {Emit, Prop, VModel, Watch} from "vue-property-decorator";
import {
  Customer,
  CustomerAutoSelectEntity,
  CustomerRegisterRequestData,
  emptyGeocodingOBJ,
  GoogleAutoCompletePrediction, GoogleGeocodingResult, GeocodingOBJ
} from "@/utils/httpReqResMapping";
import {convertGoogleAddressOBJtoGeocodingOBJ, googleTextSearchToCustomer} from "@/utils/geocoding";
import {SnackbarActions} from "@/store/snackbar/actions";
import {VForm} from "@/views/types";

@Component
export default class CustomerAutoSelect extends Mixins<PaginationMixin<Customer>>(PaginationMixin) {

  customers: CustomerAutoSelectEntity [] = [];

  @Prop(Array)
  readonly selectionItems: string [] | undefined;

  @Prop(Array)
  readonly displayItems: string [] | undefined;

  @Prop()
  readonly value!: CustomerAutoSelectEntity;
  currentValue: CustomerAutoSelectEntity = this.value;

  @Prop(String)
  readonly filterName!: string;

  @Prop(String)
  readonly label!: string;

  filterValue = ''
  timeout: number | null = null;

  get debouncedFilterValue(): string {
    return this.filterValue;
  }

  set debouncedFilterValue(val: string) {
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.filterValue = val;
    }, 300)
  }

  async mounted(): Promise<void> {
    this.customers = (await this.getNextPage('/customer', true)).map((it) => ({...it, dbPersisted: true}));
  }

  // Watching for filter to change
  @Watch('filterValue')
  async handleFilterChange(filter: string): Promise<void> {
    // Reset pagination info to default and load first page
    this.items = [];
    this.resetPaginationInfo();

    // Add query params
    const queryParams: any = {};
    queryParams[this.filterName] = filter;

    this.customers = (await this.getNextPage('/customer', true, queryParams)).map((it) => ({...it, dbPersisted: true}));
    if (this.filterValue && this.filterValue != "" && this.customers.length < 3) {
      await this.addGoogleFallback();
    }
  }

  // Watching for customer change
  @Watch('currentValue')
  async handleValueChange(value?: CustomerAutoSelectEntity): Promise<void> {
    // Check if database persisted - if not create customer
    if (!value) {
      return;
    }
    if (!value.dbPersisted && value.placeId) {
      const googlePlaceId = value.placeId;
      const geocodedObject: GoogleGeocodingResult [] = (await this.$http.get<GoogleGeocodingResult []>(`${process.env.VUE_APP_API_URL}/geocode/google/${googlePlaceId}`)).data;
      if (geocodedObject.length === 0) {
        return await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
          type: 'error',
          message: 'Napaka pri geokodiranju poskusite znova !'
        })
      }
      const customer: CustomerRegisterRequestData = {
        name: value.name,
        taxNumber: '',
        contactPhone: '',
        address: convertGoogleAddressOBJtoGeocodingOBJ(geocodedObject[0])
      };
      // check if customer with same place_id as geocoded already in db (even though it wasn't visible in autoselect (google can query better than our backend))
      let res
      const dbCustomersWithSamePlaceId: Customer[] = (await this.$http.get<Customer []>(`${process.env.VUE_APP_API_URL}/customer?place_id=${customer.address.place_id}`)).data;
      if(dbCustomersWithSamePlaceId.length === 0) res = (await this.$http.post<Customer>(`${process.env.VUE_APP_API_URL}/customer`, customer)).data;
      else res = dbCustomersWithSamePlaceId[0]
      value.dbPersisted = true;
      this.emitChosen(res);
      return;
    }

    this.emitChosen(value);
  }


  @Emit('input')
  emitChosen(val: Customer): Customer {
    return val;
  }

  /**
   * User reached bottom of scrollable
   * load next page
   */
  async endIntersect(): Promise<void> {

    // Add query params
    const queryParams: any = {};
    queryParams[this.filterName] = this.filterValue;

    this.customers = this.customers.concat(...
        (await this.getNextPage('/customer', false, queryParams))
            .map((it) => ({...it, dbPersisted: true})));
  }

  /**
   * Helper function to extract deep nested values from json
   */
  getProperty(json: any, path: string): any {
    const tokens = path.split(".");
    let obj = json;
    for (let i = 0; i < tokens.length; i++) {
      obj = obj[tokens[i]];
    }
    return obj;
  }

  /**
   * Adds non db persisted customers fetched from google cloud api
   */
  async addGoogleFallback(): Promise<void> {
    const data = (await this.$http.get<GoogleAutoCompletePrediction []>(`${process.env.VUE_APP_API_URL}/geocode/google/places?q=${this.filterValue}`)).data;
    let alreadyInDb = false
    const customers = googleTextSearchToCustomer(data).filter((it) => {
      alreadyInDb = false
      this.customers.forEach((c) => {
        if(it.placeId === c.address.placeId) alreadyInDb = true
      })
      return !alreadyInDb
    });
    this.customers.push(...customers);
  }

  get myForm(): VForm {
    return this.$refs.myForm as VForm
  }

  reset(): void {
    this.myForm.reset()
    this.myForm.resetValidation()
  }

}
