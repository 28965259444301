
































import {Component, Watch} from "vue-property-decorator";
import mapboxgl, {LngLat, Marker} from "mapbox-gl";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Multipane, MultipaneResizer} from "vue-multipane";
import ShipmentTable from "../components/ShipmentTable.vue";
import {Mixins} from "vue-mixin-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {
  AdHocPickupDTO,
  AdHocState,
  Customer,
  Driver,
  GpsSocketMsg,
  MarkerHolder,
  ShipmentUI,
  VehicleType,
} from "@/utils/httpReqResMapping";
import store from "@/store";
import EmptyAlert from "@/components/EmptyAlert.vue";
import DateHeader from "@/components/DateHeader.vue";
import WebsocketManager from "@/utils/socketManager";
import eventBus, {BusEvents} from "@/eventBus";
import PackageTable from "@/components/PackageTable.vue";
import {isDisconnected} from "@/utils/date";
import LegendDialog from "@/components/LegendDialog.vue";
import NewShipmentModal from "@/components/NewShipmentModal.vue";
import {getPopupHTML} from "@/utils/mapbox";
import {ActiveShift} from "@/store/shift/types";
import RedyTaskPanel from "@/components/RedyNew/RedyMainPanel.vue";
import {OverviewActions} from "@/store/overview/actions";
import {AxiosResponse} from "axios";
import RedyTaskUpdateSnack from "@/components/RedyNew/RedyTaskUpdateSnack.vue";
import {TicketOverviewData} from "@/components/types";
import { getLatLonCenter } from "@/utils/geocoding";

@Component({
  components: {
    RedyTaskUpdateSnack,
    RedyTaskPanel,
    NewShipmentModal,
    LegendDialog,
    PackageTable,
    Multipane,
    MultipaneResizer,
    ShipmentTable,
    EmptyAlert,
    DateHeader
  }
})
export default class Overview extends Mixins<PaginationMixin<ShipmentUI>>(PaginationMixin) {
  DISCONNECTED_TIME_LIMIT = 5 * 60 * 1000;
  accessToken = process.env.VUE_APP_MAPBOX_KEY;
  websocket: WebsocketManager = new WebsocketManager();
  map!: mapboxgl.Map
  markers = new Map<string, MarkerHolder>();
  disconnectedChecker = 0;
  authToken: string = this.$store.getters.getAuthToken
  drivers = new Map<string, Driver>();
  click = false
  ticketPanel = localStorage.getItem('ticket-panel') != "false"

  ticketOverview: TicketOverviewData = {
    pathId: '',
    collectPointMarker: undefined,
    etaMarkers: [],
    deliveryPointMarkers: []
  }

  data () {
    return {
      picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  }

  close() {
    this.click = false
  }

  beforeDestroy(): void {
    console.log("DESTROYING")
    clearInterval(this.disconnectedChecker)
    this.websocket.close();
  }

  async mounted(): Promise<void> {
    this.mapboxInit()
    const activeDrivers = await this.getDriversForActiveShift();

    await this.$store.dispatch(OverviewActions.FETCH_TASKS);
    await this.$store.dispatch(OverviewActions.FETCH_BASIC_INFO);

    activeDrivers.forEach((driver) => {
      this.drivers.set(driver.workerNumber, driver)
      this.updateMarker({ clientId: driver.workerNumber, latitude: driver.location?.latitude ?? -1, longitude: driver.location?.longitude ?? -1, timestamp: (driver.location?.updatedAt ?? -1) * 1000})
    })

    const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/overview/tasks`);
    console.log(res.data);

    this.disconnectedChecker = setInterval(() => {
      this.checkForDisconnectedDrivers()
    }, 5000);

    // Every time token changes reinitialize socket connection
    store.watch(
        state => state.auth.authToken,
        (newVal) => {
          this.websocket.refreshToken(newVal)
        }
    );

    //Attach listener for socket messages
    eventBus.$on(BusEvents.SOCKET_MSG_EVENT, (event: MessageEvent) => {
      if (event.data == null || event.data == '') return;

      const data = JSON.parse(event.data) as GpsSocketMsg;
      if(this.drivers.has(data.clientId)) {
        this.updateMarker(data)
      }else {
        if (!data.clientId) return;
        this.getDriverByWorkerNumber(data.clientId).then((driver) => {
          this.drivers.set(data.clientId, driver)
          this.updateMarker(data)
        })
      }
    })

    this.websocket.refreshToken(this.authToken);
    eventBus.$on('taskHide', (val: boolean) => {
      this.ticketPanel = val;
      setTimeout(() => {
        this.map.resize();
      }, 200)
    })
  }

  mapboxInit(): void {
    mapboxgl.accessToken = this.accessToken;
    const mapDomElement = document.getElementById("gpsMap");
    if (mapDomElement == null) throw new DOMException("DOM element gpsMap not found");

    const map = new mapboxgl.Map({
      container: document.getElementById("gpsMap") || 'gpsMap',
      style: "mapbox://styles/mapbox/light-v10",
      center: [14.486680, 46.026317],
      zoom: 12
    });
    map.addControl(new mapboxgl.FullscreenControl());
    this.map = map;
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }
  // Computed property over ref - legend dialogs
  get legendDialog(): LegendDialog {
    return this.$refs.legendDialog as LegendDialog;
  }

  async getDriversForActiveShift(): Promise<Driver[]> {
    const res: Driver[] = (await this.$http.get<Driver []>(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/drivers`)).data;
    return res;
  }

  async getDriverByWorkerNumber(workerNumber: string): Promise<Driver> {
    const res: Driver = (await this.$http.get<Driver>(`${process.env.VUE_APP_API_URL}/driver/${workerNumber}`)).data;
    return res;
  }

  get currentlyChosenTicket(): AdHocPickupDTO | undefined {
    return this.$store.getters['currentlySelectedTicket'];
  }

  @Watch('currentlyChosenTicket')
  handleCurrentChosenTicket(ticket: AdHocPickupDTO | undefined): void {
    // If ticket is not null draw current path of shipment and  display ETA
    if (ticket) {
      this.generateTicketVisit(ticket);
    } else {
      this.removeTicketVisit();
    }
  }

  checkForDisconnectedDrivers(): void {
    for (let [_, markerHolder] of this.markers) {
      if (markerHolder.type === 'disconnected') continue;
      if (isDisconnected(markerHolder.data, this.DISCONNECTED_TIME_LIMIT)) {
        markerHolder.marker?.remove();
        const newMarker = this.generateMarker(markerHolder.data);
        newMarker.addTo(this.map);

        this.markers.set(markerHolder.data.clientId, {
          marker: newMarker,
          data: markerHolder.data,
          type: 'disconnected'
        })
      }
    }
  }

  /**
   * @param data - signal from socket (GpsSocketMsg object)
   */
  updateMarker(data: GpsSocketMsg): void {
    const markerHolder = this.markers.get(data.clientId);
    if(markerHolder && markerHolder.marker && markerHolder.data) {
      if(markerHolder.type === 'disconnected') {
        markerHolder.marker.remove();
        this.makeNewMarker(data, 'car');
        return;
      }
      markerHolder.marker.setLngLat(new LngLat(data.longitude, data.latitude))
      markerHolder.data.longitude = data.longitude;
      markerHolder.data.latitude = data.latitude;
      markerHolder.data.timestamp = Date.now()

      markerHolder.marker.getPopup().setHTML(getPopupHTML(markerHolder.data, this.drivers.get(data.clientId)));
      this.markers.set(data.clientId, markerHolder);
    }else if(!markerHolder) {
      this.makeNewMarker(data)
    }else {
      console.log(`Failed to update gpsMarker`)
    }
  }

  /**
   * @param data - GpsSocketMsg object
   * @param style - a String for differentiating icon style, 'car' for usual yellow icon, 'disconnected' for gray car icon
   */
   makeNewMarker(data: GpsSocketMsg, style = 'car'): void {
    const newMarker = this.generateMarker(data).addTo(this.map);

    this.markers.set(data.clientId, {
      marker: newMarker,
      data: data,
      type: style
    });
  }

  /**
   * @param data - GpsSocketMsg object
   */
  generateMarker(data: GpsSocketMsg): Marker {
    const driver = this.drivers.get(data.clientId)
    const el = document.createElement('div');
    if(isDisconnected(data, this.DISCONNECTED_TIME_LIMIT)) el.className = driver?.driverType.type === VehicleType.SI ? 'marker-disconnected' : 'marker-city-disconnected';
    else el.className = driver?.driverType.type === VehicleType.SI ? 'marker' : 'marker-city';
    const html = getPopupHTML(data, driver);
    return new mapboxgl.Marker(el).setLngLat([data.longitude, data.latitude]).setPopup(new mapboxgl.Popup().setHTML(html))
  }

  /**
   * Removes path and marker
   */
  removeTicketVisit() {
    this.ticketOverview.deliveryPointMarkers.forEach((it) => it.remove());
    this.ticketOverview.collectPointMarker?.remove();
    this.ticketOverview.etaMarkers.forEach((it) => it.remove());

    if (this.map.getLayer('ticket-route')) this.map.removeLayer('ticket-route')
    if (this.map.getSource('ticket-route')) this.map.removeSource('ticket-route')

    this.ticketOverview = {
      pathId: '',
      collectPointMarker: undefined,
      etaMarkers: [],
      deliveryPointMarkers: []
    }

    this.map.flyTo({
      center: [14.486680, 46.026317],
      essential: true
    });
  }

  /**
   * Creates path and marker
   * @param data - AdHocPickup
   */
  async generateTicketVisit(data: AdHocPickupDTO): Promise<void> {
    this.removeTicketVisit();
    const driver = data.proposedDriver ? this.markers.has(data.proposedDriver.workerNumber) ? this.markers.get(data.proposedDriver.workerNumber) : undefined : undefined;

    const driverLocation = driver ? {
      latitude: driver.data.latitude,
      longitude: driver.data.longitude
    } : undefined;

    if (data.state === AdHocState.STARTED && data.shipments[0].collectionPoint) {
      // Driver is driving to pickup point -> show only pickup and path to pickup
      const eta = await this
          .generateETA(driverLocation, data.shipments[0].collectionPoint)

      this.ticketOverview.etaMarkers.push(eta);
      eta.addTo(this.map);

      if (driverLocation) {
        const res = await this.$http.post<{latitude: number, longitude: number} []>(`${process.env.VUE_APP_API_URL}/overview/path`, {
          fromLocation: {
            latitude: driverLocation.latitude,
            longitude: driverLocation.longitude
          },
          toLocation: {
            latitude: data.shipments[0].collectionPoint.location.latitude,
            longitude: data.shipments[0].collectionPoint.location.longitude
          }
        });
        const path = res.data;
        this.createPath(path)
        const center = getLatLonCenter([
          {
            latitude: driverLocation.latitude,
            longitude: driverLocation.longitude
          },
          {
            latitude: data.shipments[0].collectionPoint.location.latitude,
            longitude: data.shipments[0].collectionPoint.location.longitude
          }
        ])
        this.map.flyTo({
          center: {
            lat: center.latitude,
            lon: center.longitude
          }
        })
      }
    } else if (data.state === AdHocState.IN_DELIVERY || data.state === AdHocState.FINISHED) {
      // Driver already picked up -> show pickup, and markers to other delivery points
      // (if already delivered to point show only delivery marker, else show actual pin with ETA inside)
      const dataPoints: {latitude: number, longitude: number} [] = [];

      data
          .shipments
          .forEach((ship, index) => {
            if (index === 0 && ship.collectionPoint) {
              const shipmentMarker = this.generateShipmentMarker(ship.collectionPoint, true)
              this.ticketOverview.collectPointMarker = shipmentMarker
              shipmentMarker.addTo(this.map);

              dataPoints.push(ship.collectionPoint.location);
            }

            if (ship.deliveryPoint) dataPoints.push(ship.deliveryPoint?.location);

            if (ship.state?.name === "IN_TRANSITION" && ship.deliveryPoint) {
              this
                  .generateETA(driverLocation, ship.deliveryPoint)
                  .then((etaMarker) => {
                    this.ticketOverview.etaMarkers.push(etaMarker)
                    etaMarker.addTo(this.map);
                  })
            } else if (ship.state?.name === "DELIVERED" && ship.deliveryPoint) {

              const shipmentMarker = this.generateShipmentMarker(ship.deliveryPoint, false)
              this.ticketOverview.deliveryPointMarkers.push(shipmentMarker)
              shipmentMarker.addTo(this.map);

            }
          })
      const promises: Promise<AxiosResponse<{latitude: number, longitude: number}[]>> [] = [];
      dataPoints.forEach((ship, index) => {
        if (index < dataPoints.length - 1) {
          const from = ship;
          const to = dataPoints[index + 1]
          promises.push(this.$http.post<{latitude: number, longitude: number}[]>(`${process.env.VUE_APP_API_URL}/overview/path`, {
            fromLocation: {
              latitude: from.latitude,
              longitude: from.longitude
            },
            toLocation: {
              latitude: to.latitude,
              longitude: to.longitude
            }
          }))
        }
      })
      const result = await Promise.all(promises);
      const points = result
          .map((it) => it.data)
          .reduce((a, b) => [...a, ...b], []);
      const center = getLatLonCenter(points);
      if (points.length > 0) {
        this.map.flyTo({
          center: {
            lat: center.latitude,
            lon: center.longitude
          }
        })
      }
      this.createPath(points);
    }
  }

  /**
   * Generate marker for shipment
   * @param customer - customer
   * @param pickup - boolean if pickup is true
   */
  generateShipmentMarker(customer: Customer, pickup: boolean): Marker {
    const el = document.createElement('div');
    el.className = pickup ? 'shipment' : 'shipment_delivery';

    return new mapboxgl.Marker(el).setLngLat([customer.location.longitude, customer.location.latitude]);
  }

  /**
   * Generates ETA marker
   * @param driverLocation - driver location
   * @param customer - customer
   */
  async generateETA(driverLocation: {latitude: number, longitude: number} | undefined, customer: Customer): Promise<Marker> {
    const ETT = driverLocation && customer ? await this.getETT(driverLocation, {latitude: customer.location.latitude, longitude: customer.location.longitude}) : '?'
    const el = document.createElement('div');
    el.className = 'eta_marker';
    el.innerHTML = `
     <div style="font-weight: bold; position:absolute;top: 5px; width: 100%; text-align: center">${ETT}</div>
     <div style="font-size: 0.35rem; position: absolute; bottom: 12px; width: 100%; text-align: center">min</div>
    `
    return new mapboxgl.Marker(el).setLngLat([customer.location.longitude, customer.location.latitude]);
  }

  async getETT(from: {latitude: number, longitude: number}, to: {latitude: number, longitude: number}): Promise<number> {
    const res = await this.$http.post<{ estimateTimeTravel: number}>(`${process.env.VUE_APP_API_URL}/overview/ETT`, {
      fromLocation: from,
      toLocation: to
    })
    return Math.round(res.data.estimateTimeTravel / (1000 * 60))
  }

  /**
   * Draws path
   * @param points - array of points
   */
  createPath(points: {latitude: number, longitude: number} []): void {
    this.map.addSource('ticket-route', {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: points.map((it) => {
            return [
              it.longitude,
              it.latitude
            ]
          })
        }
      }
    })

    if (!this.map.getLayer('ticket-route')) {
      this.map.addLayer({
        id: 'ticket-route',
        type: 'line',
        source: 'ticket-route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#006B98',
          'line-width': 5
        }
      })
    }
  }
}
