import {ShiftState} from "@/store/shift/types";
import {RootState} from "@/store/types";
import {Module} from "vuex";
import {getters} from "@/store/shift/getters";
import {actions} from "@/store/shift/actions";
import {mutations} from "@/store/shift/mutations";

const state: ShiftState = {
    activeShift: {
        id: '',
        createdAt: 0,
        updatedAt: 0,
        active: false
    }
}

export const shift: Module<ShiftState, RootState> = {
    state,
    getters,
    actions,
    mutations
}
