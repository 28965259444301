import * as XLSX from 'xlsx';

/**
 * @param file - file that has been selected by client
 * @return - JSON array of type T
 */
export const parseXLSX = <T>(file: File): Promise<T []> => {

    return new Promise<T[]>((resolve, reject) => {
        let arrayBuffer: ArrayBuffer | null | string = null;
        const fileReader: FileReader = new FileReader();

        fileReader.readAsArrayBuffer(file);

        fileReader.onload = () => {
            arrayBuffer = fileReader.result;
            const data = new Uint8Array(arrayBuffer as ArrayBuffer);
            const arr: string [] = [];
            data.forEach((value, index) => {
                arr[index] = String.fromCharCode(value);
            })

            const str = arr.join("");
            const workbook = XLSX.read(str, {type: 'binary'});
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            resolve(XLSX.utils.sheet_to_json<T>(worksheet, {raw: true}))
        }

    })
}
