/***
 * @project redy
 * @author azurkurbasic on 12/12/2022
 */
import {ActionTree} from "vuex";
import {OverviewState} from "@/store/overview/types";
import {RootState} from "@/store/types";
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";
import httpClient from "@/utils/httpClient";
import {OverviewMutations} from "@/store/overview/mutations";

export enum OverviewActions {
    FETCH_TASKS = "FETCH_TASKS",
    SET_TYPE = "SET_TYPE",
    FETCH_BASIC_INFO = "FETCH_BASIC_INFO",
    SET_SELECTED_TICKET = "SET_SELECTED_TICKET",
    UPDATE_TASK = "UPDATE_TASK",
    UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO",
    ADD_TASK_TO_ASSIGNED = "ADD_TASK_TO_ASSIGNED"
}

export const actions: ActionTree<OverviewState, RootState> = {

    /**
     * Issues http request to backend fetching current tasks
     */
    async [OverviewActions.FETCH_TASKS]({commit, state}): Promise<AdHocPickupDTO []> {
        const currentType = state.chosenType
        const res = await httpClient.get<AdHocPickupDTO []>(`${process.env.VUE_APP_API_URL}/overview/tasks?type=${currentType}`);

        commit(OverviewMutations.SET_TASKS, res.data);
        return res.data;
    },

    /**
     * Sets type of overview action
     */
    async [OverviewActions.SET_TYPE]({commit}, type): Promise<void> {
        commit(OverviewMutations.SET_TYPE, type);
    },

    /**
     * Fetches shipment basic information
     */
    async [OverviewActions.FETCH_BASIC_INFO]({commit}): Promise<void> {
        const res = await httpClient.get<ShiftBasicInfoDTO>(`${process.env.VUE_APP_API_URL}/overview/basicInfo`);

        commit(OverviewMutations.SET_BASIC_INFO, res.data);
    },

    /**
     * Sets selected ticket
     */
    async [OverviewActions.SET_SELECTED_TICKET]({commit}, it: AdHocPickupDTO | undefined): Promise<void> {
        commit(OverviewMutations.SET_SELECTED_TICKET, it);
    },

    /**
     * Updates task
     */
    [OverviewActions.UPDATE_TASK]({commit}, it: AdHocPickupDTO): void {
        commit(OverviewMutations.UPDATE_TASK, it);
    },

    /**
     * Updates basic info
     */
    [OverviewActions.UPDATE_BASIC_INFO]({commit}, it: ShiftBasicInfoDTO): void {
        commit(OverviewMutations.SET_BASIC_INFO, it);
    },

    /**
     * Adds task to assigned queue
     */
    [OverviewActions.ADD_TASK_TO_ASSIGNED]({commit}, it: AdHocPickupDTO): void {
        commit(OverviewMutations.ADD_TASK_TO_ASSIGNED, it);
    }
}
