import {CustomerState} from "@/store/customer/types";
import {RootState} from "@/store/types";
import {Module} from "vuex";
import {getters} from "@/store/customer/getters";
import {mutations} from "@/store/customer/mutations";
import {actions} from "@/store/customer/actions";
import {emptyGeocodingOBJ, emptyLocation} from "@/utils/httpReqResMapping";

const state: CustomerState = {
    editingCustomer: {
        address: {...emptyGeocodingOBJ},
        name: '',
        contactPhone: '',
        taxNumber: ''
    },
    customerId: ''
}



export const customer: Module<CustomerState, RootState> = {
    state,
    getters,
    actions,
    mutations
}
