import {Module} from "vuex";
import {RootState} from "@/store/types";
import {SnackbarState} from "@/store/snackbar/types";
import {getters} from './getters';
import {mutations} from "@/store/snackbar/mutations";
import {actions} from "@/store/snackbar/actions";

const state: SnackbarState = {
    snackbar: {
        type: 'success',
        message: 'message',
    },
    ticketMessages: []
}

export const snackbar: Module<SnackbarState, RootState> = {
    state,
    getters,
    mutations,
    actions
}
