import { render, staticRenderFns } from "./TasksTable.vue?vue&type=template&id=624f8254&scoped=true&"
import script from "./TasksTable.vue?vue&type=script&lang=ts&"
export * from "./TasksTable.vue?vue&type=script&lang=ts&"
import style0 from "./TasksTable.vue?vue&type=style&index=0&id=624f8254&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "624f8254",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VDataTable,VPagination})
