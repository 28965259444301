/***
 * @project redy
 * @author azurkurbasic on 12/12/2022
 */
import {initState, OverviewState} from "@/store/overview/types";
import {Module} from "vuex";
import {RootState} from "@/store/types";
import {actions} from "@/store/overview/actions";
import {mutations} from "@/store/overview/mutations";
import {getters} from "@/store/overview/getters";

const state: OverviewState = {
    ...initState
}

export const overview: Module<OverviewState, RootState> = {
    state,
    mutations,
    actions,
    getters
}