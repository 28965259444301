/**
 *
 */
import {GpsSocketMsg, Driver} from "@/utils/httpReqResMapping";

export const getPopupHTML = (data: GpsSocketMsg, driver: Driver | undefined): string => {
    const locationUpdated: string = (new Date(data.timestamp)).toLocaleString()
    const additionalHTML = `<p>Lokacija posodobljena: <span id="location-${data.clientId}">${locationUpdated}</span></p>`;
    return (
        `<h3>Sifra zaposlenega: ${driver ? `${driver.workerNumber}` : 'Ni znano'}</h3>
                 <p>Ime in Priimek: <b> ${driver ? `${driver.workerName} ${driver.workerLastName}` : 'Ni znano'}</b></p>
                 <p>Telefonska stevilka: <b>${driver ? `${driver.phoneNumber}` : 'Ni znano'}</b></p>
                 ${additionalHTML}`
    );
}
