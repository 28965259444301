






























import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import CustomerAdder from "@/components/CustomerAdder.vue";
import {CustomerRegisterRequestData, Location, emptyGeocodingOBJ, Customer, emptyAddress} from "@/utils/httpReqResMapping";
import {SnackbarActions} from "@/store/snackbar/actions";
@Component({
  components: {CustomerAdder}
})
export default class NewCustomer extends Vue {
    @Prop() readonly isEditing: boolean

    customer: CustomerRegisterRequestData = {
          address: JSON.parse(JSON.stringify(this.editingCustomer.address)),
          name: this.editingCustomer.name,
          contactPhone: this.editingCustomer.contactPhone,
          taxNumber: this.editingCustomer.taxNumber
    }

    get editingCustomer(): CustomerRegisterRequestData {
        return this.$store.getters.getEditingCustomer
    }

    get editingCustomerLocation(): Location {
        return (this.$store.getters.getEditingCustomer as Customer).location
    }

    @Watch("editingCustomer", { immediate: true, deep: true })
    handleEditingCustomer(customer: CustomerRegisterRequestData): void {
        this.customer = customer
    }

    mounted(): void {
        if (!this.isEditing) {
            this.customer.address = {...emptyGeocodingOBJ}
            this.customer.name = ''
            this.customer.contactPhone = ''
            this.customer.taxNumber = ''
        }
    }

    async onCustomer(customerData: CustomerRegisterRequestData): Promise<void> {
        if (this.isEditing) {
            const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/customer/${this.$store.getters.getEditingCustomerId}`, this.customer);
            if (response.status === 200) {
                this.$emit('close-modal');
                this.$emit('update-table', response.data);
            }
            await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                type: 'success',
                message: `Uspesno ste uredili stranko ${this.customer.name}`
            });
        } else {
            await this.$http.post(`${process.env.VUE_APP_API_URL}/customer`, customerData);
            this.customer = {
                address: {...emptyGeocodingOBJ, address: {...emptyAddress}},
                name: '',
                contactPhone: '',
                taxNumber: ''
            }

            await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
                type: 'success',
                message: 'Uspesno ste dodali novo stranko'
            });
        }
    }
}
