/***
 * @project redy
 * @author azurkurbasic on 12/12/2022
 */
import {GetterTree} from "vuex";
import {OverviewState} from "@/store/overview/types";
import {RootState} from "@/store/types";
import {AdHocPickupDTO, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";

export const getters: GetterTree<OverviewState, RootState> = {

    /**
     * Currently loaded tasks
     */
    tasks(state): AdHocPickupDTO [] {
        return state.tasks;
    },

    /**
     * Currently chosen type
     */
    type(state): string {
        return state.chosenType;
    },

    /**
     * Current shift info
     */
    shiftBasicInfo(state): ShiftBasicInfoDTO | undefined {
        return state.basicInfo;
    },

    /**
     * Gets currently selected ticket
     */
    currentlySelectedTicket(state): AdHocPickupDTO | undefined {
        return state.selectedTicket;
    }

}