










import store from '../store/index'
import {Component, Vue } from "vue-property-decorator";
@Component
export default class Spinner extends Vue {

    show = false;
    // At modal windows the spinner should be centered on the whole page, ignoring the largeScreen rule
    isModal = false;
    created(): void {
        store.watch(
            state => state.spinner.show,
            (show) => this.show = show
        )
        store.watch(
            state => state.spinner.isModal,
            (isModal) => this.isModal = isModal
        )
    }

    get largeScreen(): boolean {
        if (this.isModal) {
            return false
        }
        const screen = this.$vuetify.breakpoint.name;
        return screen !== 'xs' && screen !== 'sm'
    }

}
