import {GetterTree} from "vuex";
import {SpinnerState} from "@/store/spinner/types";
import {RootState} from "@/store/types";

export const getters: GetterTree<SpinnerState, RootState> = {
    /**
     * Get boolean if data is being fetched
     */
    getIsLoadingSpinner(state): boolean {
        return state.show
    }
}
