import eventBus, {BusEvents} from "@/eventBus";
import {AdHocPickupDTO, AdHocState, ShiftBasicInfoDTO} from "@/utils/httpReqResMapping";
import store from "@/store";
import {OverviewActions} from "@/store/overview/actions";
import {SnackbarActions} from "@/store/snackbar/actions";
import {getHourString} from "@/utils/date";

/**
 * Manages websocket connection
 */
export default class WebsocketManager {
    socket: WebSocket | null = null
    token = ''

    // Construct Socket url from root and latest jwt token
    private getWsUrl(): string {
        return `${process.env.VUE_APP_SOCKET_URL}?jwt=${this.token}`;
    }

    // Creates socket client for backend/frontend communication
    startSocketClient(): void {
        console.log("Starting WebSocket client");
        const url = this.getWsUrl()
        console.log(`Web socket connecting to: ${url}`)
        this.socket = new WebSocket(url);
        this.socket.onopen = () => {
            console.log("Socket open");
        };
        this.socket.onerror = (event) => {
            console.error('Websocket has encountered an error', event)
        }

        this.socket.onmessage = async (event: MessageEvent) => {
            eventBus.$emit(BusEvents.SOCKET_MSG_EVENT, event);

            if (event.data !== null && event.data !== '') {

                const data = JSON.parse(event.data) as { type: string, payload: any }
                const payload = data.payload as AdHocPickupDTO;
                switch (data.type) {
                    case "AD_HOC_STATE_UPDATE":
                        // Update task
                        await store.dispatch(OverviewActions.UPDATE_TASK, payload);
                        break;
                    case "AD_HOC_STATUS_UPDATE":
                        // Update task
                        await store.dispatch(OverviewActions.UPDATE_TASK, payload);
                        break;
                    case "AD_HOC_DELIVERY":
                        // Update task
                        if (payload.state === AdHocState.FINISHED) {
                            await store.dispatch(SnackbarActions.ADD_TICKET_MESSAGE, {
                                title: `Completed TASK - ${payload.id}`,
                                content: `Driver ${payload.proposedDriver?.workerName} ${payload.proposedDriver?.workerLastName} successfully delivered task at: ${getHourString(payload.updatedAt)}`
                            });
                            const currentInfo: ShiftBasicInfoDTO | undefined = store.getters.shiftBasicInfo
                            if (currentInfo) {
                                console.log('Updating current info', currentInfo)
                                await store.dispatch(OverviewActions.UPDATE_BASIC_INFO, {
                                    ...currentInfo,
                                    completed: currentInfo.completed + 1
                                } as ShiftBasicInfoDTO)
                            }
                        }
                        await store.dispatch(OverviewActions.UPDATE_TASK, payload);
                        break;
                    default:
                        break;
                }
            }
        };
        this.socket.onclose = (event) => {
            console.log("Socket closed with code [" + event.code + '] - ' + event.reason);
        };
    }

    // refreshed locally stored jwt token. Closes client if set to empty
    refreshToken(newToken: string): void {
        this.token = newToken;

        if (this.token == '') {
            console.log('Closing websocket due to empty token')
            this.close();
        } else {
            console.log('JWT token updated, restarting socket')
            this.reInitSocketConnection()
        }

    }

    // Closes client if open
    close(): void {
        if (this.socket != null && this.socket.readyState === this.socket.OPEN) {
            this.socket.close();
            this.socket = null;
        }
    }


    // Reinitialize socket connection
    private reInitSocketConnection(): void {
        this.close()

        setTimeout(() => {
            const socket = this.socket;
            if (socket == null || (socket.readyState === socket.CLOSED || socket.readyState !== socket.CLOSING)) {
                this.startSocketClient();
            }

        }, 2000);
    }
}
