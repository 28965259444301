
































































































import {Component, Watch} from "vue-property-decorator";
import DateHeader from "@/components/DateHeader.vue";
import {Customer} from "@/utils/httpReqResMapping";
import {Mixins} from "vue-mixin-decorator";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import CustomerTable from "@/components/CustomerTable.vue";
import {readyForLoad} from "@/utils/pagination";
import EmptyAlert from "@/components/EmptyAlert.vue"

@Component({
  components: {CustomerTable, DateHeader, EmptyAlert}
})
export default class Customers extends Mixins<PaginationMixin<Customer>>(PaginationMixin) {

  text = "";
  customers: Customer [] = [];
  currentCustomers: Customer [] = [];
  fetch = false;
  itemsPerPage = [10, 50];
  itemsPerPageText = ["Show 10", "Show 50"];
  selected = "Show 10";
  clicked = false;
  selectedItem = 10
  page = 0;

  async setPageNum(pageNum: number) {
    this.page = pageNum;
    this.changePaginationInfo(this.selectedItem, (this.page) * this.selectedItem);
    this.customers = await this.getNextPage('/customer', true);
  }

  handleClick() {
    this.clicked = true;
  }

  async setNumberOfItems(index: number) {
    this.selected = this.itemsPerPageText[index];
    this.selectedItem = this.itemsPerPage[index];
    this.page = 0;
    this.changePaginationInfo(this.selectedItem, 0);
    this.customers = await this.getNextPage('/customer', true);
  }

  updateTable(customer: Customer): void {
    const customerIndex = this.customers.findIndex(el => el.id === customer.id);
    if (customerIndex > -1) {
      this.customers.splice(customerIndex, 1, customer);
    }
  }

  async search() {
    var queryParameters = {'name': this.text}
    this.changePaginationInfo(this.selectedItem, 0);
    this.customers = await this.getNextPage('/customer', true, queryParameters);
  }

  async created(): Promise<void> {
    this.customers = await this.getNextPage('/customer', true);
    //console.log(this.customers);
    this.fetch = true;
  }

  async loadPage(): Promise<void> {
    if (!this.customers) {
      this.customers = [];
    }
    this.customers.push(...await this.getNextPage('/customer', false));
  }

  endIntersect(e: unknown): void {
    const fetch = readyForLoad(e);
    if (fetch) {
      this.loadPage();
    }
  }
}
