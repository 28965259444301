var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5",staticStyle:{"max-height":"500px","overflow-y":"scroll !important","-ms-overflow-style":"none","scrollbar-width":"none"}},[_c('v-data-table',{staticClass:"table-drivers",attrs:{"items":_vm.tasks,"headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.isLoading,"hide-default-footer":"","header-props":{ sortIcon: null }},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.tasks",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.tasks)+" ")])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [(item.customer === '')?_c('span',[_vm._v(" Ni na voljo ")]):_c('span',{staticStyle:{"text-transform":"uppercase","font-weight":"bold"}},[_vm._v(" "+_vm._s(item.customer)+" ")])]}},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [(item.phoneNumber === '')?_c('span',[_vm._v(" Ni na voljo ")]):_c('span',[_vm._v(" "+_vm._s(item.phoneNumber)+" ")])]}},{key:"item.courier",fn:function(ref){
var item = ref.item;
return [(item.courier === '')?_c('span',[_vm._v(" Ni na voljo ")]):_c('span',[_vm._v(" "+_vm._s(item.courier)+" ")])]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [(item.createTime === '')?_c('span',[_vm._v(" Ni na voljo ")]):_c('span',[_vm._v(" "+_vm._s(item.createTime)+" ")])]}},{key:"item.dueTime",fn:function(ref){
var item = ref.item;
return [(item.dueTime === '')?_c('span',[_vm._v(" Ni na voljo ")]):_c('span',[_vm._v(" "+_vm._s(item.dueTime)+" ")])]}}])}),_c('v-pagination',{staticClass:"pa-2",attrs:{"total-visible":"0","length":_vm.pageCount,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }