


























































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Customer} from "@/utils/httpReqResMapping";
import EditCustomerModal from "@/components/EditCustomerModal.vue";
import {CustomerActions} from "@/store/customer/actions";

@Component({
  components: {
    EditCustomerModal
  }
})
export default class CustomerTable extends Vue {

  page = 1;
  pageCount = 0;

  @Watch('page')
  emitPageNum() {
    this.$emit('pageNum', this.page - 1);
  }

  @Prop() totalItems: number;
  @Prop() itemsPerPage: number;
  @Prop(Array) readonly customers: Customer [];
  modal = false
  headers = [
    {
      text: 'Customer',
      align: 'start',
      value: 'name',
    },
    {
      text: 'Addres',
      value: 'address.displayName'
    },
    {
      text: 'Phone number',
      value: 'contactPhone'
    },
    {
      text: 'Tax number',
      value: 'taxNumber'
    },
    {
      align: 'center',
      value: 'actions'
    }
  ]

  @Watch('modal')
  async handleChange(val: boolean): Promise<void> {
    if (val) {
      this.$store.commit('SPINNER_MODAL')
    } else {
      this.$store.commit('SPINNER_NORMAL')
    }
  }

  editCustomer(customer: Customer): void {
    this.$store.dispatch(CustomerActions.GET_CUSTOMER, customer)
    this.modal = true;
  }

}
