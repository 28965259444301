import {
    CustomerAutoSelectEntity, emptyAddress,
    emptyCustomer, emptyGeocodingOBJ, GoogleAddressComponent,
    GoogleAutoCompletePrediction,
    GoogleGeocodingResult, GeocodingOBJ
} from "@/utils/httpReqResMapping";

export const googleTextSearchToCustomer = (predictions: GoogleAutoCompletePrediction []): CustomerAutoSelectEntity [] => {

    return predictions.map((it) => {
        return {
            ...emptyCustomer,
            dbPersisted: false,
            placeId: it.placeId,
            name: it.description
        }
    })
}

const getAddressComponent = (googleOBJ: GoogleGeocodingResult, component: string): GoogleAddressComponent | undefined => {
    return googleOBJ.addressComponents.find((it) => it.types.find((type) => type === component));
}

/**
 * Transforms google address to GeocodingOBJ
 */
export const convertGoogleAddressOBJtoGeocodingOBJ = (googleOBJ: GoogleGeocodingResult): GeocodingOBJ => {
    return {
        lat: googleOBJ.geometry.location.lat.toString(),
        lon: googleOBJ.geometry.location.lng.toString(),
        display_name: googleOBJ.formattedAddress,
        place_id: googleOBJ.placeId,
        address: {
            ...emptyAddress,
            house_number: getAddressComponent(googleOBJ, "STREET_NUMBER")?.longName || "",
            road: getAddressComponent(googleOBJ, "ROUTE")?.longName || "",
            postcode: getAddressComponent(googleOBJ, "POSTAL_CODE")?.longName || "",
            town: getAddressComponent(googleOBJ, "POSTAL_TOWN")?.longName || "",
            country: getAddressComponent(googleOBJ, "COUNTRY")?.longName || "",
            country_code: getAddressComponent(googleOBJ, "COUNTRY")?.shortName || "",
            municipality: getAddressComponent(googleOBJ, "ADMINISTRATIVE_AREA_LEVEL_1")?.longName || "",
            neighbourhood: getAddressComponent(googleOBJ, "NEIGHBORHOOD")?.longName || "",
        }
    }
}

/**
 * Gets center of lat and lon based on array of points of locations
 * @param locations - arr of locations
 */
export const getLatLonCenter = (locations: {latitude: number, longitude: number} []): {latitude: number, longitude: number} => {
    let latitude = 0, longitude = 0;
    locations.forEach((loc) => {
        latitude += loc.latitude
        longitude += loc.longitude
    });

    return {
        latitude: latitude / locations.length,
        longitude: longitude / locations.length
    }
}