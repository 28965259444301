import {ActionTree} from "vuex";
import {SnackbarOBJ, SnackbarState} from "@/store/snackbar/types";
import {RootState} from "@/store/types";
import {SnackbarMutations} from "@/store/snackbar/mutations";

export enum SnackbarActions {
    FIRE_SNACK='FIRE_SNACK',

    ADD_TICKET_MESSAGE='ADD_TICKET_MESSAGE',
    REMOVE_TICKET_MESSAGE='REMOVE_TICKET_MESSAGE'
}

export const actions: ActionTree<SnackbarState, RootState> = {

    [SnackbarActions.FIRE_SNACK] ({ commit }, data: SnackbarOBJ): void {
        commit(SnackbarMutations.SHOW_SNACK, data);
    },
    [SnackbarActions.ADD_TICKET_MESSAGE] ({commit}, data: string): void {
        commit(SnackbarMutations.ADD_TICKET_MESSAGE, data);
    },
    [SnackbarActions.REMOVE_TICKET_MESSAGE] ({commit}, data: string): void {
        commit(SnackbarMutations.REMOVE_TICKET_MESSAGE, data);
    }
}
