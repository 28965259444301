import {AuthState} from "@/store/auth/types";
import {Module} from "vuex";
import {RootState} from "@/store/types";
import {mutations} from "@/store/auth/mutations";
import {actions} from "@/store/auth/actions";
import {getters} from "@/store/auth/getters";

const state: AuthState = {
    authToken: localStorage.getItem('authToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || ''
}

export const auth: Module<AuthState, RootState> = {
    state,
    mutations,
    actions,
    getters
}
