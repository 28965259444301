








































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
  AdHocPickupDTO,
  AdHocRegisterRequestData,
  AdHocStatus,
  Customer,
  Driver,
  EditShipmentRequestData,
  emptyCustomer,
  emptyShipment,
  NewShipmentRequestData,
  PaymentType,
  ShipmentUI
} from "@/utils/httpReqResMapping";
import {ActiveShift} from "@/store/shift/types";
import store from '@/store/index';
import {emptyPaginationInfo, fetchingPossible, getPaginationInfo, PaginationInfo} from "@/utils/pagination";
import {AxiosResponse} from "axios";
import {nonEmptyEntity, nonEmptyRule} from "@/utils/rules";
import {emptyDeliveryPoint, EnteredDeliveryPoint, VForm} from "@/views/types";
import {SnackbarActions} from "@/store/snackbar/actions";
import {getDateString, getHourString} from '@/utils/date';
import {AdHocPickup, availableAdHocTypes, ShipmentType} from "@/utils/shipment";
import AutoSelect from "@/components/AutoSelect.vue";
import CustomerAutoSelect from "@/components/CustomerAutoSelect.vue";


@Component({
  components: {CustomerAutoSelect, AutoSelect}
})
export default class NewShipment extends Vue {

  @Prop() readonly isModalIncluded: boolean;

  @Prop() readonly isEditing: boolean
  newShipment: ShipmentUI = {
    ...emptyShipment
  };
  pickupTypes = [...availableAdHocTypes];

  rules = {
    pickupAddress: [
      nonEmptyEntity<Customer>("Prosimo izberite prevzemnika !")
    ],
    payerAddress: [
      nonEmptyEntity<Customer>("Prosimo izberite placnika !")
    ],
    deliveryAddress: [
      nonEmptyEntity<Customer>("Prosimo izberite naslov dostave !")
    ],
    driver: [
      nonEmptyEntity<Driver>("Prosimo izberite voznika !")
    ],
    paymentType: [
      nonEmptyEntity<PaymentType>("Prosimo izberite nacin placila !")
    ],
    shared: [
      nonEmptyRule("Polje je obvezno !")
    ]
  }

  // Type of delivery
  deliveryType: AdHocPickup = this.pickupTypes[0]

  // UI - dates
  pickupHour = "";
  pickupDate = "";

  // Filters
  pickupAddressSearch = '';
  deliverAddressSearch = '';
  payerAddressSearch = '';
  driverNameSearch = '';

  // Is one who ordered it paying for it
  selfPaid = false

  pickupPoints: Customer [] = [];
  pickupPointsPagination: PaginationInfo = {...emptyPaginationInfo};

  payerPoints: Customer [] = [];
  payerPointsPagination: PaginationInfo = {...emptyPaginationInfo};

  deliveryPoints: Customer [] = [];
  deliveryPointsPagination: PaginationInfo = {...emptyPaginationInfo};

  drivers: Driver [] = [];

  typesOfPayment: PaymentType [] = [];

  enteredDeliveryPoints: EnteredDeliveryPoint [] = [];

  logisticCenter: Customer | undefined = undefined;

  // Handle change on address
  async endIntersectPickupAddress(): Promise<void> {
    const fetch = fetchingPossible(this.pickupPointsPagination);
    if (fetch) {
      const res = await this.fetchCustomerByAddress(this.pickupAddressSearch, this.pickupPointsPagination);
      this.pickupPointsPagination.offset += this.pickupPointsPagination.limit;
      this.pickupPoints = this.pickupPoints.concat(...res.data)
    }
  }

  @Watch("pickupAddressSearch")
  async handleChangePickupAddress(address: string): Promise<void> {
    if (address) {
      // Set pickupPoints to empty
      this.pickupPoints = [];
      const res = await this.fetchCustomerByAddress(address);
      this.pickupPointsPagination = {
        ...getPaginationInfo(res, this.pickupPointsPagination)
      }
      this.pickupPoints = res.data;
    }
  }

  async endIntersectDeliverAddress(): Promise<void> {
    const fetch = fetchingPossible(this.deliveryPointsPagination);
    if (fetch) {
      const res = await this.fetchCustomerByAddress(this.deliverAddressSearch, this.deliveryPointsPagination);
      this.deliveryPointsPagination.offset += this.deliveryPointsPagination.limit;
      this.deliveryPoints = this.deliveryPoints.concat(...res.data)
    }
  }

  @Watch("deliverAddressSearch")
  async handleChangeDeliverAddress(address: string): Promise<void> {
    console.log(this.enteredDeliveryPoints)
    if (address) {
      // Set delivery point to empty
      this.deliveryPoints = [];
      const res = await this.fetchCustomerByAddress(address);
      this.deliveryPointsPagination = {
        ...getPaginationInfo(res, this.deliveryPointsPagination)
      }
      this.deliveryPoints = res.data;
      console.log(this.deliveryPoints, address);
    }
  }

  async endIntersectPayerAddress(): Promise<void> {
    const fetch = fetchingPossible(this.payerPointsPagination);
    if (fetch) {
      const res = await this.fetchCustomerByAddress(this.payerAddressSearch, this.payerPointsPagination);
      this.payerPointsPagination.offset += this.payerPointsPagination.limit;
      this.payerPoints = this.payerPoints.concat(...res.data);
    }
  }

  @Watch("payerAddressSearch")
  async handleChangePayerAddress(address: string): Promise<void> {
    if (address) {
      // Check if self payed
      if (this.selfPaid) {
        return;
      }
      this.payerPoints = [];
      const res = await this.fetchCustomerByAddress(address);
      this.payerPointsPagination = {
        ...getPaginationInfo(res, this.payerPointsPagination)
      }
      this.payerPoints = res.data;
    }
  }

  async fetchDriversByName(): Promise<Driver[]> {
    const shiftID = this.shift.id;
    if (shiftID == '') {
      return [];
    }
    const res = await this.$http.get<Driver[]>(`${process.env.VUE_APP_API_URL}/shifts/${shiftID}/drivers`);
    return res.data;
  }

  async fetchCustomerByAddress(address: string, paginationInfo?: PaginationInfo): Promise<AxiosResponse<Customer[]>> {
    const offset = paginationInfo?.offset || 0;
    const limit = paginationInfo?.limit || 10;
    return await this.$http.get<Customer []>(`${process.env.VUE_APP_API_URL}/customer?name=${address}&offset=${offset}&limit=${limit}`);
  }

  async fetchPaymentTypes(): Promise<AxiosResponse<PaymentType[]>> {
    return await this.$http.get<PaymentType[]>(`${process.env.VUE_APP_API_URL}/customer/payment`)
  }

  // Handle change on self paid
  @Watch("selfPaid")
  handleSelfPaidChange(value: boolean): void {
    // If new value is true automatically complete form
    if (value) {
      this.newShipment.payer = this.newShipment.collectionPoint;
    } else {
      if (!this.isEditing) {
        this.newShipment.payer = {
          ...emptyCustomer
        }
      }
    }
  }

  @Watch('newShipment.collectionPoint')
  handleCollectPointChange(collectionPoint: Customer | undefined): void {
    if (this.selfPaid) {
      this.newShipment.payer = collectionPoint;
    }
  }

  @Watch("newShipment.collectTime")
  handleDateChange(value: number): void {
    this.pickupHour = getHourString(value);
    this.pickupDate = getDateString(value);
  }

  get editingShipment(): ShipmentUI {
    return this.$store.getters.getEditingShipment
  }

  get allowedPayer(): boolean {
    return this.newShipment.collectionPoint != undefined && this.newShipment.collectionPoint.id !== "";
  }

  @Watch("editingShipment.id", {immediate: true})
  handleEditingShipment(): void {
    if (this.isEditing) {
      this.newShipment = this.editingShipment;
      if (this.editingShipment.collectionPoint!.id === this.editingShipment.payer!.id) {
        this.selfPaid = true;
      } else {
        this.selfPaid = false;
      }
    }
  }

  @Watch("deliveryType")
  handleDeliveryPointChange(): void {
    if (this.deliveryType.type === ShipmentType.COUNTRY_PICKUP && this.logisticCenter !== undefined) {
      this.enteredDeliveryPoints.push({
        deliveryPoint: this.logisticCenter,
        filterSync: '',
        locked: true,
        packageCount: 1
      });
    } else if (this.deliveryType.type === ShipmentType.CITY_PICKUP) {
      this.enteredDeliveryPoints = this.enteredDeliveryPoints.filter((it) => it.deliveryPoint.id !== this.logisticCenter?.id)
    }
  }

  /**
   * Gives information about if
   * pickup is of type ZM - znotraj mesta or SI - po sloveniji
   */
  get isCountryPickup(): boolean {
    return this.deliveryType.type === ShipmentType.COUNTRY_PICKUP;
  }

  handleCancel(): void {
    if (this.isEditing) {
      this.$emit('close-modal');
      // TODO reset changed fields???
    } else {
      this.$emit('close');
    }
  }

  async registerShipment(): Promise<void> {
    // Validate form
    let validForm = this.formLeft.validate();
    validForm &&= this.formRight.validate();


    // Notify client
    if (!validForm) {
      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        message: 'Potrebno je pravilno izpolniti obrazec za dodajanje nove posiljke',
        type: 'info'
      });
      return;
    }
    if (this.newShipment.payer == undefined || this.newShipment.payer.id == "") {
      this.newShipment.payer = this.newShipment.collectionPoint
      this.newShipment.paymentType = this.typesOfPayment[0]
    }

    // Construct new shipmentOBJ and try to add it to current shift
    const shipmentOBJ: NewShipmentRequestData = {
      deliveryPointId: this.newShipment.deliveryPoint!.id,
      collectionPointId: this.newShipment.collectionPoint!.id,
      payerId: this.newShipment.payer!.id,
      driverId: this.newShipment.driver!.id,
      footNote: this.newShipment.footNote,
      paymentTypeId: this.newShipment.paymentType!.id,
      deliveryTime: this.newShipment.collectTime + (2 * 3600),
      collectTime: this.newShipment.collectTime,
      packageNumber: this.newShipment.packageNumber,
    };

    if (this.isEditing) {
      const shipment: EditShipmentRequestData = {
        ...shipmentOBJ,
        id: this.newShipment.id
      }
      const response = await this.$http.put(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/shipment`, shipment);
      if (response.status === 200) {
        this.$emit('close-modal');
        this.$emit('update-table', response.data);
      }
      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        type: 'success',
        message: `Uspešno ste uredili pošiljko`
      });
    } else {
      const shipments: NewShipmentRequestData [] = [];
      this.enteredDeliveryPoints.forEach((it) => {
        const shipment: NewShipmentRequestData = {
          deliveryPointId: this.isCountryPickup ? this.newShipment.collectionPoint?.id : it.deliveryPoint?.id,
          collectionPointId: this.isCountryPickup ? it.deliveryPoint?.id : this.newShipment.collectionPoint?.id,
          payerId: this.newShipment.payer?.id,
          driverId: null,
          footNote: this.newShipment.footNote,
          paymentTypeId: this.newShipment.paymentType?.id,
          deliveryTime: this.newShipment.collectTime + (2 * 3600),
          collectTime: this.newShipment.collectTime,
          packageNumber: this.newShipment.packageNumber,
          packageCount: it.packageCount
        }
        shipments.push(shipment)
      })
      const data: AdHocRegisterRequestData = {
        proposedDriverId: this.newShipment.driver!.id,
        shipments: shipments,
        status: AdHocStatus.PENDING
      }

      const res = await this.$http.post<AdHocPickupDTO>(`${process.env.VUE_APP_API_URL}/shifts/${this.shift.id}/adHoc`, data);

      this.newShipment = {
        ...emptyShipment
      };
      this.formRight.resetValidation();

      this.formLeft.resetValidation();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.$refs.customerSelectLeft.reset()

      this.enteredDeliveryPoints = [];

      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        type: 'success',
        message: 'Uspešno ste ustvarili novo pošiljko'
      });
      console.log('Emitting close with data from newShipment', res.data)
      this.$emit('close', res.data);
    }
  }

  // Mutate expected pickup arrival time
  mutateTime(minutes: number): void {
    this.newShipment.collectTime = Math.floor((Date.now() / 1000) + (minutes * 60));
  }

  // Computed property getter - current shift
  get shift(): ActiveShift {
    return store.state.shift.activeShift;
  }

  // Reference to form
  get formLeft(): VForm {
    return this.$refs.formLeft as VForm;
  }

  get formRight(): VForm {
    return this.$refs.formRight as VForm
  }

  async mounted(): Promise<void> {
    this.drivers = await this.fetchDriversByName();
    this.typesOfPayment = (await this.fetchPaymentTypes()).data;
    this.logisticCenter = await this.getPLC();
    if (this.enteredDeliveryPoints.length <= 0) {
      this.addEmptyEntity()
    }
  }

  async getPLC(): Promise<Customer> {
    const res = await this.$http.get<Customer []>(`${process.env.VUE_APP_API_URL}/customer?name=PLC (Postno logisticni center)`);
    return res.data[0];
  }

  isCountryType(): boolean {
    return this.deliveryType.type === ShipmentType.COUNTRY_PICKUP;
  }

  addEmptyEntity(): void {
    this.enteredDeliveryPoints.push({...emptyDeliveryPoint});
  }

  handleDeliveryPointPackageCount(count: string | undefined, it: EnteredDeliveryPoint) {
    try {
      const countNumber = parseInt(count || "")
      if (countNumber > 0 && countNumber < 100) {
        it.packageCount = countNumber
      }
    } catch (e) {
      // Could not parse to number, skip
    }
  }
}
