import {GetterTree} from "vuex";
import {ShiftState} from "@/store/shift/types";
import {RootState} from "@/store/types";

export const getters: GetterTree<ShiftState, RootState> = {

    /**
     * parse Date to show it in header components
     */
    getShiftDate(state): string {
        const timestamp = state.activeShift.createdAt;
        const date = new Date(timestamp * 1000);
        const partOfDay = new Date().getHours() > 12 ? 'Popoldan' : 'Dopoldan'

        return `${date.getDate()}.${date.getUTCMonth() + 1}.${date.getFullYear()}, ${partOfDay}`;
    },

    /**
     * @return - current shift state
     */
    getShift(state): ShiftState {
        return state;
    }
}
