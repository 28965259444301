var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"table-shipment",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.deliveries,"hide-default-footer":true,"fixed-header":"","height":"100%"},scopedSlots:_vm._u([{key:"item.driver",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.driver.workerName)+" "+_vm._s(item.driver.workerLastName)+" - "),_c('b',[_vm._v(_vm._s(item.driver.workerNumber))]),_vm._v(" ("+_vm._s(item.driver.phoneNumber)+") ")]}},{key:"item.shift",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.shift.createdAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullDate(item.createdAt))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{staticClass:"ma-1",on:{"click":function($event){return _vm.previewItem(item)}}},[_vm._v(" mdi-eye ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }