















































































































































import {Component, Vue } from "vue-property-decorator";
import {DriverRegisterRequestData, DriverType} from "@/utils/httpReqResMapping";
import {nonEmptyRule, passwordRule} from "@/utils/rules";
import {VForm} from "@/views/types";
import {SnackbarActions} from "@/store/snackbar/actions";

@Component({

})
export default class NewDriver extends Vue {

  // Driver types fetched from API
  driverTypes: DriverType [] = [];

  // Driver registration object
  registerDriver: DriverRegisterRequestData = {
    workerName: '',
    workerLastName: '',
    workerNumber: '',
    workerPassword: '',
    workerPasswordRepeat: '',
    driverTypeId: '',
    phoneNumber: ''
  }

  // Reference to form
  get form(): VForm {
    return this.$refs.form as VForm;
  }

  rules = {
    numberRules :[
      nonEmptyRule("Sifra zaposlenega ne sme biti prazna"),
    ],
    passwordRule: [
      passwordRule(),
      nonEmptyRule("Geslo ne sme biti prazno")
    ],
    repeatPasswordRule: [
        passwordRule(),
        nonEmptyRule("Geslo ne sme biti prazno"),
        (v: string): boolean | string => v == this.registerDriver.workerPassword || `Gesli se ne ujemata`
    ],
    firstNameRule: [
        nonEmptyRule("Ime zaposlenega ne sme biti prazno")
    ],
    lastNameRule: [
        nonEmptyRule("Priimek zaposlenega ne sme biti prazen")
    ],
    phoneNumberRule: [
        nonEmptyRule("Telefonska stevilka zaposlenega ne sme biti prazna")
    ],
    driverTypeRule: [
        nonEmptyRule("Izbrati je potrebno tip voznika")
    ]
  }

  // Fetch driver types
  async fetchDriverTypes(): Promise<DriverType []> {
    const res = await this.$http.get<DriverType []>(`${process.env.VUE_APP_API_URL}/driver/type`);
    return res.data;
  }

  async registerNewDriver(): Promise<void> {
    const validForm = this.form.validate();
    // Notify client of invalid form
    if (!validForm) {
      await this.$store.dispatch(SnackbarActions.FIRE_SNACK, {
        type: 'info',
        message: 'Obrazec za registracijo voznika je potrebno izpolniti pravilno'
      });
      return;
    }

    // Create driver
    await this.$http.post(`${process.env.VUE_APP_API_URL}/driver`, this.registerDriver);

    await this.$store.dispatch(SnackbarActions.FIRE_SNACK,  {
      type: 'success',
      message: 'Uspesno ste dodali novega voznika'
    });

    this.form.reset();

  }

  async mounted(): Promise<void> {
    this.driverTypes = await this.fetchDriverTypes();
  }
}
