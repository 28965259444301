import Vue from 'vue'
import Vuex from 'vuex'
import {RootState} from "@/store/types";
import {snackbar} from "@/store/snackbar";
import {spinner} from '@/store/spinner';
import {auth} from "@/store/auth";
import {shift} from "@/store/shift";
import {shipment} from "@/store/shipment";
import {driver} from "@/store/driver";
import {customer} from "@/store/customer";
import {importProcess} from "@/store/importProcess";
import {deliveries} from "@/store/deliveries"
import {overview} from "@/store/overview";

Vue.use(Vuex)
export default new Vuex.Store<RootState>({
    modules: {
        snackbar,
        spinner,
        auth,
        shift,
        importProcess,
        shipment,
        driver,
        customer,
        deliveries,
        overview
    }
})
