




























































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {ShipmentUI} from "@/utils/httpReqResMapping";
import PayerIcon from "@/components/PayerIcon.vue";
import BarCodeModal from "@/components/BarCodeModal.vue";
@Component({
  components: {BarCodeModal, PayerIcon}
})
export default class PackageTable extends Vue {
  showSubmitBtn = false

  @Prop(Array)
  shipment!: ShipmentUI []

  barcodeModal = false;
  barcodeSelection: ShipmentUI | null = null;


  headers = [
      { text: 'Naziv stranke, naslov', align: 'start', sortable: false, value: 'collectionPoint', 'class': 'table_entry' },
      { value: 'deliveryPoint', sortable: false },
      { text: 'Tel., DŠ', value: 'driver.phoneNumber', sortable: false, align: 'start' },
      { align: 'center', width: '3rem', value: 'deliverTime', sortable: false },
      { align: 'center', width: '8rem', value: 'driver', sortable: false },
      { align: 'center', width: '4rem', value: 'paymentType', sortable: false },
      { align: 'center', width: '4rem', text: 'ZM', value: 'zm', sortable: false },
      { align: 'center', width: '4rem', text: 'SI', value: 'si', sortable: false },
      {align: 'center', width: '8rem', value:'packageNumber', text: 'St. paketa brez presledka', sortable: false}
  ]


  // Convert unix timestamp to time windowed with +/- prefix
  timeWindowedTimeStamp(value: number): string {
    const current = Date.now() / 1000;
    const gap = current - value;
    const gapSeconds = Math.abs(Math.ceil(gap));

    const hours = `${Math.floor(gapSeconds/3600) < 10 ? `0${Math.floor(gapSeconds/3600)}` : `${Math.floor(gapSeconds/3600)}`}`;
    const minutes = `${Math.floor((gapSeconds % 3600) / 60) < 10 ? `0${Math.floor((gapSeconds % 3600) / 60)}` : `${Math.floor((gapSeconds % 3600) / 60)}` }`;

    const prefix = gap <= 0 ? '-' : '+';

    return `${prefix}${hours}:${minutes}`;

  }

  // Return true if shipment is late in deliverance
  isBeingLate(value: number): boolean {
    const current = Date.now() / 1000;
    const gap = current - value;

    return gap > 0 ;
  }

  // Convert unix timestamp to hour:minutes timestamp
  toTimeStamp(value: number): string {
    const date = new Date(value * 1000);
    const hours = `${Math.floor(date.getHours()) < 10 ? `0${date.getHours()}` : `${date.getHours()}`}`;
    const minutes = `${Math.floor(date.getMinutes()) < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}` }`;

    return `${hours}:${minutes}`;
  }

  showBarcodeModal(item: ShipmentUI): void {
    this.barcodeModal = true;
    this.barcodeSelection = item;
    console.log(this.barcodeSelection, this.barcodeModal)
  }

  onBarcodeModalClose(val: boolean): void {
    this.barcodeModal = val;
  }

}
