

















































import {Prop, Vue, Component} from "vue-property-decorator";
import {AutoGeneratedShipment, CustomerRegisterRequestData} from "@/utils/httpReqResMapping";
import CustomerAdder from "@/components/CustomerAdder.vue";

@Component({
  components: {CustomerAdder}
})
export default class CustomerModal extends Vue {

  dialog = false;

  customer!: CustomerRegisterRequestData;

  xlsxAddress = '';

  xlsxCompanyName = '';

  resolve!: (value: (PromiseLike<CustomerRegisterRequestData | null> | CustomerRegisterRequestData | null)) => void;

  reject!: (reason?: any) => void;

  showModal(customer: CustomerRegisterRequestData, item: AutoGeneratedShipment): Promise<CustomerRegisterRequestData | null> {
    this.customer = customer;
    this.xlsxCompanyName = item.deliveryPoint.name;
    this.xlsxAddress = item.deliveryPoint.address.display_name;

    this.dialog = true;


    return new Promise<CustomerRegisterRequestData | null>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    })
  }

  onCustomer(customer: CustomerRegisterRequestData): void {
    this.resolve(customer);
    this.dialog = false;
  }

  onDismiss(): void {
    this.resolve(null);
  }
}
