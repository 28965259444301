



































import {Component, Emit, Vue} from "vue-property-decorator";
import {Prop} from "vue-property-decorator";
import {ShipmentUI} from "@/utils/httpReqResMapping";
import JsBarcode from "jsbarcode";
@Component
export default class BarCodeModal extends Vue {

  @Prop(Object)
  shipment!: ShipmentUI;

  modal = true


  mounted(): void {
    JsBarcode("#barcode", this.shipment.packageNumber);
  }

  @Emit('onClose')
  onClose(val: boolean): boolean {
    return val;
  }

}
